import React, { useState, useEffect } from 'react';
import { MenuItem } from 'react-md';

import * as STRING from '@utilities/constants/strings';
import { handleDownloadClick, getChecklist } from '@utilities/services/checklistService';

function ChecklistMenuItem(props) {
    const { isDashboard, organizerId } = props;

    const [checklistData, setChecklistData] = useState(null);

    useEffect(() => {
        const getChecklistData = async () => {
            const checklist = await getChecklist(organizerId);
            setChecklistData(checklist);
        }
        getChecklistData();
    }, []);

    const handleChecklistMenuItemClick = () => {
        if (checklistData) 
            handleDownloadClick(organizerId, checklistData.name)
    }

    return checklistData && isDashboard
        ? (
            <MenuItem
                key='header-download-checklist'
                className={`cardMenuItems checklistKabob ${checklistData ? '' : ' selected'}`}
                onClick={handleChecklistMenuItemClick}
                data-testid='header-download-checklist'
                disabled={!checklistData}
            >
                {STRING.DOWNLOAD_CHECKLIST}
            </MenuItem>
        )
        : <></>;
}

export default ChecklistMenuItem;