import { useState, useEffect } from 'react';

import usePageFramework from '@utilities/hooks/usePageFramework';
import EntityRenderer from '@components/entityRenderer';

import { triggeredEvent, sectionFieldLogic } from './farmIncomeLogicTrigger';
import getYear from '@utilities/helpers/getYear';
import * as NAV from '@utilities/constants/navigation';
import getFormTemplateForYear from '@utilities/helpers/formTemplate';
import farmIncome2021 from './2021';
import farmIncome2022 from './2022';
import farmIncome2023 from './2023';

let sections = [];

export const getFarmIncomeSections = () => {
    return sections;
};

function FarmIncome(props) {
    const { isExportForm } = props;
    const { updateCard, REDUX, selectState } = usePageFramework(NAV.FARM_INCOME);
    const [sectionState, setSectionState] = useState('');
    const year = getYear(selectState);

    useEffect(() => {
        if (!isExportForm) updateCard();

        const formTemplatesByYear = {
            2021: farmIncome2021(year),
            2022: farmIncome2022(year),
            2023: farmIncome2023(year),
        };

        const formSections = getFormTemplateForYear(year.current, formTemplatesByYear);
        sections = formSections;
        setSectionState(formSections);
        // eslint-disable-next-line  
    }, []);

    const axcessIdentifiers = [
        { section: 'Income', id:'Sch F' },
        { section: 'Sch F - Profit or Loss From Farming', id: 'Government' },
    ];

    const spreadIdentifiers = [
        { section: 'Income', id: 'Other Income (1099-G, 1099-K, 1099-MISC, 1099-NEC, W-2G)' },
        { section: '4136 - Credit for Federal Tax Paid on Fuels', id:'Government', toFirstEntity: true },
    ];

    return (
        !sectionState ?  <></> : 
            <EntityRenderer sections={sectionState} key="farmIncomeForm" formName={REDUX.FARM_INCOME} identifiers={axcessIdentifiers} spreadIdentifiers={spreadIdentifiers}
                triggeredEvent={triggeredEvent} sectionFieldLogic={sectionFieldLogic} isExportForm={isExportForm}/>
    );
}

export default FarmIncome;