import { useState, useEffect } from 'react';
import usePageFramework from '@utilities/hooks/usePageFramework';
import FormRenderer from '../../../components/formRenderer/index';
import getYear from '@utilities/helpers/getYear';
import * as NAV from '@utilities/constants/navigation';
import getFormTemplateForYear from '@utilities/helpers/formTemplate';
import passthrough2021 from './2021';
import passthrough2022 from './2022';
import passthrough2023 from './2023';

function PassthroughIncome(props) {
    const { isExportForm } = props;
    const { updateCard, REDUX, selectState } = usePageFramework(NAV.PASSTHROUGH_INCOME);
    const [lineItemGroupState, setLineItemGroupState] = useState('');
    const year = getYear(selectState);

    useEffect(() => {
        if (!isExportForm) updateCard();

        const formTemplatesByYear = {
            2021: passthrough2021(year),
            2022: passthrough2022(year),
            2023: passthrough2023(year),
        };

        const formSections = getFormTemplateForYear(year.current, formTemplatesByYear);

        setLineItemGroupState(formSections);
        // eslint-disable-next-line    
    }, []);

    const axcessIdentifiers = [
        { section: 'Income', id: 'Sch E, p 2 - Partnership' },
        { section: 'Income', id: 'Sch E, p 2 - S Corporation' },
        { section: 'Income', id: 'Sch E, p 2 - Fiduciary Passthrough (K-1 1041)'}
    ];

    const entityFilter = {
		type: 'omitAny',
		filters: [
			{ key: 'IFDSFIDG.223', filterType: 'lineMatchingOnly' },
			{ key: 'IFDSPRTG.249', filterType: 'lineMatchingOnly' },
			{ key: 'IFDSSCPG.433', filterType: 'lineMatchingOnly' },
		],
	};
    
    return (
        <FormRenderer sections={lineItemGroupState} key="passthroughIncomeForm" formName={REDUX.PASSTHRU_INCOME} identifiers={axcessIdentifiers} 
            isExportForm={isExportForm} entityFilter={entityFilter}
        />
    );
}

export default PassthroughIncome;