import { useEffect, useState } from 'react';
import usePageFramework from '@utilities/hooks/usePageFramework';
import FormRenderer from '@components/formRenderer';
import { sectionFieldLogic } from './dependentsLogicTrigger';
import * as NAV from '@utilities/constants/navigation';
import getYear from '@utilities/helpers/getYear';
import getFormTemplateForYear from '@utilities/helpers/formTemplate';
import dependents2021 from './2021';
import dependents2022 from './2022';
import dependents2023 from './2023';

function Dependents(props) {
    const { isExportForm } = props
    const { updateCard, REDUX, selectState } = usePageFramework(NAV.DEPENDENTS);
    const [sectionState, setSectionState] = useState('');
    const year = getYear(selectState);

    useEffect(() => {
        if (!isExportForm) updateCard();

        const formTemplatesByYear = {
            2021: dependents2021(year),
            2022: dependents2022(year),
            2023: dependents2023(year)
        };

        const formSections = getFormTemplateForYear(year.current, formTemplatesByYear);

        setSectionState(formSections);
        // eslint-disable-next-line
    }, []);

    const axcessIdentifiers = [
        { section: 'General', id: 'Basic Data' },
        { section: 'Credits', id: '2441 - Child and Dependent Care Expenses' },
    ];

    return (
        <FormRenderer sections={sectionState} key="DependentsForm" formName={REDUX.DEPENDENTS} identifiers={axcessIdentifiers} sectionFieldLogic={sectionFieldLogic} isExportForm={isExportForm}/>
    );
}

export default Dependents;