import './ProgressBar.css';

const ProgressBar = ({ completed }) => {
  const completeStyle = {
    width: `${completed}%`,
    borderRadius: completed < 100 ? '5.4px 0 0 5.4px' : 'inherit',
  };

  const incompleteStyle = {
    width: `${100 - completed}%`,
  };

  return (
    <div className="progress-bar-container">
      <div className="progess-bar-complete" style={completeStyle}>
        <div className="percentage-text">{`${completed}%`}</div>
      </div>
      <div className="progess-bar-incomplete" style={incompleteStyle}>
        <div className="percentage-text">{`${100 - completed}%`}</div>
      </div>
    </div>
  );
};

export default ProgressBar;