import React, { useEffect, useState } from 'react';
import { useToggle } from '@react-md/utils';
import moment from 'moment';
import {
    Dialog, DialogHeader, DialogContent, DialogFooter,
} from "@react-md/dialog";
import {
    Button, Grid, GridCell, Divider, Checkbox
} from 'react-md';

import {
    NOT_SENT, CLOSE, EMAIL_CHECKLIST_SENT_SUCCESS_TEXT,
    EMAIL_CHECKLIST_SENT_FAILED_TEXT, CANCEL, SEND
} from '@utilities/constants/strings';

import { handleEmailChecklistClick, getChecklistEmailRecipients } from '@utilities/services/checklistService';

import './EmailChecklistDialog.css'

function EmailChecklistDialog(props) {
    const { organizerId, disableEmailChecklistDialog } = props;
    const [userList, setUserList] = useState();
    const [resultText, setResultText] = useState('');

    const [showResultDialog, enableResultDialog, disableResultDialog] = useToggle(false);

    const HAS_SELECTED_USERS = userList && userList.filter(x => x.isSelected).length > 0;

    const getUserList = async () => {
        const userData = [];
        await getChecklistEmailRecipients(organizerId)
            .then((users) =>
                userData.push(...users.map(user => {
                    const checklistLastSent = user.checklistLastSent
                        ? moment(new Date(user.checklistLastSent)).format("MM/DD/YYYY - hh:mm A")
                        : NOT_SENT
                    const checklistSentBy = user.checklistSentBy
                        ? (user.checklistSentBy.includes('@') ? 'Administrator' : user.checklistSentBy)
                        : NOT_SENT;
                    return {
                        ...user,
                        name: user.displayName,
                        checklistLastSent,
                        checklistSentBy,
                        isSelected: false
                    }
                }))
            );
        setUserList(userData);
    }

    const handleSendChecklistEmailClick = async () => {
        if (HAS_SELECTED_USERS) {
            const recipientIds = userList.filter(x => x.isSelected).map(x => x.id);
            const result = await handleEmailChecklistClick(organizerId, recipientIds);
            if (result) {
                await getUserList();
            }
            setResultText(result ? EMAIL_CHECKLIST_SENT_SUCCESS_TEXT : EMAIL_CHECKLIST_SENT_FAILED_TEXT);

            enableResultDialog();
        }
    }

    const handleRowCheckboxClick = (event) => {
        setUserList(prevData => {
            return prevData.map(x => {
                if (x.id === event.target.value) {
                    x.isSelected = event.target.checked;
                }
                return x;
            })
        })
    }

    const renderRowGridCell = (user, propertyName, index, colSpan) => {
        const isNotSentText = user[propertyName] == NOT_SENT;
        return (
            <GridCell
                colSpan={colSpan}
                className={`email-dialog-font send-checklist-cell ${isNotSentText ? 'not-sent-text' : ''}`}
                data-testid={`send-checklist-${propertyName}-${index}`}
            >
                {user[propertyName]}
            </GridCell>
        )
    }

    const renderHeaderGridCell = (colSpan, cellText, customStyle) => {
        return (
            <GridCell
                colSpan={colSpan}
                className={`send-checklist-header`}
                style={customStyle}
            >
                {cellText}
            </GridCell>
        );
    }

    useEffect(() => {
        getUserList();
    }, [organizerId]);

    return (
        <>
            <Dialog
                id="result-dialog"
                role="alertdialog"
                visible={showResultDialog}
                onRequestClose={() => { }}
                className={'result-dialog'}
                aria-labelledby="result-dialog"
                data-testid="result-dialog">
                <DialogHeader></DialogHeader>
                <DialogContent className={`result-dialog-content`}>
                    {resultText}
                </DialogContent>
                <DialogFooter >
                    <div style={{
                        display: 'flex', width: '100%',
                        justifyContent: 'center'
                    }}>
                        <Button
                            id="dialog-closebtn"
                            className={`email-dialog-font closeButtonActive`}
                            onClick={disableResultDialog}
                        >
                            {CLOSE}
                        </Button>
                    </div>
                </DialogFooter>
            </Dialog>
            <Dialog
                id="send-email-checklist-dialog"
                role="alertdialog"
                visible={true}
                onRequestClose={() => { }}
                className={'email-checklist-dialog'}
                aria-labelledby="send-email-checklist-dialog-title"
                data-testid="send-email-checklist-dialog-title">
                <DialogHeader className={'dialog-header'}>
                    <div>
                        <h2 style={{ color: '#2E334E' }}>Send Checklist</h2>
                        <p className={'dialog-sub-header'}>Select user(s) to send checklist</p>
                    </div>
                </DialogHeader>
                <DialogContent style={{ padding: '1.5rem 0' }}>
                    <Grid className='sendEmailHeaderContainer'>
                        {renderHeaderGridCell(1, '')}
                        {renderHeaderGridCell(2, 'Send To')}
                        {renderHeaderGridCell(3, 'Email Address')}
                        {renderHeaderGridCell(2, 'Role')}
                        {renderHeaderGridCell(2, 'Last Sent')}
                        {renderHeaderGridCell(2, 'Sent By')}
                    </Grid>
                    {
                        userList
                            ? userList.map((user, index) => (
                                <Grid
                                    key={`user-row-${index}`}
                                    className='send-checklist-grid'
                                    data-testid={`send-checklist-grid`}
                                >
                                    <GridCell colSpan={1}>
                                        <Checkbox
                                            data-testid={`send-checklist-checkbox-${index}`}
                                            id={`send-checklist-checkbox-${index}`}
                                            key={`send-checklist-checkbox-${index}`}
                                            onChange={handleRowCheckboxClick}
                                            value={user.id}
                                            checked={user.isSelected}
                                        />
                                    </GridCell>
                                    {renderRowGridCell(user, 'name', index, 2)}
                                    {renderRowGridCell(user, 'email', index, 3)}
                                    {renderRowGridCell(user, 'role', index, 2)}
                                    {renderRowGridCell(user, 'checklistLastSent', index, 2)}
                                    {renderRowGridCell(user, 'checklistSentBy', index, 2)}
                                    <GridCell colSpan={12}>
                                        <Divider />
                                    </GridCell>
                                </Grid>
                            ))
                            : (<div style={{
                                display: 'flex', width: '100%',
                                justifyContent: 'center'
                            }}>
                                Loading...
                            </div>)
                    }
                </DialogContent>
                <DialogFooter >
                    <div style={{
                        display: 'flex', width: '100%',
                        justifyContent: 'center'
                    }}>
                        <Button
                            id="dialog-cancelbtn"
                            className='cancelButtonActive email-dialog-font'
                            onClick={disableEmailChecklistDialog}
                        >
                            {CANCEL}
                        </Button>
                        <Button
                            id="dialog-sendbtn"
                            className={`email-dialog-font sendButton${HAS_SELECTED_USERS ? 'Active' : 'InActive'}`}
                            onClick={() => handleSendChecklistEmailClick()}
                        >
                            {SEND}
                        </Button>
                    </div>
                </DialogFooter>
            </Dialog>
        </>
    );
}

export default EmailChecklistDialog;