import { useState, useEffect } from 'react';
import usePageFramework from '@utilities/hooks/usePageFramework';
import FormRendererHelper from '@components/formRenderer';
import * as NAV from '@utilities/constants/navigation';
import getYear from '@utilities/helpers/getYear';
import getFormTemplateForYear from '@utilities/helpers/formTemplate';
import childCare2021 from './2021';
import childCare2022 from './2022';
import childCare2023 from './2023';

function ChildCare(props) {
    const { isExportForm } = props;
    const { REDUX, selectState, updateCard } = usePageFramework(NAV.CHILDCARE);
    const [sectionState, setSectionState] = useState('');
    const year = getYear(selectState);
    
    useEffect(() => {

        if (!isExportForm) updateCard();

        const formTemplatesByYear = {
            2021: childCare2021(year),
            2022: childCare2022(year),
            2023: childCare2023(year),
        };

        const formSections = getFormTemplateForYear(year.current, formTemplatesByYear);

        setSectionState(formSections);
    //eslint-disable-next-line
    }, []);

    return (
        !sectionState ? <></> :
            <FormRendererHelper sections={sectionState} key="providerFormSection" formName={REDUX.DEPENDENT_CARE} isExportForm={isExportForm}/>
    );
}

export default ChildCare;
