import './mobileFooter.css';
import * as IMG from '@utilities/constants/images';

function MobileFooter() {
    return(
        <div className="entryExperienceMobileFooter" data-testid='app-footer-id'>
            <img className="entryExperienceMobileFooterLogo" data-testid='app-footer-logo-id' alt="CLA white logo" src={IMG.CLA_LOGO_MOBILE_FOOTER} />
            <a className="entryExperienceMobileFooterText" data-testid='app-footer-text-id'> 2024 CliftonLarsonAllen LLP   |   CLAConnect.com </a>
        </div>
    );
}

export default MobileFooter;