import _ from 'lodash';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector, useStore } from 'react-redux';
import * as INPUTSTATE from '@utilities/constants/inputState';
import * as REDUX from '@utilities/constants/redux';
import * as NAV from '@utilities/constants/navigation';
import * as CARDSTATUS from '@utilities/constants/cardStatus';
import * as ACTION from '@utilities/actions/global';
import * as STRING from '@utilities/constants/strings';
import { selectReduxGlobalState } from '@utilities/store';
import api from '@utilities/claApi';
import { parseFormData } from '@utilities/formData/formDataParser';
import { lastUserActivityDateSetter } from '@utilities/helpers/lastUserActivityDate';
import { updateUploadSection } from '@utilities/helpers/updateUploadSection';
import { validateUploadError } from '@utilities/helpers/validateUploadError';
import InvalidFilesMessage from '@components/dialog/customDialog/InvalidFilesMessage';
import ExtensionErrorFilesMessage from '@components/dialog/customDialog/ExtensionErrorFileMessage';
import FileContentAndExtensionMismatchMessage from '@components/dialog/customDialog/FileContentAndExtensionMismatchMessage';
import MultipleFileErrorsMessage from '@components/dialog/customDialog/MultipleFileErrorsMessage';
import moment from 'moment';
import { getCardProp } from '@utilities/helpers/getCardProp';
import updateCardStatus from '@utilities/checkErrors/updateCardStatus';
import checkFormErrors from '@utilities/checkErrors/checkFormErrors';
import vehicleEntityNotApplicableChecker from '@utilities/checkErrors/vehicleEntityNotApplicableChecker';
import { convertImageToPdf, isFileToConvertToPdf, getFileName } from '@utilities/files/convertImageToPdf';
import FormData from 'form-data';
import { downloadDocumentFile } from '@utilities/apis/getDocumentsApi';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import useDocumentPreview from '@components/documentPreview/useDocumentPreview';
import { renderTiffImage } from '@utilities/files/convertImageToPdf';
import { INVALID_CHARACTER } from '@utilities/constants/document';
import * as IMG from '@utilities/constants/images.js';

let organizerId = null;
let dashboardId = null;

const currentYear = 2023;
const appType = '1040';

let currentEntity = {};

function usePageFramework(page) {
	let card = null;

	organizerId = useSelector(state => selectReduxGlobalState(state, REDUX.ORGANIZER_ID));
	dashboardId = useSelector(state => selectReduxGlobalState(state, REDUX.DASHBOARD_ID));

	const isPractitioner = useSelector(state => selectReduxGlobalState(state, REDUX.IS_PRACTITIONER));
	const isAdmin = useSelector(state => selectReduxGlobalState(state, REDUX.IS_ADMIN));
	const isInternalUser = (isPractitioner || isAdmin) ? true : false;
	const authUserEmail = useSelector(state => selectReduxGlobalState(state, REDUX.AUTH_USER_EMAIL));
	const dashboard = useSelector(state => selectReduxGlobalState(state, REDUX.DASHBOARD));
	const currentCardKey = useSelector(state => selectReduxGlobalState(state, REDUX.CURRENT_CARD_KEY));
	const screenSize = useSelector(state => selectReduxGlobalState(state, REDUX.SCREEN_SIZE));
	const uploadList = useSelector(state => selectReduxGlobalState(state, REDUX.UPLOAD_LIST));
	const taxYear = useSelector(state => selectReduxGlobalState(state, REDUX.YEAR));
	const priorYearDetails = useSelector(state => selectReduxGlobalState(state, REDUX.PRIOR_YEAR_DETAILS));
	const futureYearOrganizer = priorYearDetails?.priorYearLookup ? _.find(priorYearDetails.priorYearLookup, { value: taxYear }) : {};
	const isFutureYear = useSelector(state => selectReduxGlobalState(state, REDUX.IS_FUTURE_YEAR));
	const isLocked = useSelector(state => selectReduxGlobalState(state, REDUX.LOCK_FORMS));
	const lastSaveFormTime = useSelector(state => selectReduxGlobalState(state, REDUX.LAST_SAVE_FORM_TIME));
	const activeReturn = useSelector(state => selectReduxGlobalState(state, REDUX.ACTIVE_RETURN));
	const duplicateUploadFiles = useSelector(state => selectReduxGlobalState(state, REDUX.DUPLICATE_UPLOAD_FILES));
	const duplicateUploadFilesSkipped = useSelector(state => selectReduxGlobalState(state, REDUX.DUPLICATE_UPLOAD_FILES_SKIPPED))
	const catCardProp = page ? 'route' : 'key';
	const catCardCompare = page ? page : currentCardKey;
	const { buildDocumentPreview } = useDocumentPreview();

	const duplicateFilesSkipped = duplicateUploadFilesSkipped ? duplicateUploadFilesSkipped : [];

	// category.cards.find fails on test because cards can be empty on load
	dashboard?.findIndex((category) => category.cards && category.cards.find((catCard) => {
		const isFound = catCard[catCardProp] === catCardCompare;
		if (isFound) {
			card = catCard;
		}
		return isFound;
	}));
	const updateStatusCard = (statusId, dashCard) => {
		card = dashCard;
		updateStatus(statusId);
	};

	const updateStatus = (statusId) => {
		dispatch(ACTION.setCard(card));

		if (card) {
			card.statusId = statusId;
			card.statusTitle = CARDSTATUS.STATUS_TITLE[statusId];
			dispatch(ACTION.setDashboard(dashboard));

			if (statusId === CARDSTATUS.DOES_NOT_APPLY) {
				dispatch(ACTION.setIsCurrentFormNa(true));
			} else dispatch(ACTION.setIsCurrentFormNa(false));

			const data = { lastUserActivityOn: lastUserActivityDateSetter.lastUserActivityDate };
			const dashboardData = { dashboard: dashboard, updatedOn: lastUserActivityDateSetter.lastUserActivityDate };

			//to remoove lastUserActivityOn when null
			if (!data.lastUserActivityOn) delete data.lastUserActivityOn;

			api.put(`/organizers/${organizerId ?? ''}/dashboard/${dashboardId}`, dashboardData).catch((error) => {
				console.error(error);
				console.error('Unable to save dashboard');
				dispatch(ACTION.setIsSaveSuccess(false));
			});

			if (Object.keys(data).length > 0) {
				api.put(`/organizers/${organizerId ?? ''}`, data).catch((error) => {
					console.error(error);
					console.error('Unable to update dashboard');
					dispatch(ACTION.setIsSaveSuccess(false));
				});
			}
		}
	};

	const updateVisibleDashboardCards = (filterstatusId) => dispatch(ACTION.setDashboardFilterStatus(filterstatusId));
	const updateSortedDashboardCards = (sortBy) => dispatch(ACTION.setDashboardSortRequirements(sortBy));
	// eslint-disable-next-line react-hooks/rules-of-hooks
	const selectState = (stateLabel) => useSelector(state => selectReduxGlobalState(state, stateLabel));

	const setGlobalFormState = (formName, payload, passedOrganizerId) => {
		const isEntityForm = _.startsWith(formName, REDUX.BUSINESS_INCOME) || _.startsWith(formName, REDUX.RENTAL_INCOME) || _.startsWith(formName, REDUX.FARM_INCOME);
		const parsedFormData = parseFormData(payload);
		if (isEntityForm) currentEntity[formName] = parsedFormData;
		dispatch(ACTION.setForm(formName, parsedFormData));

		const cardStatusId = 2;

		const data = { key: formName, data: parsedFormData, status: cardStatusId };
		api.put(`/forms/${organizerId ?? passedOrganizerId ?? ''}`, data).catch((error) => {
			console.error(error);
			console.error('Unable to save form');
			dispatch(ACTION.setIsSaveSuccess(false));
		});

		setLastUserActivityDate();
	};
	const setLastUserActivityDate = (latestDashboard = dashboard) => {
		const saveDate = moment();
		latestDashboard?.forEach((cat) => {
			cat?.cards.forEach((card) => {
				card.lastFormSavedOn = saveDate;
			});
		});
		dispatch(ACTION.setDashboard(latestDashboard));
		dispatch(ACTION.setLastSaveFormTime(saveDate));
		const data = { lastUserActivityOn: lastUserActivityDateSetter.lastUserActivityDate };
		const dashboardData = { dashboard: latestDashboard, updatedOn: lastUserActivityDateSetter.lastUserActivityDate };

		//to remoove lastUserActivityOn when null
		if (!data.lastUserActivityOn) delete data.lastUserActivityOn;

		if (Object.keys(data).length > 0) {
			api.put(`/organizers/${organizerId ?? ''}`, data).catch((error) => {
				console.error(error);
				console.error('Unable to save lastUserActivityOn date');
				dispatch(ACTION.setIsSaveSuccess(false));
			});
		}

		api.put(`/organizers/${organizerId ?? ''}/dashboard/${dashboardId}`, dashboardData).catch((error) => {
			console.error(error);
			console.error('Unable to save dashboard');
			dispatch(ACTION.setIsSaveSuccess(false));
		});
	}

	const setEntityFormState = (formName, payload, passedOrganizer, latestDashboard) => {
		dispatch(ACTION.setForm(formName, payload));
		currentEntity[formName] = payload;

		const data = {
			key: formName,
			data: payload,
			status: card && card.statusId ? card.statusId : CARDSTATUS.NOT_STARTED,
		};

		api.put(`/forms/${organizerId ?? passedOrganizer ?? ''}`, data).catch((error) => {
			console.error(error);
			console.error('Unable to save form');
			dispatch(ACTION.setIsSaveSuccess(false));
		});
		setLastUserActivityDate(latestDashboard);
	};


	const updatePage = (pageInfo) => {
		dispatch(ACTION.setToolbarTitle(pageInfo.title));
		dispatch(ACTION.setCurrentCardKey(pageInfo.key));
		dispatch(ACTION.showBulkUpload(false));
		dispatch(ACTION.setDashboardFilterStatus(-1));
		dispatch(ACTION.setDashboardSortRequirements('traditional'));
	};

	const updateCard = () => {
		if (card) {
			dispatch(ACTION.setToolbarTitle(getCardProp(card.formName, 'title')));
			dispatch(ACTION.setCurrentCardKey(card.key));
			dispatch(ACTION.setCurrentCardStatus(null));

			if (card.statusId === CARDSTATUS.DOES_NOT_APPLY) {
				dispatch(ACTION.setIsCurrentFormNa(true));
			} else dispatch(ACTION.setIsCurrentFormNa(false));

			if (card.showBulkUpload) {
				dispatch(ACTION.showBulkUpload(true));
				dispatch(ACTION.setDashboard(dashboard));
			} else {
				dispatch(ACTION.showBulkUpload(false));
			}
		} else {
			dispatch(ACTION.showBulkUpload(true));
			dispatch(ACTION.setCurrentCardKey(STRING.DASHBOARD_KEY));
		}
	};

	const history = useHistory();
	const dispatch = useDispatch();
	const store = useStore();
	const location = useLocation();

	const clearFormState = () => {
		const state = store.getState();
		state.get('global').forEach((value, key) => {
			if (!key.startsWith('G_') && !key.startsWith('@')) { // Only Keep Global Variables from being cleared!
				dispatch({ type: key, payload: null });
			}
		});
	};

	const getOrganizerDocumentTarget = () => {
		// Target future year organizer
		let targetOrganizerId = organizerId;
		if (isFutureYear && futureYearOrganizer?.id) {
			targetOrganizerId = futureYearOrganizer.id;
		}
		return targetOrganizerId;
	};

	const replaceNbspWithSpaceAndGetPositions = (str) => {
		const positions = [];
		let newStr = '';

		for (let i = 0; i < str.length; i++) {
			// Remove char where nbsp;
			if (str.charCodeAt(i) === 160 || str.charCodeAt(i) === 32 || str.charCodeAt(i) === 8239) {
				positions.push(i);
				newStr += ' ';
			} else {
				newStr += str[i];
			}
		}
		return newStr;
	}

	const onFileUpload = async (event, acceptedFiles, section, list = uploadList, fn, form = card.key) => {
		const files = acceptedFiles ? acceptedFiles : event.target.files;
		if (!files) return;

		//check duplicate files
		const duplicateFiles = [];
		const filesCopy = [];
		let fileName;
		for (let index = 0; index < files.length; index++) {
			const file = files[index];
			fileName = replaceNbspWithSpaceAndGetPositions(file.name);

			const dupFile = uploadList?.find(upload => upload.name === fileName);
			if (dupFile) {
				duplicateFiles.push(dupFile);
			}
			filesCopy.push(file);
		}

		const uploadsProps = { files: filesCopy, section, fn, form };
		if (!duplicateFiles.length) {
			uploadFiles(files, section, fn, form, list);
		} else {
			dispatch(ACTION.setUploadWarningVisible(true));
			dispatch(ACTION.setDuplicateUploadFiles(duplicateFiles));
			dispatch(ACTION.setUploadsProps(uploadsProps));
		}
	};

	const uploadFiles = async (files, section, fn, form, list) => {
		if (!files) return;

		dispatch(ACTION.setProgressText('Uploading...'));
		dispatch(ACTION.setProgressVisible(true));
		dispatch(ACTION.setUploadProgressVisible(true));

		let targetOrganizerId = getOrganizerDocumentTarget();

		const uploadPromises = [];
		let invalidFiles = [];
		const filesAmount = files.length + duplicateFilesSkipped?.length;

		for (let index = 0; index < files.length; index++) {
			const file = files[index];

			const fileNameAndExtension = files[index].name.split('.');
			const removeExtension = fileNameAndExtension.pop();
			const fileName = fileNameAndExtension.join('');

			const matched = fileName.match(INVALID_CHARACTER);

			if (matched !== null) {
				invalidFiles.push(files[index].name);
				continue;
			}

			// GROWTH: Client side file size validation for uploading documents
			uploadPromises.push(uploadDocument(file, form, section));
		};

		const results = await Promise.all(uploadPromises);

		const successfulUploads = results.filter(x => x !== undefined)

		// if upload is succesful
		if (!results.includes(undefined) && invalidFiles.length === 0 && duplicateFilesSkipped?.length === 0) {
			if (isLocked) {
				const fileNames = () => {
					let fileNames = '';
					results.forEach((file, index) => {
						index === results.length - 1 ? fileNames = `${fileNames} ${file.name}` :
							fileNames = `${fileNames} ${file.name},`
					});
					return fileNames;
				};
				const notifData = {
					type: STRING.UPLOAD_AFTER_LOCK,
					data: {
						id: targetOrganizerId,
						fileName: fileNames()
					}
				};

				api.post('/notifications', notifData).then((response) => {
					console.warn(response);
				}).catch((err) => {
					console.error(err);
				});
			}
			dispatch(ACTION.setUploadList([...list, ...results]));
			dispatch(ACTION.setProgressVisible(false));
			dispatch(ACTION.setUploadProgressVisible(false));

			// save form when file uploaded
			fn?.updateState && fn.updateState()

		} else if (filesAmount > 1 && (extensionErrorFiles.length + contentAndTypeMismatchFiles.length + invalidFiles.length + duplicateFilesSkipped?.length + unknownErrorFiles.length) > 0) {
			dispatch(ACTION.setProgressVisible(false));
			dispatch(ACTION.setUploadProgressVisible(false));
			dispatch(ACTION.setCustomDialogTitle(<div><img className="warningDialogIcon" width="24" height="20" alt="Warning icon" src={IMG.WARNING_ICON}></img>Upload Error!</div>));
			dispatch(ACTION.setCustomDialogMsg(<MultipleFileErrorsMessage filesLength={filesAmount} extensionErrorFiles={extensionErrorFiles} contentAndTypeMismatchFiles={contentAndTypeMismatchFiles} invalidFiles={invalidFiles} duplicateFilesSkipped={duplicateFilesSkipped} unknownErrorFiles={unknownErrorFiles} />));
			dispatch(ACTION.setIsMultipleUpload(true))
			dispatch(ACTION.setShowCustomDialog(true));
			dispatch(ACTION.setDuplicateUploadFilesSkipped([]));
			dispatch(ACTION.setUploadList([...list, ...successfulUploads]));
			invalidFiles = [];
		} else if (invalidFiles.length > 0) {
			dispatch(ACTION.setProgressVisible(false));
			dispatch(ACTION.setCustomDialogTitle(''));
			dispatch(ACTION.setShowCustomDialog(true));
			dispatch(ACTION.setCustomDialogMsg(<InvalidFilesMessage invalidFiles={invalidFiles} />));
			dispatch(ACTION.setUploadList([...list, ...successfulUploads]));
			invalidFiles = [];
		} else if (extensionErrorFiles.length > 0) {
			dispatch(ACTION.setProgressVisible(false));
			dispatch(ACTION.setUploadProgressVisible(false));
			dispatch(ACTION.setCustomDialogTitle('Upload Error!'));
			dispatch(ACTION.setCustomDialogMsg(<ExtensionErrorFilesMessage extensionErrorFiles={extensionErrorFiles} />));
			dispatch(ACTION.setShowCustomDialog(true));
			dispatch(ACTION.setUploadList([...list, ...successfulUploads]));
		} else if (contentAndTypeMismatchFiles.length > 0) {
			dispatch(ACTION.setProgressVisible(false));
			dispatch(ACTION.setUploadProgressVisible(false));
			dispatch(ACTION.setCustomDialogTitle('Upload Error!'));
			dispatch(ACTION.setCustomDialogMsg(<FileContentAndExtensionMismatchMessage contentAndTypeMismatchFiles={contentAndTypeMismatchFiles} />));
			dispatch(ACTION.setShowCustomDialog(true));
			dispatch(ACTION.setUploadList([...list, ...successfulUploads]));
		} else {
			dispatch(ACTION.setProgressVisible(false));
			dispatch(ACTION.setUploadProgressVisible(false));
			dispatch(ACTION.setCustomDialogTitle('Upload Error!'));
			dispatch(ACTION.setCustomDialogMsg('Please try again later.'));
			dispatch(ACTION.setShowCustomDialog(true));
			dispatch(ACTION.setUploadList([...list, ...successfulUploads]));
		}
	};

	const extensionErrorFiles = [];
	const contentAndTypeMismatchFiles = [];
	const unknownErrorFiles = [];

	const uploadDocument = async (file, form, section) => {
		const formPayload = new FormData();
		formPayload.append('document', file);

		if (form !== null && form !== undefined) {
			formPayload.append('form', form);
		}

		if (section !== null && section !== undefined) {
			formPayload.append('section', section);
		}

		// Target future year organizer
		let targetOrganizerId = getOrganizerDocumentTarget();

		return api.post(`/organizers/${targetOrganizerId}/documents/upload`, formPayload).then((response) => {
			return response.data.id;
		}).then((documentId) => {
			return api.get(`/organizers/${targetOrganizerId}/documents/${documentId}`);
		}).then((response) => {
			return response.data;
		}).then((document) => {

			/*
				GROWTH: remove this transformation
					This transformation is done due to compatability with
					form renderer, document manager, and sharepoint implementation
			*/
			document.section = updateUploadSection(document.section);
			document.updated_on = document.updatedOn;
			document.downloaded_on = document.downloadedOn;
			document.upload_id = document.id;
			document.uploaded_by = document.createdBy;
			return document;
		}).catch((err) => {
			if (validateUploadError(err) === 'extensionError') {
				extensionErrorFiles.push(file.name);
			} else if (validateUploadError(err) === 'extensionMismatchContentError') {
				contentAndTypeMismatchFiles.push(file.name);
			} else {
				unknownErrorFiles.push(file.name);
			}
		});
	};

	const onFileRemoval = (event, file, replacedFiles) => {
		// Target future year organizer
		let targetOrganizerId = getOrganizerDocumentTarget();

		return api.delete(`/organizers/${targetOrganizerId}/documents/${file.id}`).then((response) => {
			dispatch(ACTION.setUploadList(uploadList.filter((u) => u.id !== file.id)));
			if (!replacedFiles) dispatch(ACTION.setProgressVisible(false));
			return response
		}).catch((err) => {
			console.error(err);
			dispatch(ACTION.setProgressText('Failed to remove document!'));
		});
	};

	const moveFile = (fileId, key, section) => {
		dispatch(ACTION.setProgressVisible(true));
		dispatch(ACTION.setProgressText('Moving...'));
		// Target future year organizer
		let targetOrganizerId = getOrganizerDocumentTarget();
		const payload = {
			form: key,
			section: section
		};
		return api.put(`/organizers/${targetOrganizerId}/documents/${fileId}`, payload).then((response) => {
			for (let index = 0; index < uploadList.length; index++) {

				if (uploadList[index].id === fileId) {
					uploadList[index].form = key;
					break;
				}
			}
			const categoryCount = [];
			const fileCategory = _.groupBy(uploadList, "form");
			for (var cat in fileCategory) {
				categoryCount.push(fileCategory[cat].length);
			}
			dispatch(ACTION.setUploadList([...uploadList]));
			dispatch(ACTION.setFileCategoryCount([...categoryCount]));
			dispatch(ACTION.setProgressVisible(false));
			dispatch(ACTION.setCustomDialogTitle(''));
			dispatch(ACTION.setCustomDialogMsg('File successfully moved'));
			dispatch(ACTION.setShowCustomDialog(true));
			return fileId;
		}).catch((error) => {
			console.error(error);
			dispatch(ACTION.setProgressVisible(false));
			dispatch(ACTION.setCustomDialogTitle(''));
			dispatch(ACTION.setCustomDialogMsg('File move failed!'));
			dispatch(ACTION.setShowCustomDialog(true));
		});
	};

	const onFilePreview = async (file, config) => {
		// Target future year organizer
		let targetOrganizerId = getOrganizerDocumentTarget();

		return downloadDocumentFile(targetOrganizerId, file.id, false, config);
	};

	const onFileRename = (file, newName) => {
		dispatch(ACTION.setProgressVisible(true));
		dispatch(ACTION.setProgressText('Renaming...'));

		// Target future year organizer
		let targetOrganizerId = getOrganizerDocumentTarget();

		const payload = {
			name: newName,
		};

		return api.put(`/organizers/${targetOrganizerId}/documents/${file.id}`, payload).then((response) => {
			file.name = newName;

			dispatch(ACTION.setProgressVisible(false));
			dispatch(ACTION.setCustomDialogTitle(''));
			dispatch(ACTION.setCustomDialogMsg('File name successfully renamed'));
			dispatch(ACTION.setShowCustomDialog(true));

			return file;
		}).catch((error) => {
			console.error(error);
			dispatch(ACTION.setProgressVisible(false));
			dispatch(ACTION.setCustomDialogTitle(''));
			dispatch(ACTION.setCustomDialogMsg('File rename failed!'));
			dispatch(ACTION.setShowCustomDialog(true));
		});
	};

	const downloadFile = async (file, skipPropUpdate = false) => {
		// Target future year organizer
		let targetOrganizerId = getOrganizerDocumentTarget();

		if (isFileToConvertToPdf(file)) {
			// image files are to be converted to pdf before download
			await convertImageToPdf(targetOrganizerId, file);
		} else {
			const blobFile = await downloadDocumentFile(targetOrganizerId, file.id, true);
			const url = URL.createObjectURL(blobFile);
			const a = document.createElement("a");
			a.href = url;
			a.download = file.name;
			document.body.appendChild(a);
			a.click();

			setTimeout(function () {
				document.body.removeChild(a);
				URL.revokeObjectURL(blobFile);
			}, 1000);
		}

		if (!skipPropUpdate) {
			const uploads = _.cloneDeep(uploadList);
			const uploadIndex = uploads.findIndex(upload => upload.id === file.id);
			const downloadedFile = uploads[uploadIndex];

			if (downloadedFile && uploadIndex !== -1) {
				downloadedFile.downloaded_on = `${new Date().toJSON()}`;

				if (isInternalUser) {
					downloadedFile.isNew = false;
				}
			}

			dispatch(ACTION.setUploadList(uploads));
		}
	};

	const downloadAllFiles = async (keepImageFormat, { orgExportBlobUrl }) => {
		let targetOrganizerId = getOrganizerDocumentTarget();
		const uploads = [];
		let zip = new JSZip();

		if (orgExportBlobUrl) {

			try {
				const response = await fetch(orgExportBlobUrl);
				const orgExportBlob = await response.blob();
				zip.file(`${activeReturn.clientNumber}-${activeReturn.displayName}-OrganizerExport.pdf`, orgExportBlob);
				;
			} catch (error) {
				console.error('Error fetching the Blob from orgExportBlobUrl or adding it to zip', error);
			}
		}

		for (let index = 0; index < uploadList.length; index++) {
			const upload = uploadList[index];
			upload.downloaded_on = `${new Date().toJSON()}`;

			if (isInternalUser) {
				upload.isNew = false;
			}

			let blobContent = isFileToConvertToPdf(upload)
				? await convertImageToPdf(targetOrganizerId, upload, true)
				: await downloadDocumentFile(targetOrganizerId, upload.id, true);
			let fileName = getFileName(upload, keepImageFormat);

			if (!blobContent) {
				throw new Error(`Received no file content for ${upload.name}`);
			}

			zip.file(fileName, blobContent, { blob: true, base64: true });
			uploads.push(upload);
		}

		return zip.generateAsync({ type: "blob" }).then((content) => {
			saveAs(content, `${activeReturn.clientNumber}_${activeReturn.displayName}_${moment().format("MM-DD-YYYY H:m:ss")}.zip`);
			dispatch(ACTION.setUploadList(uploads));
		});
	};

	const downloadNewFiles = async (keepImageFormat) => {
		// Locking down this functionality to internal users only
		if (!isInternalUser) {
			return;
		}

		let targetOrganizerId = getOrganizerDocumentTarget();
		const uploads = _.cloneDeep(uploadList);

		const newUploads = uploads.filter(upload => upload.isNew);
		const numNewUploads = newUploads.length;

		// Download files individually if less than 5 new files
		if (numNewUploads < 5) {
			const downloadPromises = newUploads.map((newUpload) => {
				return downloadFile(newUpload, true);
			});

			const downloadResults = await Promise.all(downloadPromises);

			uploads.forEach((upload) => {
				upload.downloaded_on = `${new Date().toJSON()}`;
				upload.isNew = false;
			});

			dispatch(ACTION.setUploadList(uploads));
			return;
		}

		// Package 'isNew' files into a zip if 5 or more files
		let zip = new JSZip();
		for (const upload of uploads) {
			if (!upload.isNew) {
				continue;
			}

			upload.downloaded_on = `${new Date().toJSON()}`;
			upload.isNew = false;

			let blobContent = isFileToConvertToPdf(upload)
				? await convertImageToPdf(targetOrganizerId, upload, true)
				: await downloadDocumentFile(targetOrganizerId, upload.id, true);
			let fileName = getFileName(upload, keepImageFormat);

			if (!blobContent) {
				throw new Error(`Received no file content for ${upload.name}`);
			}

			zip.file(fileName, blobContent, { blob: true, base64: true });
		}

		return zip.generateAsync({ type: "blob" }).then((content) => {
			saveAs(content, `${activeReturn.clientNumber}_${activeReturn.displayName}_${moment().format("MM-DD-YYYY H:m:ss")}.zip`);
			dispatch(ACTION.setUploadList(uploads));
		});
	};

	const formState = selectState(card ? card.formName : null);

	const validateCurrentCard = () => {
		const storeForValidate = store.getState();
		const globalState = storeForValidate.get('global')
		// Determine whether or not there are fields with errors for this card
		// @TODO how are we calculating a card statusId once errors are resolved?
		// @TODO will users be able to edit the card in a complete state? Should I update error status on card if in complete state?
		// @TODO move to usePageFramework?

		let errorCount = 0;

		//stores all vehicle entity keys in the current form
		const vehicleEntitiesAndParentEntities = {}

		//stores all form entity keys in current form
		const currentFormKeys = []

		globalState.forEach((value, key) => {
			if (card) {
				if (key === card.formName) {
					if (card.formName === 'businessIncome' || card.formName === 'rentalIncome' || card.formName === 'farmIncome') {
						if (value.length > 0) {
							value.forEach((formEntity) => {
								currentFormKeys.push(formEntity.formNameKey)
							})
						} else {
							currentFormKeys.push(`${card.formName}-0-0`)
						}

					} else {
						currentFormKeys.push(card.formName)
					}
				}

				if (key === 'vehicle') {
					if (value) {
						value.forEach((vehicleEntity) => {

							const parentEntityKeys = [];

							//pushes collects all parentEntityKeys for one vehicleEntity
							vehicleEntity.parentEntityKeys.forEach((parentEntity) => {
								if (_.startsWith(parentEntity.parentFormName, card.formName)) {
									parentEntityKeys.push(parentEntity.parentFormName)
								}
							})

							//adds all collected parentEntityKeys to the vehicleEntities
							vehicleEntity.parentEntityKeys.forEach((parentEntity) => {
								if (_.startsWith(parentEntity.parentFormName, card.formName)) {
									if (parentEntity.isRemoved !== true) {
										vehicleEntitiesAndParentEntities[vehicleEntity.formNameKey] = {
											keyName: vehicleEntity.formNameKey,
											parentEntityKeys: parentEntityKeys,
										}
									}
								}
							})
						})
					}
				}

				if (key.startsWith('provider') && card.formName === 'dependentCare') {
					currentFormKeys.push(key)
				}

				if (key.startsWith('state') && card.formName === 'taxPayments') {
					currentFormKeys.push(key)
				}

			}
		})

		//check if vehicleEntities are disabled in rows
		const passedVehicleEntities = vehicleEntityNotApplicableChecker(globalState, currentFormKeys, vehicleEntitiesAndParentEntities);

		//check form errors
		errorCount += checkFormErrors(globalState, currentFormKeys)

		//check vehicle entity errors
		errorCount += checkFormErrors(globalState, passedVehicleEntities)

		updateCardStatus(formState, CARDSTATUS, card, errorCount, updateStatus)

	};

	const setCardsRequired = (requiredCards, isSetRequiredCards) => {

		dashboard.forEach((cat) => {
			cat.cards.forEach((card) => {
				if (requiredCards.length === 1) {
					if (card.formName === requiredCards[0]) {
						card.isRequired = isSetRequiredCards;
					}
				} else {
					requiredCards.forEach(requiredCard => {
						if (card.formName === requiredCard.card) {
							card.isRequired = requiredCard.isSetRequired;
						}
					})
				}
			});
		});

		dispatch(ACTION.setDashboard(dashboard));
		const data = { dashboard: dashboard, lastUserActivityOn: lastUserActivityDateSetter.lastUserActivityDate };
		const dashboardData = { dashboard: dashboard, updatedOn: lastUserActivityDateSetter.lastUserActivityDate };

		//to remoove lastUserActivityOn when null
		if (!data.lastUserActivityOn) delete data.lastUserActivityOn;

		if (Object.keys(data).length > 0) {
			api.put(`/organizers/${organizerId ?? ''}`, data).catch((error) => {
				console.error('Unable to update organizer, error: ', error);
				dispatch(ACTION.setIsSaveSuccess(false));
			});
		}

		api.put(`/organizers/${organizerId ?? ''}/dashboard/${dashboardId}`, dashboardData).catch((error) => {
			console.error('Unable to save dashboard, error: ', error);
			dispatch(ACTION.setIsSaveSuccess(false));
		});
	};

	const getData = () => {
		return new Promise(() => {
			return {
				status: 'success',
				uploads: uploadList
			}
		});
	};

	const updateUploadList = async (organizerId) => {
		const documents = await api.get(`/organizers/${organizerId}/documents`)
			.then((response) => response.data.results);

		/*
			GROWTH: remove this transformation
				This transformation is done due to compatability with
					form renderer, document manager, and sharepoint implementation
		*/
		const transformedDocuments = documents.map((document) => {
			document.section = updateUploadSection(document.section);
			document.updated_on = document.updatedOn;
			document.downloaded_on = document.downloadedOn;
			document.upload_id = document.id;
			document.uploaded_by = document.createdBy;
			return document;
		});
		// store upload list in state
		await dispatch(ACTION.setUploadList(transformedDocuments));
	};

	return {
		getData,
		page,
		card,
		currentCardKey,
		clearFormState,
		organizerId,
		history,
		dashboard,
		dispatch,
		screenSize,
		selectState,
		setGlobalFormState,
		setEntityFormState,
		location,
		uploadList,
		updateCard,
		updatePage,
		updateStatusCard,
		updateStatus,
		updateVisibleDashboardCards,
		updateSortedDashboardCards,
		onFileUpload,
		uploadFiles,
		onFileRemoval,
		onFilePreview,
		onFileRename,
		downloadFile,
		downloadAllFiles,
		downloadNewFiles,
		updateUploadList,
		REDUX,
		NAV,
		ACTION,
		CARDSTATUS,
		INPUTSTATE,
		validateCurrentCard,
		appType,
		setCardsRequired,
		lastSaveFormTime,
		buildDocumentPreview,
		renderTiffImage,
		authUserEmail,
		moveFile,
	};
}

export default usePageFramework;

export {
	currentYear
};