// import { Button, Avatar, TextIconSpacing, BadgeContainer, Badge } from 'react-md';
// import { ExpandMoreSVGIcon, NotificationsNoneSVGIcon } from '@react-md/material-icons';

import './headerMenu.css'
import usePageFramework from '@utilities/hooks/usePageFramework';
import * as IMG from '@utilities/constants/images';
import * as STRING from '@utilities/constants/strings';
import ShowLastFormSave from  '@components/showLastFormSave';

function HeaderMenu() {
	const { history, dispatch, selectState, REDUX, NAV, location, ACTION, validateCurrentCard } = usePageFramework();
	const isPractitioner = selectState(REDUX.IS_PRACTITIONER);
	const isAdmin = selectState(REDUX.IS_ADMIN);
	const isAuthenticated = selectState(REDUX.IS_AUTHENTICATED);
	const activeReturn = selectState(REDUX.ACTIVE_RETURN);
	const toolbarTitle = selectState(REDUX.TOOLBAR_TITLE);
	const isPracDash = toolbarTitle === STRING.EXCHANGE_MANAGER_1040 || toolbarTitle === 'Document Manager';
	const isDashboard = toolbarTitle === STRING.DASHBOARD || location?.pathname === '/dashboard';
	const active = 'headerMenuTextSelected headerMenuCustomMargin';
	const inactive = 'headerMenuText headerMenuCustomMargin';
	const goBack = () => {
		validateCurrentCard();
		dispatch(ACTION.setCurrentCardKey(STRING.DASHBOARD_KEY));
		dispatch(ACTION.setDashboardFilterStatus(-1));
		dispatch(ACTION.setDashboardSortRequirements('traditional'));
		history.push(NAV.DASHBOARD);
	};

	const tempLoadData = () => {
		if (isPractitioner || isAdmin) {
			validateCurrentCard();
			dispatch(ACTION.setCurrentCardKey(null));
			history.push(NAV.PRACTITIONERS_DASHBOARD);
		}
	};

	const handleDocManager = () => {
		validateCurrentCard();
		dispatch(ACTION.setCurrentCardKey(null));
		history.push(`${NAV.DOCUMENT_MANAGER}`);
	};

	const buildActiveReturn = () => {
		return (
			<div className="activeReturnContainer">
				<div
					className={
						activeReturn ? !isPracDash ?
							'headerMenuTextSelected headerMenuCustomMargin' :
							'headerMenuText headerMenuCustomMargin' :
							'inactiveHeaderMenuText headerMenuCustomMargin'
					}
					id="dashboard"
					onClick={activeReturn ? () => goBack() : () => { }}
					style={{ marginRight: '55px' }}
				>
					Active Client
				</div>
				{activeReturn ?
					<div onClick={() => goBack()} className="navClientInfoContainer">
						<div className='activeReturnName'>{activeReturn?.displayName}</div>
						<div className='activeReturnNumber'>{`CLIENT ID #${activeReturn.clientNumber}`}</div>
					</div>
					: <> </>}
			</div>
		);
	};

	return (
		<div className="navbar">
			<div className="navbarMenu">
				<img className="site-logo" alt="CLA Navy colored logo" src={IMG.CLA_NAVY_LOGO} />
				{
					(isPractitioner || isAdmin) && isPracDash ?
						buildActiveReturn() :
						// isPractitioner && isPracDash ? 
						//     buildActiveReturn() : 
						isAuthenticated && <>
							<div
								className={
									location.pathname === '/dashboard' || (location.pathname === '/' && (!isPractitioner && !isAdmin)) ?
										active : inactive
								}
								id="dashboard"
								onClick={() => !isDashboard && goBack()}
							>
								{STRING.DASHBOARD}
							</div>
						</>

				}

				{isPractitioner || isAdmin ?
					<div
						className={
							location.pathname === '/practitionersDashboard' || (location.pathname === '/' && (isPractitioner || isAdmin)) ?
								active : inactive
						}
						onClick={() => tempLoadData()}
						id="practitionersDashboard"
					>
						1040 Exchange Manager
					</div> : <></>
				}
				{
					toolbarTitle !== '1040 Exchange Manager' && isAuthenticated &&
					<div
						className={location.pathname === `/documentManager` ? active : inactive}
						onClick={() => handleDocManager()}
					>
						Document Manager
					</div>
				}
				<ShowLastFormSave className={(isPractitioner || isAdmin) ? 'navbarLastSaveMsg' : 'navbarClientLastSaveMsg'} />			
			</div>
			{/* <div className="navbarIconButton"> */}
				{/* TODO: Hidden until functionality is implemented */}
				{/* <SearchField placeholder="Search" />
                <BadgeContainer>
                    <Button
                        id="custom-badged-button"
                        aria-describedby="custom-badged-button-badge"
                        buttonType="icon"
                    >
                        <TextIconSpacing icon={<ExpandMoreSVGIcon />} iconAfter>
                            <NotificationsNoneSVGIcon />
                        </TextIconSpacing>
                    </Button>
                    <Badge id="custom-badged-button-badge" className="navbarBellBadge">3</Badge>
                </BadgeContainer>
                <Button id="combined-button-2">
                    <TextIconSpacing icon={<ExpandMoreSVGIcon />} iconAfter>
                        <Avatar src="icons/claAvatar.svg" />
                    </TextIconSpacing>
                </Button> */}
			{/* </div> */}
		</div>
	);
}

export default HeaderMenu;