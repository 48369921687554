import { useState, useEffect } from 'react';
import usePageFramework from '@utilities/hooks/usePageFramework';
import FormRendererHelper from '@components/formRenderer/index';
import { sectionFieldLogic } from './taxPaymentsLogicTrigger';
import getYear from '@utilities/helpers/getYear';
import * as NAV from '@utilities/constants/navigation';
import getFormTemplateForYear from '@utilities/helpers/formTemplate';
import taxPayments2021 from './2021';
import taxPayments2022 from './2022';
import taxPayments2023 from './2023';

function TaxPayments(props) {
    const { isExportForm } = props;
    const { updateCard, REDUX, selectState } = usePageFramework(NAV.TAX_PAYMENTS);
    const [sectionState, setSectionState] = useState('');
    const year = getYear(selectState);
    
    useEffect(() => {
        if (!isExportForm) updateCard();

        const formTemplatesByYear = {
            2021: taxPayments2021(year),
            2022: taxPayments2022(year),
            2023: taxPayments2023(year)
        };

        const formSections = getFormTemplateForYear(year.current, formTemplatesByYear);
        setSectionState(formSections);
        // eslint-disable-next-line
    }, []);

    const axcessIdentifiers = [
        // { section: 'Payments/Penalties/Estimates', id: 'Payments' },
        { section: 'Federal Link Worksheet', id: 'Government' },
        { section: 'Payments/Penalties/Estimates', id: 'Estimates and Application of Overpayment' },
    ];

    return (
        <FormRendererHelper sections={sectionState} key="TaxPayments" formName={REDUX.TAX_PAYMENTS} identifiers={axcessIdentifiers} sectionFieldLogic={sectionFieldLogic}
            isExportForm={isExportForm}
        />
    );

}
export default TaxPayments;