import { useState, useEffect } from 'react';
import usePageFramework from '@utilities/hooks/usePageFramework';
import FormRenderer from '@components/formRenderer';
// import { PROPERTY_TAXES_PAID_UPLOAD } from '@utilities/constants/strings';
import * as NAV from '@utilities/constants/navigation';
import getYear from '@utilities/helpers/getYear';
import getFormTemplateForYear from '@utilities/helpers/formTemplate';
import propertyTax2021 from './2021';
import propertyTax2022 from './2022';
import propertyTax2023 from './2023';

function PropertyAndOtherStateTaxes(props) {
    const { isExportForm } = props;
    const { updateCard, REDUX, selectState } = usePageFramework(NAV.PROPERTYANDOTHERSTATETAXES);
    const [sectionState, setSectionState] = useState('');
    const year = getYear(selectState);

    useEffect(() => {
        if (!isExportForm) updateCard();

        const formTemplatesByYear = {
            2021: propertyTax2021(year),
            2022: propertyTax2022(year),
            2023: propertyTax2023(year)
        };

        const formSections = getFormTemplateForYear(year.current, formTemplatesByYear);

        setSectionState(formSections);
        // eslint-disable-next-line
    }, []);

    const axcessIdentifiers = [
        { section: 'Deductions', id: 'Sch A' },
    ];

    return (
        <FormRenderer
            sections={sectionState}
            key="PropertyAndOtherStateTaxesForm"
            formName={REDUX.PROPERTY_STATE_TAXES}
            identifiers={axcessIdentifiers}
            isExportForm={isExportForm}
        />
    );

}
export default PropertyAndOtherStateTaxes;