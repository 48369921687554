import React, { useState, useEffect } from 'react';
import { Grid, GridCell, TextIconSpacing, FontIcon, Button, Divider, useToggle, GroupAddFontIcon } from 'react-md';
import _ from 'lodash';
import { AddSVGIcon, RemoveSVGIcon } from '@react-md/material-icons';
import { frameworkSetter } from '../../../hooks/usePageFramework';
import moneyFormatter, { noDollarFormatter } from '../../../utils/moneyFormatter';
import { buildField, buildTextArea, buildLabel, buildSelect, buildCheckbox, buildRadio, buildViewButton, buildMultiSelectDropdown } from '../buildControls';
import LineItemButtons from '../lineItemButtons/index';
import LineItemSection from '../lineItemSection/index';
import LineItemDrawer from '../lineItemDrawer/index';
import DenseRowBtn from './denseRowBtn';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import prePopulateLineItems from '../../../utils/populatePriorData/prePopulateLineItems';
import LineItemFooter from '../lineItemFooter/index';
import LineItemHeader from '../lineItemHeader/index';
import TableManagerTabs from '../tableManagerTabs/index';
import ReactPaginate from 'react-paginate';
import { updateMasterTotals } from '../../../utils/updateTotals';
import { ObjectId } from 'bson';

function LineItemDetail(props) {
    const { group,
        colspan,
        screenSize,
        fns,
        isFormLocked,
        section,
        sectionIndex,
        groupIndex,
        sections,
        entityIndex,
        showSharedEntityDialog,
        parentGroup,
        parentIndex,
        isDenseRowNA,
        setParentCurrentEntity,
        renderLineItemSections,
        grandParentGroup,
        formSections,
        allSections
    } = props;
    const showDefaultDenseRowLineItem = Boolean(group.defaultDenseRowLineItem);
    const { selectState, REDUX, INPUTSTATE, dispatch, ACTION, history } = frameworkSetter.usePageFramework();
    const uploadList = selectState(REDUX.UPLOAD_LIST);
    const [lineItemCtrls, setLineItemCtrls] = useState([]);
    const [headerDetail, setHeaderDetail] = useState([]);
    const [footerDetail, setFooterDetail] = useState([]);
    const [menuTabsDetail, setMenuTabsDetail] = useState(null);
    const [paint, setPaint] = useState(false);
    const { width } = useWindowDimensions();
    const [footerBtns, setFooterBtns] = useState([]);
    const [footerLabels, setFooterLabels] = useState(false);
    const [summaryTotals, setSummaryTotals] = useState(null);
    const [itemOffset, setItemOffset] = useState(0);
    const [pageCount, setPageCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const isLineDisabled = isFormLocked || isDenseRowNA || section?.isEntityNa;
    const itemsPerPage = 4;
    const endOffset = itemOffset + itemsPerPage;
    //dense rows related
    const [currentEntity, setCurrentEntity] = useState(0);

    const [visible, showLineitems, hideLineitems] = useToggle(false);
    const [currentH2, setCurrentH2] = useState(group.isH2TabsHidden ? group?.lineSections[0]?.title : 'Summary');
    const [isAddingNewEntity, setIsAddingNewEntity] = useState(false);
    const hasDenseRow = section.hasDenseRow && group.lineSections?.length;
    const isDenseRow = section.isDenseRow;
    const formHeaderContainer = section.title === 'Occupant Details' ? 'denseRowformHeaderContainer' :
        isDenseRow ? 'formHeaderContainer denseRowHeaderMargin' : 'formHeaderContainer';

    const updateTableManager = () => {
        if (group.hasTableManagerTabs || group.isSummaryRow) {

            group.currentH2 = group.isH2TabsHidden ? group?.lineSections[0]?.title : group.currentH2 || currentH2;
            setCurrentH2(group.currentH2);
            group.isAddingNewEntity = isAddingNewEntity;
            if (group.hasTableManagerTabs && group.currentEntity >= 0) {
                const newCurrentPage = Math.ceil((group.currentEntity + 1) / 4);
                const newOffset = (newCurrentPage - 1) * 4;
                setItemOffset(newOffset);
                setCurrentPage(newCurrentPage - 1);
            }

            if (currentH2 && (group.isSummaryRow ? group.currentEntity === 0 : group.currentEntity >= 0)) {
                if (group.currentH2 === 'expandAll') {
                    group.entities[group.currentEntity].sections.forEach((section, idx) => {
                        if (idx === 0) {
                            section.isCollapsed = false;
                        } else {
                            section.isCollapsed = true;
                        }
                    });
                }
                else {
                    if (group.currentH2 === 'Summary') {
                        group.entities[group.currentEntity].sections.forEach((section) => {
                            section.isCollapsed = true;
                        });
                    }
                    group.entities[group.currentEntity].sections.forEach((section) => {
                        if (section.title === group.currentH2 && section.isCollapsed) {
                            section.isCollapsed = false;
                        }
                        if (section.title !== group.currentH2 && !section.isCollapsed) {
                            section.isCollapsed = true;
                        }
                    });
                }
            }
        }

        if (group.isSummaryRow) {
            group.currentH2 = parentGroup.currentH2;
            setCurrentH2(group.currentH2);
            group.currentEntity = parentGroup.currentEntity === -2 ? 0 : -1;
            setCurrentEntity(group.currentEntity);
        }
    };
    // group?.hasHiddenDeletedItem is for PY entity data deleted
    const filteredGroupLineItems = group?.hasHiddenDeletedItem ? group.lineItems.filter(lineItem => {
        return !lineItem[0]?.default?.includes("Title: PLEASE DELETE");
    }) : group.lineItems;
    const deletedPriorYearLineItemsCount = group?.hasHiddenDeletedItem ? group.lineItems.length - filteredGroupLineItems.length : 0;

    const handlePageClick = ({ selected: selectedPage }) => {
        const newOffset = selectedPage * itemsPerPage % (group.lineItems.length - deletedPriorYearLineItemsCount);
        setItemOffset(newOffset);
        group.currentEntity = -1;
        setCurrentPage(selectedPage);
    }

    useEffect(() => {
        _.forEach(group.lineItems, lineItem => {
            lineItem[0].drawerVisible = false;
        });
    }, []);
    useEffect(() => {
        if (showDefaultDenseRowLineItem) {
            group.currentViewField = 100;
            group.currentEntity = 0;
            setCurrentEntity(group.currentEntity);
        }
    }, []);

    useEffect(() => {
        if (showDefaultDenseRowLineItem) {
            group.currentViewField = 100;
            setCurrentEntity(group.currentEntity);
            createLineItemCtrl(group, colspan);
        }
        else {
            createLineItemCtrl(group, colspan);
            setCurrentEntity(group.currentEntity);
        }

        updateTableManager();
        setPageCount(Math.ceil((group.lineItems.length - deletedPriorYearLineItemsCount) / itemsPerPage));
    }, [
        group,
        paint,
        width,
        currentEntity,
        currentH2,
        isAddingNewEntity,
        parentGroup,
        itemOffset
    ]);

    useEffect(() => {
        setBondID();
    }, [section.title, group.lineItems, group.entities]);

    // To remove the extra bonds created due to unexpected addition on newLineItem
    if (formSections?.[2]?.groups?.[1]?.bonds?.length !== formSections?.[1]?.groups?.[0]?.entities?.length) {
        formSections?.[2]?.groups?.[1]?.bonds?.pop();
        formSections?.[3]?.groups?.[1]?.bonds?.pop();
        formSections?.[4]?.groups?.[1]?.bonds?.pop();
        fns.saveForm();
    }

    const buildLineButtons = (field, colspan, index, group, isLineDisabled, fn, section, parentGroup, parentIndex, grandParentGroup, lineItemSectionName) => (
        <LineItemButtons
            key={`grp-${group.groupId}-lib-${field.name}-${index}`}
            field={field}
            colspan={colspan}
            group={group}
            index={index}
            fns={fn}
            isFormLocked={isLineDisabled}
            entityIndex={entityIndex}
            section={section}
            parentGroup={parentGroup}
            parentIndex={parentIndex}
            formSections={formSections}
            lineItemSectionName={lineItemSectionName}
            grandParentGroup={grandParentGroup}
            allSections={allSections}
        />
    );

    const uploadType = group.uploadType || 'single';
    const sectionName = uploadType === 'single' ? 'single' : group.lineItemDetails.lineButtons[0].uploadSectionName;

    const saveForm = (field, nextId) => {

        updateTotals(group);
        setPaint(!paint);

        if (fns) {
            fns.saveForm(field, nextId);
        }
    };

    const updateTotals = (group) => {
        const totals = [];

        filteredGroupLineItems.forEach((lineItem) => {
            const isNA = lineItem[0] && lineItem[0].notApplicable;
            const totalFields = lineItem.filter(x => (x.isTotal && !x.prior) || x.showToFooter);
            totalFields.forEach((fields) => {
                const total = totals.find(x => x.name === fields.name);
                // will fields.NumberValue affects anything??
                const value = fields.amount || fields.default;
                const numberValue = value ? value.replace(/[^0-9.]/g, '') : 0.0;
                const defaultValue = isNA ? 0 : Number(numberValue) || 0.0;

                if (total) {
                    total.default += defaultValue;
                } else {
                    totals.push({ name: fields.name, default: defaultValue });
                }
            });
        });

        const footerLabels = group.lineItemDetails.footerLabels;

        totals.forEach((total) => {
            const footer = footerLabels.find(x => x.name === total.name);

            if (footer) {
                footer.amount = total.default;
                footer.label = footer.type == 'totalMoney' ? moneyFormatter(Number(total.default), group.currencySymbol) : noDollarFormatter(Number(total.default));
                if (footer.logicFunction) {
                    footer.logicFunction.forEach((logic) => {
                        logic.isDifferentGroup ? fns.triggered(logic.trigger, footer.label, {}) :
                            fns.sectionFieldLogic(logic?.trigger, { group, field: footer, logic, parentGroup, parentIndex, section });
                    });
                }
            }
        });
    };

    const setBondID = () => {
        if (section.title === 'Basic Bond Information') {
            group.lineItems.forEach((item, idx) => {
                item.forEach(field => {
                    if (field.name === 'bondID') {
                        field.default = idx + 1;
                    }
                });
            });

            group.entities.forEach((entity, idx) => {
                if (entity && entity.sections) {
                    entity.sections.forEach(section => {
                        section.bondID = idx + 1;
                    });
                }
            });
        }
    }

    const newLineItem = (group, isAddingToLineItem) => {
        const { fields, lineItems } = group;

        if (lineItems.length === 200) {
            dispatch(ACTION.setShowCustomDialog(true));
            dispatch(ACTION.setCustomDialogTitle(''));
            dispatch(ACTION.setCustomDialogMsg('Maximum entry is 200 records. \n Please contact CLA for assistance.'));
            return;
        }

        const itemFields = fields.map((field) => {
            const item = {};

            Object.keys(field).forEach((key) => item[key] = field[key]);
            item.hideLabel = screenSize === 'Desktop' ? true : false;

            setDefaultValues(item);

            return item;
        });

        if (isAddingToLineItem) {
            lineItems.splice(lineItems.length - deletedPriorYearLineItemsCount, 0, itemFields);

            setBondID();

            if (group.groupType === 'lineSection') {
                fns.saveForm();
            }
        }

        if (group.lineSections) {
            const copySection = _.cloneDeep(group.lineSections);
            copySection.forEach((section, idx) => {
                if (idx === 0) section.isCollapsed = false;
                else section.isCollapsed = true;
            });
            group.currentH2 = group?.lineSections[0]?.title;
            setCurrentH2(group.currentH2);

            group.entities.splice(group.entities.length - deletedPriorYearLineItemsCount, 0, { id: new ObjectId().toString(), sections: copySection });
            setBondID();
        }

        if (formSections?.[1]?.groups?.[0]?.entities?.length) { //To not generate bonds sections when there is no first entity created on basic bond info section 
            if (formSections?.[2]?.groups?.[1]?.bondsSections) {
                generateBond(formSections[2].groups[1], group, lineItems);
            }

            if (formSections?.[3]?.groups?.[1]?.bondsSections) {
                generateBond(formSections[3].groups[1], group, lineItems);
            }

            if (formSections?.[4]?.groups?.[1]?.bondsSections) {
                generateBond(formSections[4].groups[1], group, lineItems);
            }
        }

        return itemFields;
    };

    const setBondItem = (bondItem, group, lineItems) => {
        const copySection = _.cloneDeep(bondItem.bondsSections);
        bondItem.currentEntity = 0;// lineItems.length - 1 - deletedPriorYearLineItemsCount;
        bondItem.currentViewField = 100;
        bondItem.currentH2 = bondItem?.bondsSections[0]?.title;
        bondItem.isAddingNewEntity = true;

        // bondItem.bonds.push({ id: new ObjectId().toString(), sections: copySection });
        group.currentEntity = 0; //lineItems.length - 1 - deletedPriorYearLineItemsCount;
        group.currentViewField = 100;
        setCurrentEntity(group.currentEntity);
        group.isAddingNewEntity = true;
        setIsAddingNewEntity(group.isAddingNewEntity);
        fns.saveForm();
    }

    const generateBond = (bondItem, group, lineItems) => {
        bondItem.bondsSections[0].isCollapsed = true;
        const copySection = _.cloneDeep(bondItem.bondsSections);
        bondItem.bonds.push({ id: new ObjectId().toString(), sections: copySection });
        group.currentEntity = lineItems.length - 1 - deletedPriorYearLineItemsCount;
        group.currentViewField = 100;
        setCurrentEntity(group.currentEntity);
        group.isAddingNewEntity = true;
        setIsAddingNewEntity(group.isAddingNewEntity);
        fns.saveForm();
    }

    const setDefaultValues = (item) => {
        if (item.name === 'totalReimbursedAndPaid') {
            item.default = `${group.currencySymbol ? group.currencySymbol : '$'}0`;
        }

        if (item.name === 'foreignIncomeOwner') {
            item.default = group.foreignIncomeOwner ? group.foreignIncomeOwner : '';
        }

        if (item.name === 'visaType') {
            item.default = group.visaType ? group.visaType : '';
        }
    };

    const footerStyles = (group, item) => {
        const subSecWithYear = group.hasSubSectionTotalDisplay && !item.year;
        const labelsClass = !group.hasSubSectionTotalDisplay ?
            'footerLabelMoney' :
            subSecWithYear && item.name !== 'salePrice' ? 'businessEquipmentFooter' :
                subSecWithYear && item.name === 'salePrice' ? 'salePriceFooter' :
                    'footerSubSectionLabelMoney';

        if ((item.type === 'total' || item.type === 'totalMoney') && !item.align) {
            return labelsClass;
        } else if (!group.hasSubSectionTotalDisplay && item.align === 'left') {
            return 'footerLabelMoneyLeft';
        } else if (group.hasSubSectionTotalDisplay && item.align === 'left') {
            return 'footerSubSectionLabelMoneyLeft';
        } else if (item.type === 'label') {
            return 'footerLabelText';
        } else return '';
    };

    const addNewLineItem = (group) => {
        newLineItem(group, true);

        setPaint(!paint);
        if (group.hasTableManagerTabs || group.lineSections) {
            //to expand the h2s of newly added lineItem row
            group.currentEntity = group.lineItems.length - 1 - deletedPriorYearLineItemsCount;
            group.currentViewField = 100;
            setCurrentEntity(group.currentEntity);
            group.isAddingNewEntity = true;
            setIsAddingNewEntity(group.isAddingNewEntity);
        } else {
            // This will collapse expanded dense rows
            group.currentEntity = -1
        }
        if (group?.logicFunction?.length > 0) {
            const index = _.findIndex(group?.logicFunction, { triggerAddEntity: true });
            if (index > -1) {
                fns.sectionFieldLogic(group?.logicFunction[index].trigger, { group, section, fns, parentGroup, parentIndex, allSections })
            }
        }

    };

    // TODO: Fix data syncing with pagination
    const createLineItemCtrl = (group, colspan) => {
        const lineItems = filteredGroupLineItems
        const footerClass = group.footerClass || 'formFooterLabel';
        const denseRowFooterMargin = isDenseRow ? 'denseRowFooterMargin' : '';
        if (group.prePopulate && group.prePopulate.length) {
            prePopulateLineItems(group);

            if (group.lineSections) {
                const copySection = _.cloneDeep(group.lineSections);
                group.prePopulate.forEach(prePop => {
                    group.entities.push({ id: new ObjectId().toString(), sections: copySection });
                });
            }

            group.prePopulate = [];
        }

        if (!lineItems.length) {
            newLineItem(group, true);
            if (group.hasTableManagerTabs) {
                //to expand the h2s of newly added lineItem row
                group.currentEntity = group.lineItems.length - 1 - deletedPriorYearLineItemsCount;
                group.currentViewField = 100;
                setCurrentEntity(group.currentEntity);
                group.isAddingNewEntity = true;
                setIsAddingNewEntity(group.isAddingNewEntity);
            }
        } else {
            if (group.currentEntity === null) {
                group.currentEntity = 0;
                group.currentH2 = group?.lineSections[0]?.title;
                setCurrentH2(group.currentH2);
                setCurrentEntity(group.currentEntity);
                if (formSections?.[2]?.groups?.[1]?.bondsSections) {
                    setBondItem(formSections[2].groups[1], group, lineItems);
                }

                if (formSections?.[3]?.groups?.[1]?.bondsSections) {
                    setBondItem(formSections[3].groups[1], group, lineItems);
                }

                if (formSections?.[4]?.groups?.[1]?.bondsSections) {
                    setBondItem(formSections[4].groups[1], group, lineItems);
                }
            }
        }

        // this condition doesn't get the colspans of the sub sections fields
        // const isDesktop = screenSize === 'Desktop';
        const isShowHeader = group.isShowHeader !== false;
        const headerLabels = isShowHeader && group.lineItemDetails.headerLabels.map((item, index) => {
            return <LineItemHeader key={index} item={item} width={width} index={index} />;
        }).filter(item => item);

        const footerButtons = group.lineItemDetails.footerLabels
            .filter(footerLabel => footerLabel.type === 'buttons')
            .map((item, index) => {
                return (
                    <Grid
                        key={`btn-add-${index}`}
                        className={!hasDenseRow ? 'formFooterBtn' : 'denseRowFormFooterBtn'}
                    >
                        <Button
                            disabled={isLineDisabled}
                            className="lineItemButton"
                            onClick={() => addNewLineItem(group)}
                        >
                            <TextIconSpacing
                                icon={
                                    <FontIcon className="addIcon">
                                        {item.icon}
                                    </FontIcon>
                                }>
                                {item.buttonTitle}
                            </TextIconSpacing>
                        </Button>
                        { (item.linkLabel && item.path) ? <a style={{marginLeft: 'auto', fontSize: '13px'}} href='javascript: void(0)' onClick={()=>history.push(item.path)}>{item.linkLabel}</a>: ''}
                    </Grid>
                );
            });

        setFooterBtns(footerButtons);
        // Conditions for showing footer on fields with amounts or
        // H1's/H2's with footer totals
        const moneyTypeFields = group.fields
            .filter(item => item.type === 'money' && !item.hideFooter);
        const filteredFooterLabels = group.lineItemDetails.footerLabels
            .filter(item => item.type === 'totalMoney' || item.type === 'total');
        const isShowFooter = group.isShowFooter !== false;
        // remove this condition to show footers on all forms
        // regardless if the footerlabels is empty
        const footerLabels = isShowFooter &&
            (filteredFooterLabels.length > 0 || moneyTypeFields.length > 0) &&
            <Grid key={`footer-grid-${group.groupId}`} className={`${footerClass} ${denseRowFooterMargin}`}>
                {
                    group.lineItemDetails.footerLabels.map((item, index) => {
                        return <LineItemFooter key={index} group={group} item={item} index={index} width={width} />;
                    })
                }
            </Grid>;

        setFooterLabels(footerLabels);

        if (group.hasTableManagerTabs) {
            const items = lineItems.slice(itemOffset, endOffset);
            // this should take care when removed.
            // if currentPage last item removed, set currentPage to prev Page
            if (!items.length && pageCount >= 1) {
                setCurrentPage(pageCount - 1);
                //need new offset to set the new current page data properly
                const newOffset = (currentPage - 1) * 4;
                setItemOffset(newOffset);
            }
        }

        let lineItemCount = 0;
        const paginatedLineItems = group.hasTableManagerTabs ? lineItems.slice(itemOffset, endOffset) : lineItems;
        const items = paginatedLineItems.map((lineItem, linePIndex) => {
            const lineIndex = (currentPage * itemsPerPage) + linePIndex;
            const isLineItemVisible = typeof (lineItem.isVisible) === 'undefined' ? true : lineItem.isVisible;
            if (!isLineItemVisible) return (null);

            const lineItemSectionName = entityIndex || entityIndex === 0 ? `${entityIndex}-${lineItemCount}-${sectionName}` : `${lineItemCount}-${sectionName}`;
            let fieldCount = -1;
            let drawer = null;
            const uploadDetails = lineItem[0];
            const lineItemIndex = group.hasTableManagerTabs ? lineItems.indexOf(lineItem) : lineItemCount;

            const fields = lineItem.map((field, index) => {
                const isVisible = typeof (field.isVisible) === 'undefined' ? true : field.isVisible;

                if (isVisible) {
                    fieldCount++;
                }

                const colType = width < 768 ? 'colPhone' : width < 1025 ? 'colTablet' : 'col';

                if (field.inputState === INPUTSTATE.PRIOR_INPUT && !field.isPriorEditable && !field.isTextFieldWithPrior) field.type = 'label';

                if (field.uniqueName) fns.setUniqueEntityFieldValue({ field: field });

                if (!field.isPriorTriggered && field?.priorYearValue && field.logicFunction && !parentGroup?.isSummaryRow) {
                    field.logicFunction.forEach((logic) => {
                        const fieldValue = field.default ? field.default : field.priorYearValue;
                        logic.isDifferentGroup ? fns.triggered(logic.trigger, fieldValue, null) :
                            fns.sectionFieldLogic(logic?.trigger, { group, field, index: lineIndex, logic, parentGroup, parentIndex })
                    });
                    field.isPriorTriggered = true;
                    if (field.requiredCardsToSet) {
                        setCardsRequired(field.requiredCardsToSet, field.isSetRequiredCards);
                    }
                }

                // this condition doesn't get the colspans of the sub sections fields
                const col = group.lineItemDetails.headerLabels.length > 0 &&
                    group.lineItemDetails.headerLabels[fieldCount] ?
                    group.lineItemDetails.headerLabels[fieldCount][colType] ?
                        group.lineItemDetails.headerLabels[fieldCount][colType] :
                        colspan : colspan;

                let isFieldVisible = typeof (field.isVisible) === 'undefined' ? true : field.isVisible;

                if (typeof (field.isVisible) === 'undefined') {
                    if (width < 1025 && col != colspan) {
                        isFieldVisible = true;
                    } else if (width < 1025 && field.name === '') {
                        isFieldVisible = false;
                    } else if (width > 1025) {
                        isFieldVisible = true;
                    }
                }

                if (!isFieldVisible) return ('');

                let ctrl = buildField;

                switch (field.type) {
                    case 'select':
                        ctrl = buildSelect;
                        break;
                    case 'multiSelectDropdown':
                        ctrl = buildMultiSelectDropdown;
                        break;
                    case 'checkbox':
                        ctrl = buildCheckbox;
                        break;
                    case 'checkboxLabel':
                        ctrl = buildCheckbox;
                        break;
                    case 'radio':
                        ctrl = buildRadio;
                        break;
                    case 'label':
                        ctrl = buildLabel;
                        break;
                    case 'textarea':
                        ctrl = buildTextArea;
                        break;
                    case 'lineButtons':
                        if (uploadType !== 'single' && uploadList) {
                            const filteredList = uploadList.filter(x => x?.section?.toLowerCase().trim() === lineItemSectionName?.toLowerCase().trim());

                            if (filteredList.length) {
                                uploadDetails.uploaded = true;
                                if (!uploadDetails.drawerVisible) {
                                    uploadDetails.drawerVisible = false;
                                }
                            } else {
                                uploadDetails.uploaded = false;
                                uploadDetails.drawerVisible = false;
                            }
                        }

                        ctrl = buildLineButtons;
                        break;
                    case 'viewButton':
                        ctrl = buildViewButton;
                        break;
                    default:
                        ctrl = buildField;
                        break;
                }

                return (
                    <React.Fragment key={`line-item-cell-wrapper-key-${index}`}>
                        <GridCell
                            colSpan={col}
                            className={group.class === 'aboutYouFieldsRow' ? 'aboutYouLineRowField' : 'lineRowField'}
                        >
                            {ctrl(field, col, lineItemIndex, group, isLineDisabled, { saveForm, triggered: fns.triggered, screenSize, showSharedEntityDialog, sectionFieldLogic: fns.sectionFieldLogic }, section, parentGroup, parentIndex, grandParentGroup, lineItemSectionName, allSections)}
                        </GridCell>
                    </React.Fragment>
                );
            });

            if (uploadType !== 'single') {
                drawer = (<LineItemDrawer key={`lid=${lineIndex}`} sectionName={lineItemSectionName} isVisible={uploadDetails.drawerVisible} group={group} index={lineIndex} fns={fns} isFormLocked={isLineDisabled} />);
            }

            lineItemCount++;

            if (group.lineSections && group.entities.length < lineItemIndex) {
                const copySection = _.cloneDeep(group.lineSections);

                const identifiers = !group.subWorkSheets ? null : {
                    identifiers: group.subWorkSheets.reduce((prev, item) => {
                        if (item && item.length >= lineItemIndex - 1) {
                            prev.push(item[lineItemIndex - 1] || null);
                        } else {
                            prev.push(null);
                        }

                        return prev;
                    }, []).filter(x => x)
                };

                copySection.forEach(x => x.lineItem = lineItemIndex);

                console.log(copySection);

                group.entities.splice(group.entities.length - deletedPriorYearLineItemsCount, 0, { id: new ObjectId().toString(), sections: copySection, identifiers });
            }
            // if isSummaryRow and is the 2nd entity from bottom
            // else expand the currentEntity
            const expandCurrentLineItem = group.isSummaryRow ? parentGroup.currentEntity === -2 : group.currentEntity === lineItemIndex
                || (group.currentEntity === -1 && showDefaultDenseRowLineItem && paginatedLineItems.length === 1)
                || (group.currentEntity === -2 && visible && showDefaultDenseRowLineItem);
            // is lineSections and expandCurrent Line, then render LineItemSections
            const lineSections = group.lineSections && expandCurrentLineItem ?
                renderLineItemSections(
                    lineItemIndex,
                    group,
                    fns,
                    sectionIndex,
                    groupIndex,
                    lineItemIndex,
                    parentGroup,
                    setIsAddingNewEntity
                ) : null;

            const isHouseholdEmployment = group.class === 'householdEmploymentUpload';

            const renderFields = () => {
                return (
                    <>
                        {
                            isHouseholdEmployment ?
                                <GridCell
                                    colSpan={12}
                                    className={isHouseholdEmployment && 'householdEmploymentUpload'}
                                >
                                    {fields}
                                </GridCell> : fields
                        }
                        {
                            <GridCell colSpan={12} style={hasDenseRow ? { margin: '41px 0 -40px -35px' } : {}}>
                                <div className={'lineItemSectionContainer' + (lineSections && section.title === 'Passthrough Information' ? ' mbZero' : '')} >
                                    {lineSections}
                                </div>
                            </GridCell>
                        }
                        <GridCell colSpan={12}>{drawer}</GridCell>
                    </>
                )
            }

            const renderRow = () => {
                return (
                    <Grid
                        className={!hasDenseRow ? 'lineItemRow' : 'denseLineItemRow'}
                        style={(group.isLastItem || isHouseholdEmployment) ? { marginBottom: '22px' } : null}
                        key={`line-item-row-key-${group.groupId}-${lineIndex}`}
                        id={`line-item-row-id-${group.groupId}-${lineIndex}`}
                    >
                        {
                            !hasDenseRow ?
                                renderFields() :
                                <GridCell colSpan={12} id={`denseLineitem-${currentEntity}`} className="denseRowContainer" style={group.isSummaryRow && currentEntity != lineIndex ? { margin: '0 22px' } : null}>
                                    {group.isSummaryRow && !parentGroup.entities.length ? null : <DenseRowBtn group={group} index={lineItemIndex} setCurrentEntity={setCurrentEntity} parentGroup={parentGroup} setParentCurrentEntity={setParentCurrentEntity} status={expandCurrentLineItem} />}
                                    <Grid className="step">
                                        {renderFields()}
                                    </Grid>
                                    {
                                        expandCurrentLineItem ?
                                            <div
                                                className={
                                                    group.with990DenseRows ? 'denseRow990ExpandConnectorLine' :
                                                        'denseRowExpandConnectorLine'
                                                }
                                            >
                                            </div> : null
                                    }
                                </GridCell>
                        }
                        {
                            !group.isSummaryRow && (group.hasDivider || lineItems.length > 1 || footerButtons.length > 0) ?
                                <GridCell colSpan={12}>
                                    <Divider className={group.class === 'aboutYouFieldsRow' || group.groupType === 'lineSection' ? null : 'lineItemRowDivider'} />
                                </GridCell> : null
                        }
                    </Grid>
                );
            }

            return (
                <React.Fragment key={`line-item-row-key-${group.groupId}-${lineIndex}`}>
                    {
                        group.isSummaryRow && parentGroup.currentEntity !== -2 ?
                            <div
                                className='totalsRowTemporary'
                            >
                                {renderRow()}
                            </div> : renderRow()
                    }
                </React.Fragment>
            );
        });

        const handleHeaderBtnClick = () => {
            if (visible) {
                hideLineitems();
                group.currentEntity = -1;
            }
            else {
                showLineitems();
                group.currentViewField = 100;
                group.currentEntity = -2;
            }
            setCurrentEntity(group.currentEntity);
        }
        const header = !headerLabels || headerLabels.length < 1 || group.isSummaryRow ? null : (
            <Grid
                key={`header-${group.groupId}`}
                className={formHeaderContainer}
                style={group.class === 'aboutYouFieldsRow' ? { marginBottom: '23px' } : null}
            >
                {
                    <GridCell colSpan={12} key={`header-${group.groupId}`} className="denseRowContainer " >
                        {showDefaultDenseRowLineItem && <div className="denseRowBtnContainer" style={{ margin: '10px 0 0' }} >
                            {
                                visible ?
                                    <RemoveSVGIcon className="denseRowBtn" onClick={handleHeaderBtnClick} /> :
                                    <AddSVGIcon className="denseRowBtn" onClick={handleHeaderBtnClick} />
                            }
                        </div>}
                        <Grid className="step">
                            {headerLabels}
                        </Grid>
                    </GridCell>
                }
            </Grid>
        );
        const footer = !footerLabels && !footerButtons || group.isSummaryRow ? null :
            <>
                {footerButtons}
                {footerLabels}
            </>;
        const menuTabs = !group.hasTableManagerTabs ? null : <TableManagerTabs key={`table-manager-tab-${group.groupId}`} group={group}
            isFormLocked={isFormLocked} fns={fns} section={section} setCurrentEntity={setCurrentEntity} setCurrentH2={setCurrentH2}
            setIsAddingNewEntity={setIsAddingNewEntity} addNewLineItem={addNewLineItem} />

        const summaryTotalRow = () => {

            if (!group.hasTableManagerTabs || group.isMasterSummaryHidden) return null;

            const copySection = _.cloneDeep(group.lineSections);
            const filteredGroupEntities = group.entities?.filter(entity => {
                return entity?.sections[0]?.groups[0]?.fields[1]?.default !== "PLEASE DELETE"
            })

            const avgHoursSections = filteredGroupEntities?.map((entity) => {
                return entity.sections?.find(section => section.title === 'Average Hours/Week')
            });

            const aboutIndividualSections = filteredGroupEntities?.map((entity) => {
                return entity.sections?.find(section => section.title === 'About Individual')
            });

            const avgHoursSummaryGroups = avgHoursSections.map((summarySection, index) => {
                if (summarySection.groups?.find(secGroup => secGroup.fields?.find(field => field.isVisible))) {
                    summarySection.groups.forEach(group => group.parentEntityIndex = index);
                    return summarySection.groups;
                }
            });

            const aboutIndividualSummaryGroups = aboutIndividualSections.map((summarySection, index) => {
                summarySection.groups.forEach(group => group.parentEntityIndex = index);
                return summarySection.groups;
            });

            const compesationDetailsSections = filteredGroupEntities?.map(entity =>
                entity.sections
                    ?.find(section => section.title === 'Compensation Details')
            );

            const compesationDetailsSummaryGroups = compesationDetailsSections.map((summarySection, index) => {
                summarySection.groups.forEach(group => group.parentEntityIndex = index);
                return summarySection.groups;
            });

            const benefitsDetailsSections = filteredGroupEntities?.map(entity =>
                entity.sections
                    ?.find(section => section.title === 'Benefits Details')
            );

            const benefitsDetailsSummaryGroups = benefitsDetailsSections.map((summarySection, index) => {
                summarySection.groups.forEach(group => group.parentEntityIndex = index);
                return summarySection.groups;
            });

            const fiscalYearDetailsSections = filteredGroupEntities?.map(entity =>
                entity.sections
                    ?.find(section => section.title === 'Fiscal Year Details')
            );

            const fiscalYearDetailsSummaryGroups = fiscalYearDetailsSections.map((summarySection, index) => {
                summarySection.groups.forEach(group => group.parentEntityIndex = index);
                return summarySection.groups;
            });

            if (!group.summaryList) {
                group.summaryList = { sections: copySection };
            }

            const compensationSummaryFields = compesationDetailsSummaryGroups
                .flatMap(groups => groups.map(group => group.isVisible && group.fields));

            const benefitsDetailsSummaryFields = benefitsDetailsSummaryGroups
                .flatMap(groups => groups.map(group => group.isVisible && group.fields));

            const fiscalYearSummaryFields = fiscalYearDetailsSummaryGroups
                .flatMap(groups => groups.map(group => group.isVisible && group.fields));

            group.summaryList.sections[0].groups = [{
                groupId: 1,
                groupType: 'groupItem',
                groupItems: aboutIndividualSummaryGroups,
                groupItemsLength: aboutIndividualSummaryGroups.length
            }];
            group.summaryList.sections[1].groups = [{
                groupId: 1,
                groupType: 'groupItem',
                groupItems: avgHoursSummaryGroups,
                groupItemsLength: avgHoursSummaryGroups.filter(group => group).length
            }];

            group.summaryList.sections[2].groups = [{
                groupId: 1,
                groupType: 'groupItem',
                groupItems: compesationDetailsSummaryGroups,
                groupItemsLength: compesationDetailsSummaryGroups
                    .flat()
                    .filter(group => group.isVisible).length
            }];

            group.summaryList.sections[3].groups = [{
                groupId: 1,
                groupType: 'groupItem',
                groupItems: benefitsDetailsSummaryGroups,
                groupItemsLength: benefitsDetailsSummaryGroups
                    .flat()
                    .filter(group => group.isVisible).length
            }];

            group.summaryList.sections[4].groups = [{
                groupId: 1,
                groupType: 'groupItem',
                groupItems: fiscalYearDetailsSummaryGroups,
                groupItemsLength: fiscalYearDetailsSummaryGroups
                    .flat()
                    .filter(group => group.isVisible).length
            }];

            const copyGroup = _.cloneDeep(group);
            copyGroup.hasTableManagerTabs = false;
            copyGroup.hasSaveCancelButtons = false;
            copyGroup.currentEntity = -1;
            copyGroup.isSummaryRow = true;
            copyGroup.entities = [group.summaryList];

            const summaryFields = {
                compensationSummaryFields,
                benefitsDetailsSummaryFields,
                fiscalYearSummaryFields
            };
            const fields = updateMasterTotals(copyGroup, summaryFields);
            copyGroup.lineItems = [fields];
            copyGroup.lineItemDetails.lineButtons = [];

            return (group.hasTableManagerTabs && !group.isSummaryRow ?
                <LineItemDetail
                    key={`lid-${section.sectionId}-${-1}`}
                    group={copyGroup}
                    colspan={colspan}
                    screenSize={screenSize}
                    fns={fns}
                    isFormLocked={isFormLocked}
                    section={section}
                    sectionIndex={sectionIndex}
                    groupIndex={groupIndex}
                    entityIndex={-1}
                    showSharedEntityDialog={showSharedEntityDialog}
                    parentGroup={group}
                    parentIndex={parentIndex}
                    isDenseRowNA={isDenseRowNA}
                    setParentCurrentEntity={setCurrentEntity}
                    allSections={allSections}
                    renderLineItemSections={(
                        lineItemCount,
                        group,
                        fns,
                        sectionIndex,
                        groupIndex,
                        lineIndex,
                        parentGroup
                    ) => (
                        <LineItemSection
                            id={'lis-summary-row'}
                            key={'lis-summary-row'}
                            hasLineItemSection={true}
                            entities={group.summaryList}
                            parentFns={fns}
                            parentGroup={group}
                            sectionIndex={sectionIndex}
                            groupItemIndex={0}
                            parentIndex={lineIndex}
                            grandParentGroup={parentGroup}
                            isFormLocked={isFormLocked}
                            allSections={allSections}
                        />
                    )}
                /> : null
            );
        };

        setLineItemCtrls(items);
        setHeaderDetail(header);
        setFooterDetail(footer);
        setMenuTabsDetail(menuTabs);
        setSummaryTotals(summaryTotalRow);
    };

    return (
        <>
            {menuTabsDetail}
            {headerDetail}
            <Grid
                className={
                    !hasDenseRow ?
                        "fieldsContainer" : "expandableFieldsContainer"
                }
                style={
                    footerLabels && footerBtns.length === 0 ?
                        { marginBottom: '20px' } : null
                }
            >
                {lineItemCtrls}
            </Grid>
            {summaryTotals}
            <div className={group.hasTableManagerTabs && group.lineItems.length > itemsPerPage ? 'footerPaginationContainer' : null}>
                {footerDetail}
                {group.hasTableManagerTabs && group.lineItems.length > itemsPerPage ?
                    <Grid>
                        <GridCell colSpan={12}>
                            <ReactPaginate
                                previousLabel='<'
                                nextLabel='>'
                                pageCount={pageCount}
                                marginPagesDisplayed={1}
                                pageRangeDisplayed={2}
                                onPageChange={handlePageClick}
                                containerClassName='pagination'
                                activeClassName='paginationActivePage'
                                breakLabel='...'
                                breakClassName='page-item'
                                breakLinkClassName='page-link'
                                renderOnZeroPageCount={null}
                                forcePage={currentPage}
                            />
                        </GridCell>
                    </Grid>
                    : <></>}
            </div>
        </>
    );
}

export default LineItemDetail;