import { useState, useEffect } from 'react';
import { TransformMapper } from './utils/transformers';
import { validateField } from './utils/validateField';
import { roundOff } from './utils/roundOff';
import { parseToISODate, parseToUSDate } from './utils/formatDate';
import * as Validators from './utils/Validators';

const fnSelf = (x) => x;
const modifyFieldValue = (type) => {
    const fns = {
        money: (x, field) => roundOff(x, field),
        date: (x) => parseToISODate(x), 
        total: (x) => roundOff(x),
    };

    return fns[type] || fnSelf;
};

const modifySaveValue = (type) => {
    const fns = {
        money: (x, field) => roundOff(x, field),
        date: (x) => parseToUSDate(x), 
    };

    return fns[type] || fnSelf;
};

const maskSsn = (value) => {
    return value.length === 9 ? `XXXXX${value.substring(5)}` : value;
};

const maskGeneral = (value) => {
    return ('' + value).slice(0, -4).replace(/./g, 'X') + ('' + value).slice(-4);
};

const mask = (fieldValue) => {
    return fieldValue.type === 'ssn' || fieldValue.type === 'ein' ? maskSsn(fieldValue.default) : maskGeneral(fieldValue.default);
};

export const useField = (props) => {
    let { field, fns, group, index, parentGroup, parentIndex, section, grandParentGroup, allSections } = props;
    
    const [ state, setState ] = useState({
        value: field.default ? field.isMaskOnBlur ? mask(field) : modifyFieldValue(field.type)(field.default, field) : field.type === 'date' ? '' : ' ', 
        error: field.error ? field.error : false, 
        errorMessage: field.errorMessage ? field.errorMessage : ''
    });
    
    useEffect(() => {
        if (group?.groupType !== 'lineSection') {
            if (field.default && field.isSyncFieldValue) {
                setState({
                    value: field.default ? modifyFieldValue(field.type)(field.default, field) : field.type === 'date' ? '' : ' ',
                    error: field.error ? field.error : false,
                    errorMessage: field.errorMessage ? field.errorMessage : ''
                });
            } else if (field.default === state.value && field.validations && !field.error) {
                updateBlurState(field.default ? modifyFieldValue(field.type)(field.default, field) : field.type === 'date' ? '' : ' ', field.error, field.errorMessage);
            } else {
                updateBlurState(field.default ? modifyFieldValue(field.type)(field.default, field) : field.type === 'date' ? '' : field.type === 'multiSelectDropdown' ? [] : ' ', field.error, field.errorMessage);
            }
        }
    }, [section, field.isGreaterThanW2Amount]);
      
    const handleBlur = (e) => {
        // Validate field
        setUpValidations();
        const toValidate = field.type === 'select' ? state.value : e.currentTarget.value;
        const errorMessage = field.validations ? validateField(toValidate, field) : null;
        const blurValue = state.value === '' ? ' ' : state.value;
        const valueChanged = !field.default ? false : (field.type === 'money' || field.type === 'total') && (state.value.trim() === '') ?  false : field.default !== state.value;
        
        if (field.isSharedEntityValue && valueChanged) fns.showSharedEntityDialog();
        
        if (!field.isGreaterThanW2Amount)  updateBlurState(blurValue, errorMessage ? true : false, errorMessage);
        else updateBlurState(blurValue, field.error, field.errorMessage);
        
        const nextId = e.relatedTarget && e.relatedTarget.id ? e.relatedTarget.id : null; // Used to fix tabbing

        if (field.logicFunction && !parentGroup?.isSummaryRow) {
            field.logicFunction.forEach((logic) => {
                logic.isDifferentGroup && fns.triggered ? fns.triggered(logic.trigger, field.default, null) :
                fns.sectionFieldLogic && fns.sectionFieldLogic(logic?.trigger, {group, field, index, logic, parentGroup, parentIndex, section, fns, allSections});
            });
        }

        //for syncing summary view changes to individual view 
        if (parentGroup?.isSummaryRow && grandParentGroup) {
            grandParentGroup.entities[index]?.sections
                ?.find(entitySection => entitySection.title === section.title)
                ?.groups.forEach((entityGroup) => {
                    entityGroup.fields.forEach((entityField) => {
                        if (entityField.name === field.name) {
                            entityField.default = field.default;
                            if (entityField.logicFunction) {
                                entityField.logicFunction.forEach((logic) => {
                                    logic.isDifferentGroup ? fns.triggered(logic.trigger, entityField.default, grandParentGroup.entities[index]?.sections) :
                                        fns.sectionFieldLogic(logic?.trigger, {group, field: entityField, index, logic, parentGroup: grandParentGroup, parentIndex: index, section, fns, allSections});
                                });
                            }
                            if (entityField.validations) {
                                const errorMessage = entityField.validations ? validateField(toValidate, field) : null;
                                entityField.error = errorMessage ? true : false;
                                entityField.errorMessage = errorMessage;
                            }
                        }
                    })
                });
        }

        if (parentGroup?.bonds) {
            parentGroup.bonds[parentIndex].sections
                ?.find(bondSection => bondSection.title === section.title)
                ?.groups.forEach(bondGroup => {
                    bondGroup.fields.forEach(bondField => {
                        if (bondField.name === field.name) {
                            bondField.default = field.default;
                            if (bondField.logicFunction) {
                                bondField.logicFunction.forEach(logic => {
                                    logic.isDifferentGroup ? fns.triggered(
                                        logic.trigger,
                                        bondField.default,
                                        parentGroup.bonds[parentIndex]?.sections
                                    ) : fns.sectionFieldLogic(
                                        logic?.trigger,
                                        {
                                            group,
                                            field: bondField,
                                            index,
                                            logic,
                                            parentGroup,
                                            parentIndex,
                                            section,
                                            fns
                                        }
                                    )
                                })
                            }

                             if (bondField.validations) {
                                const errorMessage = bondField.validations ? validateField(toValidate, field) : null;
                                bondField.error = errorMessage ? true : false;
                                bondField.errorMessage = errorMessage;
                            }
                        }
                    })
                });
        }

        fns.saveForm(field, nextId);
    };

    const handleChange = (e) => {
        let value = e.currentTarget.value;

        // If we have a transformer defined for the field type, use it to transform the current value of the field
        if (typeof TransformMapper[field.type] !== 'undefined') {
            value = TransformMapper[field.type](state.value, e.currentTarget.value);
        }

        if (value !== state.value) {
            // Update value and reset error state
            updateState(value, false, '');
        }
    };

    const handleFocus = (e) => {
        if (e.target.value === ' ') {
            updateState('', false, '');
        } else {
            setState({
                value: field.default, 
                error: field.error ? field.error : false, 
                message: field.errorMessage ? field.errorMessage : ''
            });
        }
    };

    const setUpValidations = () => {
        // for DateField, if field has maxDate or minDate, set validations for it.
        if (field?.maxDate) {
            field.validations = { ...field.validations, ...Validators.maxDate(field.maxDate, field?.message) };
        }
        if (field?.minDate) {
            field.validations = { ...field.validations,...Validators.minDate(field.minDate, field.message) };
        }
    };

    const handleNumberValueChange = (value) => {
        const changeValue = value === 0 || value === '0' ? ' ' : value;
        const errorMessage = field.validations ? validateField(changeValue, field) : null;
        updateState(changeValue, errorMessage ? true : false, errorMessage);
    };

    const handleEmptyValueChange = (value) => {
        const changeValue = value === '' ? ' ' : value;
        const errorMessage = field.validations ? validateField(changeValue, field) : null;
        if (!value.includes('X')) {
            updateState(changeValue, errorMessage ? true : false, errorMessage);
        }  
    };

    const updateState = (value, error, errorMessage) => {
        setState({ value, error, errorMessage });
    };

    const updateBlurState = (value, error, errorMessage) => {
        const stateValue = modifyFieldValue(field.type)(value, field);
        field.default = modifySaveValue(field.type)(value, field);
        field.error = error;
        field.errorMessage = errorMessage;
        if (field.type === 'ssn' || (field.type === 'ein' && field.isMaskOnBlur)) {
            const valueToMask = field.default?.length === 9 ? field.default : value;
            if (valueToMask.length === 9) {
                setState({
                    value: maskSsn(valueToMask), 
                    error: error, 
                    message: errorMessage,
                });
            } else {
                setState({ value, error, errorMessage });
            }
        } else if (field.isMaskOnBlur && value?.trim().length && !error ) {
            setState({
                value: maskGeneral(value), 
                error: error, 
                message: errorMessage,
            });
        } else {
            setState({ value: stateValue, error, errorMessage });
        }

    };

    return { state, handleBlur, handleChange, updateState, handleFocus, handleNumberValueChange, handleEmptyValueChange, updateBlurState};
};