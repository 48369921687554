// common modules
import React, { useState } from 'react';
// custom modules
import * as NAV from '@utilities/constants/navigation';
import * as IMG from '@utilities/constants/images';
import Breadcrumb from '@components/Breadcrumb.js';
import usePageFramework from '@utilities/hooks/usePageFramework';
import api from '@utilities/claApi';
import { Button, TextIconSpacing } from 'react-md';

const targetYear = 2023;
const PriorYearDataPull = () => {
    const [results, setResults] = useState(null);
    const {
        dispatch,
        ACTION,
    } = usePageFramework();
    const navItems = [
        { to: NAV.ADMIN_DASHBOARD, label: 'Admin Dashboard' },
        { to: NAV.PRIOR_YEAR_DATA_PULL, label: 'Prior Year Data Pull', current: true }
    ];
    const getOrganizerSet = async (query, limit, offset) => {
        return api.get(`/organizers?filterStaticYear=${targetYear}&filter=migratedDocumentsToBlob.spToBlobResult ine&limit=${limit}&offset=${offset}`).then((response) => {
            return response.data;
        });
    };

    // Get a list of all organizers
    const getAllOrganizers = async (query) => {
        const organizers = [];
        const limit = 250;
        let offset = 0;
        await getOrganizerSet(query, limit, offset).then(async (data) => {
            const { results, total } = data;
            organizers.push(...results);
            while (offset < total) {
                offset += limit;
                const nextPageResults = await getOrganizerSet(query, limit, offset);
                organizers.push(...nextPageResults.results);
            }
        });
        return organizers;
    };
    const pullPriorYearData = () => {

        let updateMessage = '';
        const queryParameters = [];

        dispatch(ACTION.setProgressText('pulling prior year data...'));
        dispatch(ACTION.setProgressVisible(true));
        getAllOrganizers(queryParameters.join('&')).then(async (organizers) => {
            const foundMessage = `Found ${organizers.length} organizers to pull prior data\n\n`;
            console.log(foundMessage.trim());
            updateMessage = updateMessage.concat(foundMessage);
            for (let index = 0; index < organizers.length; index++) {
                const { id } = organizers[index];
                const body = { returnType: "I" };
                try {
                    await api.post(`organizers/${id}/pull`, body);

                    const successStatusMessage = `Organizer (${id}): Successful data pull\n`;

                    console.log(successStatusMessage);
                    updateMessage = updateMessage.concat(successStatusMessage);
                }
                catch (e) {
                    const failStatusMessage = `Organizer (${id}): Failed data pull: ${e}\n`;
                    console.log(failStatusMessage);
                    updateMessage = updateMessage.concat(failStatusMessage);
                }
            }
        }).finally(() => {
            dispatch(ACTION.setProgressVisible(false));
            dispatch(ACTION.setShowCustomDialog(true));
            dispatch(ACTION.setCustomDialogTitle('Finished data pull'));
            dispatch(ACTION.setCustomDialogMsg('The results will be displayed in-page'));
            setResults(updateMessage);
        });
    }
    const copyResultsToClipboard = (evt) => {
        evt.preventDefault();
        navigator.clipboard.writeText(results);
    };
    return (
        <div className='pracDashboardSize'>
            <Breadcrumb items={navItems} />
            <h1>{targetYear} Data</h1>
            <p className='page-instructions'>
                Click Pull PriorYear Data button to start pulling {targetYear} data. The results will be displayed in the page below and the log can be copied to your clipboard.

            </p>
            <p style={{ textAlign: 'left', marginLeft: '10px' }}> Target Year: {targetYear}</p>
            <Button themeType='outline' onClick={pullPriorYearData}>
                <TextIconSpacing icon={<img height={50} alt="Pull priordata button" src={IMG.NOTES_ICON} />}>
                Pull PriorYear Data
                </TextIconSpacing>
            </Button>
            <br />
            <Button themeType='outline' disabled={!results ? true : false} onClick={copyResultsToClipboard}>
                <TextIconSpacing icon={<img height={50} alt="Copy results button" src={IMG.DOWNLOAD_ALL_ICON} />}>
                    Copy Results
                </TextIconSpacing>
            </Button>
            {results && <p className='page-result-title'><b>Results:</b></p>}
            {results && <p className='page-result-text'>{results}</p>}
        </div>
    );
};
export default PriorYearDataPull;