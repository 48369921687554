import React, { useState, useEffect } from 'react';
import moment from 'moment';
import nextId from 'react-id-generator';
// common modules
import {
	Grid,
	GridCell,
	FileInput,
	Form,
	FontIcon,
	DropdownMenu,
	MenuItem,
	TextIconSpacing,
	Divider,
	LinearProgress,
} from 'react-md';
import { MoreVertSVGIcon } from '@react-md/material-icons';

// custom modules
import usePageFramework from '@utilities/hooks/usePageFramework';
import useWindowDimensions from '@utilities/hooks/useWindowDimensions';
import OrganizerViewUploadDialog from '@components/OrganizerViewUploadDialog';

import { currentYear } from '../utilities/hooks/usePageFramework';
import { ACCEPTED_FILE_TYPES_1040 } from '@utilities/constants/document';
function OrganizerUploadsDrawer(props) {
	const { selectState, REDUX, onFileRemoval } = usePageFramework();
	const isProgressVisible = selectState(REDUX.UPLOAD_PROGRESS_VISIBLE);
	const { uploadList, onUpload, useType } = props;
	const { width } = useWindowDimensions();
	const dashKey = 'dashboard';
	const isLocked = selectState(REDUX.LOCK_FORMS) || false;

	const [isFileUploadDisabled, setIsFileUploadDisabled] = useState(false);
	const currentReportYear = selectState(REDUX.YEAR);

	useEffect(() => {

		if (currentReportYear < currentYear) {
			setIsFileUploadDisabled(true)
		} else {
			setIsFileUploadDisabled(false)
		}

	}, [currentReportYear])

	const sortedUploads = [...uploadList].sort((a,b) => new Date(b.createdOn) - new Date(a.createdOn));

	const onChange = (event) => onUpload(event, dashKey);

	const getScreenCol = (col) => {
		const colTab = col === 10 ? 6 : col === 6 ? 3 : col === 3 ? 2 : col === 2 ? 1 : col;
		return width < 1025 ? colTab : col;
	};


	const uploadedFileList = sortedUploads.map((file, idx) =>
		<React.Fragment key={`organizer-upload-${file.id}`}>
			<Grid key={`organizer-upload-row-${file.id}`} id={`${dashKey}-${nextId()}`} className='uploadDrawerRow'>
				<GridCell className='fileName' colSpan={getScreenCol(6)}>
					<OrganizerViewUploadDialog
						key={`organizer-view-upload-dialog-${file.id}`}
						id={nextId()}
						className="fileViewDetails"
						blobFile={file}
						fileName={file.name}
					/>
				</GridCell>
				<GridCell className='fileName' colSpan={getScreenCol(3)}>
					{file.uploaded_by || ''}
				</GridCell>
				<GridCell className='fileName' colSpan={getScreenCol(2)}>
					{moment(new Date(file.createdOn)).format('MMM DD, YYYY') || 'None'}
				</GridCell>
				{useType && useType === 'readOnly' ? <></> :<GridCell colSpan={1} className="fileList">
					<DropdownMenu
                        data-testid={'upload-drawer-menu'}
						disabled={isLocked}
						key={`organizer-upload-dpmenu-${file.id}`}
						id={`organizer-upload-dpmenu-${file.id}`}
						buttonType="icon"
						buttonChildren={<MoreVertSVGIcon />}
					>
						<MenuItem
							key={`upload-remove-item-${file.upload_id}`}
							onClick={() => onFileRemoval(null, file)}>
							{'Remove File'}
						</MenuItem >
					</DropdownMenu>
				</GridCell>}
			</Grid>
			<Grid>
				<GridCell colSpan={getScreenCol(12)}>
					{isProgressVisible && idx === uploadList.length - 1 ?
						<LinearProgress
							id={`upload-linear-progress-${file.id}`}
							barStyle={{ backgroundColor: '#76d2d3' }}
							className='uploadLinearProgress'
							min={0}
							max={100}
							value={null}
						/> : <Divider />}
				</GridCell>
			</Grid>
		</React.Fragment>
	);

	return (
		<div className='uploadDrawerContainer'>
			<Grid className='uploadDrawerHeaderContainer'>
				<GridCell colSpan={getScreenCol(12)}><Divider /></GridCell>
				<GridCell colSpan={getScreenCol(6)} className="headerLabelsLeft">
					File Name
				</GridCell>
				<GridCell colSpan={getScreenCol(3)} className="uploadHeaderheaderLabelsLeft">
					Uploaded By
				</GridCell>
				<GridCell colSpan={getScreenCol(2)} className="uploadHeaderheaderLabelsLeft">
					Date Uploaded
				</GridCell>
				<GridCell colSpan={1}></GridCell>
				<GridCell colSpan={getScreenCol(12)}><Divider /></GridCell>
			</Grid>
			<div className='uploadedListContainer'>{uploadedFileList}</div>
			{ useType && useType === 'readOnly' ? <></> : <Grid>
				<GridCell colSpan={6}>
					<Form>
						<FileInput
							disabled={isFileUploadDisabled}
							theme='clear'
							themeType='flat'
							id="dashboard-upload-button"
							onChange={onChange}
							buttonType={'text'}
							disableIconSpacing={true}
							multiple={true}
							icon={<TextIconSpacing
								icon={
									<FontIcon className="addIcon">
										add
									</FontIcon>
								}>
							</TextIconSpacing>}
							className={'addMoreFile'}
							accept={ACCEPTED_FILE_TYPES_1040}
						>
							Upload More Files
						</FileInput>
					</Form>
				</GridCell>
			</Grid>}
		</div>
	);
}

export default OrganizerUploadsDrawer;