import {
	TextIconSpacing,
	FontIcon,
} from '@react-md/icon';
import { useIntercom } from 'react-use-intercom';

import './topBar.css';
import { authenticated } from '@utilities/authentication.js';
import { useMsal } from '@azure/msal-react';
import { injectLocalDevelopmentTokens } from '@utilities/authentication.js';
import { scopes } from '@utilities/claApi.js';

function TopBar() {
	const { instance } = useMsal();
	const { shutdown } = useIntercom();
	const isAuthenticated = authenticated();

	const handleSignin = (e) => {
		if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
			// inject development tokens into cache then refresh entire page
			injectLocalDevelopmentTokens();
			window.location.reload();
		} else {
			// redirect to MSAL login
			instance.loginRedirect({
				scopes: scopes,
			});
		}
	}

	const handleSignout = (e) => {
		// GROWTH: Handle for automatic sign outs
		shutdown();

		instance.logoutRedirect({
			postLogoutRedirectUri: 'https://www.claconnect.com/'
		});
	}

	return (
		<div className="notification-bar">
			<div className="appBarHelpText">
				{/* TODO: Temporarily hiding until functionality is built */}
				<ul>
					{/* <li>
                        <TextIconSpacing 
                            icon={
                                <FontIcon className="appBarFontIconColor">
                                    comment
                                </FontIcon>
                            }
                        >
                                Ask CLA
                        </TextIconSpacing>
                    </li> */}
					<li onClick={isAuthenticated ? handleSignout : handleSignin}>
						<TextIconSpacing
							icon={
								<FontIcon className="appBarFontIconColor">
									{isAuthenticated ? 'logout' : 'login'}
								</FontIcon>
							}
						>
							{isAuthenticated ? 'Sign Out' : 'Sign In'}
						</TextIconSpacing>
					</li>
				</ul>
			</div>
		</div>
	);
}

export default TopBar;