import getFormTemplateForYear from '@utilities/helpers/formTemplate';
import vehicle2021 from './2021';
import vehicle2022 from './2022';
import vehicle2023 from './2023';

const getVehicleFormSection = (props) => {
    const { isFormExpandable, isLineSection, year, barcodeName } = props;
    
    const formTemplatesByYear = {
        2021: vehicle2021(isFormExpandable, isLineSection, year),
        2022: vehicle2022(isFormExpandable, isLineSection, year, barcodeName),
        2023: vehicle2023(isFormExpandable, isLineSection, year, barcodeName),
    };

    const formSections = getFormTemplateForYear(year.current, formTemplatesByYear);
    return formSections;
};

export default getVehicleFormSection;