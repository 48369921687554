// common modules
import React, { useState, useEffect, useRef } from 'react';
import _ from 'lodash';

// custom modules
import '@views/PractitionerDashboard.css';
import OrganizerList from '@components/OrganizerList.js';
import MyDashboards from '@components/MyDashboards.js';
import api from '@utilities/claApi';
import usePageFramework, { currentYear } from '@utilities/hooks/usePageFramework';
import * as STRING from '@utilities/constants/strings';
import { MAX_PREFETCH_LIMIT, DEFAULT_FILTER_QUERY, DEFAULT_SORT_QUERY, OPERATORS_MAP, DEFAULT_PAGE_LIMIT, SORT_TO_DATA_MAP, FILTER_TO_DATA_MAP } from '@utilities/constants/practitionerDashboard';
import useServerSideMUI from '@utilities/hooks/useServerSideMUI';

function PractitionerDashboard() {
  const { dispatch, ACTION, selectState, REDUX } = usePageFramework();
  const [isLoaded, setIsLoaded] = useState(false);
  const [paginatedLoad, setPaginatedLoad] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [prefetchedData, setPrefetchedData] = useState([]);
  const [data, setData] = useState([]);
  const [totalResults, setTotalResults] = useState(0);
  const previousOrganizerQuery = useRef(null);
  const hasLockPermission = selectState(REDUX.LOCKING_PERMISSON);
  const [refreshKeyStats, setRefreshKeyStats] = useState(false);

  const payload = {
    defaultFilter: DEFAULT_FILTER_QUERY,
    defaultSort: DEFAULT_SORT_QUERY,
    defaultPageSize: DEFAULT_PAGE_LIMIT,
    filterDataMap: FILTER_TO_DATA_MAP,
    sortDataMap: SORT_TO_DATA_MAP,
    operatorsMap: OPERATORS_MAP,
    defaultPrefetchSize: MAX_PREFETCH_LIMIT,
  };
  const {
    handleFilterModelChange,
    handleSortModelChange,
    handlePageModelChange,
    handlePageSizeChange,
    handleExternalFilterChange,
    currentPageNumber,
    filterQueryParameters,
    filterMethodParameters,
    sortQueryParameters,
    pageSize,
    prefetchSize,
    externalFilterParameters1,
    externalFilterParameters2,
    currentFilterModel,
    currentSortModel,
  } = useServerSideMUI(payload);

  const buildOrganizerQuery = (limit, offset, filtering, sorting, filterMethod, filterParameters2, filterParameters3) => {
    const staticYearFilter = `&filterStaticYear=${currentYear}`;
    const filterQuery = filtering?.length ? filtering.map(x => `&filter=${x}`).join('') : '';
    const externalFilterQuery1 = filterParameters2?.length ? filterParameters2.map(x => `&filter2=${x}`).join('') : '';
    const externalFilterQuery2 = filterParameters3?.length ? filterParameters3.map(x => `&filter3=${x}`).join('') : '';
    const filterMethodQuery = filterMethod ? `&filterMethod=${filterMethod}` : '';
    const filterMethodQuery2 = `&filterMethod3=or`;
    const sortQuery = sorting && Object.entries(sorting).length ? Object.entries(sorting).map(([param, query]) => `&${param}=${query}`).join('') : '';
    return `${staticYearFilter}&limit=${limit}&offset=${offset}${filterMethodQuery}${filterQuery}${externalFilterQuery1}${externalFilterQuery2}${filterMethodQuery2}${sortQuery}`;
  };

  const refreshData = () => {
    setRefreshKeyStats(prev => !prev);
  }

  useEffect(() => {
    // On page load, set pracDash data
    dispatch(ACTION.setToolbarTitle(STRING.EXCHANGE_MANAGER_1040));
    // eslint-disable-next-line
  }, [dispatch, ACTION]);

  useEffect(() => {
    setPaginatedLoad(true);

    const totalOffset = currentPageNumber * pageSize;
    const prefetchOffset = Math.floor(totalOffset / prefetchSize) * prefetchSize;
    const prefetchPageOffset = totalOffset % prefetchSize

    const organizerQuery = buildOrganizerQuery(prefetchSize, prefetchOffset, filterQueryParameters, sortQueryParameters, filterMethodParameters, externalFilterParameters1, externalFilterParameters2);

   
    // If the resulting queries are the same, grab organizer data from the prefetched set
    if (previousOrganizerQuery.current !== null && organizerQuery === previousOrganizerQuery.current) {
      const resultSlice = prefetchedData.slice(prefetchPageOffset, prefetchPageOffset + pageSize);
      setData(resultSlice);
      setPaginatedLoad(false);
      return;
    }

    previousOrganizerQuery.current = organizerQuery;

    api.get(`/organizers?${organizerQuery}`).then((response) => {
      return response.data;
    }).then((response) => {
      const { results, total } = response;

      const resultSlice = results.slice(prefetchPageOffset, prefetchPageOffset + pageSize);
      
      setPrefetchedData(results);
      setData(resultSlice);
      setTotalResults(total);
    }).catch((error) => {
      console.error(error);
      setHasError(true);
    }).finally(() => {
      setIsLoaded(true);
      setPaginatedLoad(false);
    });
    // eslint-disable-next-line
  }, [currentPageNumber, filterQueryParameters, sortQueryParameters, filterMethodParameters, pageSize, prefetchSize, externalFilterParameters1, externalFilterParameters2]);

  const syncParentData = (organizers) => {
    setData(organizers);

    const totalOffset = currentPageNumber * pageSize;
    const prefetchPageOffset = totalOffset % prefetchSize

    const prefetch = _.cloneDeep(prefetchedData);
    prefetch.splice(prefetchPageOffset, prefetchPageOffset + pageSize, ...organizers);
    setPrefetchedData(prefetch);
  };

  return (
    <div className="pracDashboardSize">
      <MyDashboards handleViewChange={handleExternalFilterChange} refreshTrigger={refreshKeyStats}/>
      {
        isLoaded
          ? hasError
            ? <>An error has occurred while loading the exchange manager. Please try again later.</>
            : <OrganizerList
                organizers={data}
                pageSize={pageSize}
                currentPageNumber={currentPageNumber}
                totalResults={totalResults}
                paginatedLoad={paginatedLoad}
                handlePageModelChange={handlePageModelChange}
                handleFilterModelChange={handleFilterModelChange}
                handleSortModelChange={handleSortModelChange}
                handlePageSizeChange={handlePageSizeChange}
                syncParentData={syncParentData}
                hasLockPermission={hasLockPermission || false}
                currentFilterModel={currentFilterModel}
                currentSortModel={currentSortModel}
                onDownload={refreshData}
            />
          : <>Loading...</>
      }
    </div>
  );
}

export default PractitionerDashboard;