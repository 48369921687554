import { useState, useEffect } from 'react';
import EntityRenderer from '@components/entityRenderer/index';
import usePageFramework from '@utilities/hooks/usePageFramework';
import { triggeredEvent, sectionFieldLogic } from './stateTaxEntityLogicTrigger';
import getYear from '@utilities/helpers/getYear';
import { getStateTaxSpreadSections } from '@utilities/helpers/getStateTaxSections';
import getFormTemplateForYear from '@utilities/helpers/formTemplate';
import stateTax2021 from './2021';
import stateTax2022 from './2022';
import stateTax2023 from './2023';

function StateTaxEntity(props) {
	const { isExportForm } = props;
	const { REDUX, selectState } = usePageFramework();
	const [sectionState, setSectionState] = useState('');
	const formState = selectState(REDUX.STATE_TAX);
	const year = getYear(selectState);

	useEffect(() => {

		const formTemplatesByYear = {
			2021: stateTax2021(year)(formState),
			2022: stateTax2022(year)(formState),
			2023: stateTax2023(year)(formState)
		};

		const formSections = getFormTemplateForYear(year.current, formTemplatesByYear);
        setSectionState(formSections);
    //eslint-disable-next-line    
	}, [formState]);

	const axcessIdentifiers = [
		{ section: 'State Link Worksheet', id: 'Government' },
	];

	const spreadIdentifiers = getStateTaxSpreadSections()

	const entityFilter = {
		type: 'any',
		filters: [
			{ keys: ['G20632.66', 'G20632.49', 'G20632.50', 'G20632.51', 'G20632.52'], filterType: 'inEntityNotZero' },
		],
	};

	return (
		!sectionState
			? <></>
			: <EntityRenderer sections={sectionState} key="StateTaxEntity" formName={REDUX.STATE_TAX} isExportForm={isExportForm}
				identifiers={axcessIdentifiers} spreadIdentifiers={spreadIdentifiers} fromGridDetail={true} entityFilter={entityFilter} triggeredEvent={triggeredEvent} sectionFieldLogic={sectionFieldLogic} />
	);
}

export default StateTaxEntity;