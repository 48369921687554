import { useState, useEffect } from 'react';

import usePageFramework from '@utilities/hooks/usePageFramework';
import FormRendererHelper from '@components/formRenderer';
import * as NAV from '@utilities/constants/navigation';
import { triggeredEvent, sectionFieldLogic } from './mortgageInterestLogicTrigger';
import getYear from '@utilities/helpers/getYear';
import getFormTemplateForYear from '@utilities/helpers/formTemplate';
import mortgage2021 from './2021';
import mortgage2022 from './2022';
import mortgage2023 from './2023';

function MortgageInvestmentInterest(props) {
    const { isExportForm } = props;
    const { updateCard, REDUX, selectState } = usePageFramework(NAV.MORTGAGE_INVESTMENT_INTEREST);
    const [sectionState, setSectionState] = useState('');
    const year = getYear(selectState);

    useEffect(() => {
        if (!isExportForm) updateCard();

        const formTemplatesByYear = {
            2021: mortgage2021(year),
            2022: mortgage2022(year),
            2023: mortgage2023(year),
        };

        const formSections = getFormTemplateForYear(year.current, formTemplatesByYear);

        setSectionState(formSections);
    //eslint-disable-next-line
    }, []);

    return (
        <FormRendererHelper sections={sectionState} key="mortgageInvestmentInterestForm" formName={REDUX.MORTGAGE_INVESTMENT} identifiers={[ {section: 'Deductions', id: 'Sch A' } ]} triggeredEvent={triggeredEvent} sectionFieldLogic={sectionFieldLogic}
            isExportForm={isExportForm}
        />
    );
}

export default MortgageInvestmentInterest;
