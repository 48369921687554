import { GridCell } from 'react-md';
import moneyFormatter, { parseMoneyToNumber } from '../../../utils/moneyFormatter';
import { getYear } from '../../../utils/getYear';

const LineItemFooter = ({ item, group, width, index  }) => {
    
    const footerStyles = (group, item) => {
        const subSecWithYear = group.hasSubSectionTotalDisplay && !item.year;
        const labelsClass = !group.hasSubSectionTotalDisplay ? 
            'footerLabelMoney' : 
            subSecWithYear && item.name !== 'salePrice' ? 'businessEquipmentFooter': 
                subSecWithYear && item.name === 'salePrice' ? 'salePriceFooter' :
                    'footerSubSectionLabelMoney'; 
        
        if ((item.type === 'total' || item.type === 'totalMoney') && !item.align) {
            return labelsClass;
        } else if (!group.hasSubSectionTotalDisplay && item.align === 'left') {
            return 'footerLabelMoneyLeft';
        } else if (group.hasSubSectionTotalDisplay && item.align === 'left') {
            return 'footerSubSectionLabelMoneyLeft';
        }   else if ( item.type === 'label') {
            return 'footerLabelText';
        } else return '';
    };

    let children = null;
    const currentDate = new Date();
    let year = currentDate.getFullYear();
    // year = item.year === 'prior' ? year - 1 : item.year === 'current' ? year : '';
    year = getYear(item.year);
    // const totalAmountStyle = !year ? {width: '30px' } : {}; // This is 1040 specific footer styling

    switch (item.type) {
    case 'totalMoney':
        children = group.currencySymbol ? item.label : moneyFormatter(parseMoneyToNumber(item.label));
        break;
    default:
        // children = (<div className={item.class || null}>{item.label}</div>);
        children = item.label;
        break;
    }

    return item.type !== 'buttons' && (
        <GridCell 
            key={`footer-grid-${group.groupId}-cell-${index}`} 
            colSpan={width < 1025 && item.colTablet ? item.colTablet : item.col} 
            className={footerStyles(group, item)}
        >
            {
                (item.type === 'totalMoney' || item.type === 'total') && item.label
                    ? 
                    <>
                        <span>{year} Total: </span> 
                        <span 
                            // style={totalAmountStyle}
                        >
                            {children}
                        </span>
                    </> : ''
            }
        </GridCell>
    );
    
};

export default LineItemFooter;