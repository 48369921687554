import { Button, DialogFooter } from 'react-md';
import usePageFramework from '@utilities/hooks/usePageFramework';
import React from 'react';

function NotesFooter(props) {
    const {characterCount, isNotesChangedAndUnsaved, handleOnCancel,
        saveFunction, isNewNote
    } = props;
    const { REDUX,selectState } = usePageFramework();
    const isFormLocked = selectState(REDUX.LOCK_FORMS);
    
    return (
        <DialogFooter className='notesFooter'>
            <div className='notesFooterText'> Saving this note will create a new version.  { 998 - characterCount} characters remaining.</div>
            <div>
                <Button className='formNotesCancel' id="notes-cancel" onClick={handleOnCancel} disabled={isFormLocked}>
                    CANCEL
                </Button> 
                <Button className={isNotesChangedAndUnsaved?'formNotesSaveActive':'formNotesSaveInActive' } id="notes-save" onClick={saveFunction} disabled={!isNotesChangedAndUnsaved || isFormLocked}>
                    {isNewNote ? 'SAVE' : 'UPDATE'}
                </Button>
            </div>
        </DialogFooter>
    );
}

export default NotesFooter;