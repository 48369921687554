import { useEffect, useState, useRef } from 'react';

import DashboardSubMenu from '@components/header/dashboardSubMenu';
import DashboardGrid from '@components/dashboard/dashboardGrid';
import { setToolbarTitle } from '@utilities/actions/global';
import usePageFramework, { currentYear } from '@utilities/hooks/usePageFramework';
import * as STRING from '@utilities/constants/strings';
import OrganizerUploads from '@components/OrganizerUploads';
import EntryExperienceModal from '@components/entryExperience/entryModal';
import api from '@utilities/claApi';
import { CloseSVGIcon } from '@react-md/material-icons';
import {
	Grid,
	GridCell,
	Divider,
	Button,
} from 'react-md';

function Dashboard() {
	const { dispatch, updateCard, selectState, REDUX, history, NAV } = usePageFramework();
	const dashboard = selectState(REDUX.DASHBOARD);
	const isFutureYear = selectState(REDUX.IS_FUTURE_YEAR);
	const categories = dashboard ? dashboard.filter((category) => category.title !== 'Opportunity Services') : [];

	// use to checked if user already login
	const hasSkipEntryStep = selectState(REDUX.HAS_SKIP_ENTRY_STEP);
	const taxYear = selectState(REDUX.YEAR);
	const isAdmin = selectState(REDUX.IS_ADMIN);
	const isPractitioner = selectState(REDUX.IS_PRACTITIONER);
	const organizerId = selectState(REDUX.ORGANIZER_ID);
	const spouseEmail = selectState(REDUX.SPOUSE_EMAIL);


	const [clientEmail, setClientEmail] = useState('');
	const [displayUploadInstructionBanner, setDisplayUploadInstructionBanner] = useState(true);

	useEffect(() => {
		if (!organizerId) {
			setClientEmail('');
			return;
		}

		api.get(`/organizers/${organizerId}/users`)?.then((response) => {
			const nonClaEmails = response.data.results.filter((result) => result.email.split("@")[1] !== 'claconnect.com');
			if (nonClaEmails.length > 0) {
				setClientEmail(nonClaEmails[0].email);
			}

			return response.data.results;
		}).catch((err) => {
			setClientEmail('');
		});

	}, [organizerId]);

	useEffect(() => {
		dispatch(setToolbarTitle(STRING.DASHBOARD));
		updateCard();
	});

	useEffect(() => {
		// pop entry Experience modal if Client user first time login
		(isFutureYear || currentYear === taxYear) && !isPractitioner && !isAdmin && !hasSkipEntryStep && history.push(NAV.ENTRY_INTERVIEW);
		// eslint-disable-next-line
	}, []);

	const bannerRef = useRef(null);
	const closeUploadInstructionBanner = () => {
		if (bannerRef.current) {
			// Trigger collapsing animation by setting max-height and opacity with a delay
			bannerRef.current.style.maxHeight = '0';
			bannerRef.current.style.opacity = '0';
			setTimeout(() => setDisplayUploadInstructionBanner(false), 500);
		  }
	};

	useEffect(() => {
		if (!displayUploadInstructionBanner && bannerRef.current) {
			// Trigger collapsing animation by setting max-height
			bannerRef.current.style.maxHeight = '0';
			bannerRef.current.style.opacity = '0';
		}
	}, [displayUploadInstructionBanner]);

	const renderUploadInstructionBanner = () => {
		const bannerStyles = {
			display: 'flex',
			justifyContent: 'left',
			alignItems: 'center',
			height: '118px',
			width: '100%',
			backgroundColor: '#24787A',
			paddingLeft: '90px',
			paddingRight: '150px',
			textAlign: 'left',
			position: 'relative',
			transition: 'max-height 0.5s ease-out, opacity 0.5s ease-out',
			overflow: 'hidden',
			maxHeight: displayUploadInstructionBanner ? '118px' : '0',
			opacity: displayUploadInstructionBanner ? '1' : '0',
		};

		const textStyle = {
			color: '#FFFFFF',
			maxWidth: '910px',
			height: '54px',
			fontSize: '16px',
			fontWeight: '600',
			lineHeight: '22px',
		};

		const buttonStyle = {
			position: 'absolute',
			right: '30px',
			top: '5px',
			color: '#FFFFFF',
			fontWeight: '50',
			fontSize: '16px',
		};

		return (
			<div ref={bannerRef} style={bannerStyles}>
				<Button
					id="icon-button-close"
					data-testid="icon-button-close"
					buttonType="icon"
					theme="clear"
					aria-label="close"
					className="entryExperienceModalCloseBtn"
					onClick={closeUploadInstructionBanner}
					style={buttonStyle}
				>
					<CloseSVGIcon className="closeIcon" />
				</Button>
				<div style={textStyle}>
					Upload your documents and complete required cards. Don't forget to click "Submit to CLA"
					above when you are finished. If additional information is needed, your CLA team will contact
					you.
				</div>
			</div>
		);
	};

	return (
		<>
			{displayUploadInstructionBanner && renderUploadInstructionBanner()}
			<Grid style={{ marginLeft: '30px', marginRight: '30px' }}>
				<p>
					{clientEmail ? <><span style={{ fontWeight: "bold", marginLeft: '60px' }}>Taxpayer:</span>{clientEmail}</> : null}
					{spouseEmail ? <><span style={{ fontWeight: "bold", marginLeft: "20px" }}>Spouse:</span>{spouseEmail}</> : null}
				</p>
				<GridCell colSpan={12} className="categoryCell">
					<GridCell style={{ width: '94%', position: 'relative', margin: '0 auto', marginBottom: '-30px' }} colSpan={8} id="dashboardCategoryDivider">
						<Divider />
					</GridCell>
				</GridCell>
			</Grid>
			<OrganizerUploads style={{ top: '30px' }} />
			{isFutureYear ?
				<div className='formBuilderContainer futureContentContainer'>
					<div className='futureHeadText'>Your future tax year has not yet been created.</div>
					<div className='uploadText'>Upload files for your future tax year above.</div>
					<div className='contentText'>You can upload applicable documents in the Document Manager tab, or upload in bulk above.</div>
					<div className='contentText'>For the current tax year, please change the tax year in the dropdown selection above to upload files.</div>
				</div> :
				<>

					<div className="dashboardSize">
						<DashboardSubMenu />
						<DashboardGrid categories={categories} />
					</div>
				</>
			}
			<EntryExperienceModal />
		</>
	);
}

export default Dashboard;
