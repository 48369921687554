import { useState, useEffect } from 'react';
import {
	Grid,
	GridCell,
	GridList,
	GridListCell,
	Divider,
	Button,
	TextIconSpacing
} from 'react-md';
import { ExpandMoreSVGIcon, ExpandLessSVGIcon } from '@react-md/material-icons';
import _ from 'lodash';
import { isMobile } from 'react-device-detect';

import usePageFramework from '@utilities/hooks/usePageFramework';
import setDerivedOrganizerData from '@utilities/organizerData';

import DashboardGridCell from './dashboardGridCell';
import './dashboardGrid.css';

function DashboardGrid(props) {
	const { categories } = props;
	const { ACTION, dispatch, REDUX, CARDSTATUS, selectState } = usePageFramework();
	const isClient = selectState(REDUX.IS_CLIENT);
	const filterStatusId = selectState(REDUX.DASHBOARD_FILTER_STATUS);
	const dashboardCategories = () => {
		const catCopy = _.cloneDeep(categories);
		if (filterStatusId !== CARDSTATUS.REQUIRED && catCopy.length) {
			catCopy.push(
				{
					title: 'Optional Forms (Unless Applicable)',
					entryType: 'optionalForms',
					cards: []
				}
			);
			catCopy[3].cards = [];
			catCopy.forEach(category => {
				if (category.entryType !== 'optionalForms') {
					category.cards.forEach(card => {
						if (!card.isRequired) {
							catCopy[3].cards.push(card);
						}
					});
				}
			});
		}

		catCopy.forEach(category => {
			if (category.entryType !== 'optionalForms')
				category.cards = category.cards.filter(card => card.isRequired);
		});
		return catCopy;
	};
	const sortReq = selectState(REDUX.DASHBOARD_SORT_REQ);
	const [isViewAll, setIsViewAll] = useState([
		{ id: 0, show: true },
		{ id: 1, show: true },
		{ id: 2, show: true },
		{ id: 3, show: true },
		{ id: 4, show: true },
	]);
	const [categoryIndex, setCategoryIndex] = useState();
	const [filteredCategories, setFilteredCategories] = useState(dashboardCategories());
	const [categoryEntryType, setCategoryEntryType] = useState('');
	const [isEqualLength, setIsEqualLength] = useState([
		{ id: 0, isEqual: false },
		{ id: 1, isEqual: false },
		{ id: 2, isEqual: false },
		{ id: 3, isEqual: false },
		{ id: 4, isEqual: false },
	]);
	const cardsPerRow = 5; //added const for cards per row  
	const sortByTitleAToZ = (cardA, cardB) => cardA.title.toLowerCase().localeCompare(cardB.title.toLowerCase());
	const sortByTitleZToA = (cardA, cardB) => cardB.title.toLowerCase().localeCompare(cardA.title.toLowerCase());
	const sortByStatusIdIncreasing = (cardA, cardB) => cardA.statusId - cardB.statusId;
	const sortByStatusIdDecreasing = (cardA, cardB) => cardB.statusId - cardA.statusId;
	const sortByTraditional = (cardA, cardB) => cardA.key.localeCompare(cardB.key);
	const isPractitioner = selectState(REDUX.IS_PRACTITIONER);
	const organizerId = selectState(REDUX.ORGANIZER_ID);

	let filteredBy = '';
	switch (filterStatusId) {
		case CARDSTATUS.NOT_STARTED:
			filteredBy = 'Not Started';
			break;
		case CARDSTATUS.IN_PROGRESS:
			filteredBy = 'In Progress';
			break;
		case CARDSTATUS.ERRORS:
			filteredBy = 'Errors Only';
			break;
		case CARDSTATUS.COMPLETED:
			filteredBy = 'Completed';
			break;
		case CARDSTATUS.DOES_NOT_APPLY:
			filteredBy = 'Does Not Apply';
			break;
		case CARDSTATUS.REQUIRED:
			filteredBy = 'Required';
			break;
		default:
			filteredBy = '';
			break;
	}

	let sortBy = sortByTraditional;
	switch (sortReq) {
		case 'alphaIncreasing':
			sortBy = sortByTitleAToZ;
			break;
		case 'alphaDecreasing':
			sortBy = sortByTitleZToA;
			break;
		case 'progressIncreasing':
			sortBy = sortByStatusIdIncreasing;
			break;
		case 'progressDecreasing':
			sortBy = sortByStatusIdDecreasing;
			break;
		case 'traditional':
			sortBy = sortByTraditional;
			break;
		default:
			sortBy = sortByTraditional;
			break;
	}

	useEffect(() => {
		if (categoryEntryType) {
			if (isViewAll[categoryIndex].show) {
				setCategoryEntryType(prevState => {
					if ((isViewAll.show && prevState === categoryEntryType) || (prevState === categoryEntryType)) {
						setFilteredCategories(prevState => {
							const category = prevState.find(data => data.title === categoryEntryType);
							const index = prevState.indexOf(category);
							prevState[index].cards = dashboardCategories()
								.find(data => data.title === categoryEntryType).cards;
							return [...prevState];
						});
					}
				});
			} else if ((!isViewAll[categoryIndex].show && !filterStatusId) || (filterStatusId < 0)) {
				setFilteredCategories(prevState => {
					const category = prevState.find(data => data.title === categoryEntryType);
					const index = prevState.indexOf(category);
					prevState[index].cards = dashboardCategories()
						.find(data => data.title === categoryEntryType).cards
						.slice(0, cardsPerRow);
					return [...prevState];
				});
			} else if (!isViewAll[categoryIndex].show && filterStatusId) {
				setFilteredCategories(prevState => {
					const category = prevState.find(data => data.title === categoryEntryType);
					const index = prevState.indexOf(category);
					// Filter by statusId if filterStatusId > 0
					prevState[index].cards = dashboardCategories()
						.find(data => data.title === categoryEntryType).cards
						.filter(card => card.statusId === filterStatusId)
						.slice(0, cardsPerRow);
					return [...prevState];
				});
			}
		}
		// eslint-disable-next-line
	}, [categoryEntryType, isViewAll, setFilteredCategories, setCategoryEntryType, setIsViewAll, categories]);

	useEffect(() => {
		if (filterStatusId && filterStatusId !== -1) {
			const initialCategories = dashboardCategories().map((category, index) => {
				const filteredCards = filterStatusId === CARDSTATUS.REQUIRED ? category.cards.filter(card => card.isRequired)
					: category.cards.filter(card => card.statusId === filterStatusId);
				let payload = {
					title: category.title,
					entryType: category.entryType,
					cards: filteredCards,
					defaultCardLength: filteredCards.length
				};

				isEqual(payload, index);

				return payload;
			});
			setFilteredCategories(initialCategories);
			dispatch(ACTION.setNavBarCards(initialCategories));
		} else {
			const initialCategories = dashboardCategories().map((category, index) => {
				let payload = {
					title: category.title,
					entryType: category.entryType,
					// cards: category.cards.slice(0, cardsPerRow),
					cards: category.cards, // change for showing all the cards as default
					defaultCardLength: category.cards.length
				};

				isEqual(payload, index);
				if (!categoryIndex && !isViewAll[index].show && payload.cards.length >= cardsPerRow) {
					payload.cards = payload.cards.slice(0, cardsPerRow);
				}

				return payload;
			});
			setFilteredCategories(initialCategories);
			dispatch(ACTION.setNavBarCards(initialCategories));
		}
		// eslint-disable-next-line
	}, [filterStatusId, categories]);

	useEffect(() => {
		setDerivedOrganizerData(organizerId);
		// eslint-disable-next-line
	}, []);

	const isEqual = (payload, categoryIndex) => {
		setIsEqualLength(prevState => {
			const data = prevState.find(item => item.id === categoryIndex);
			const index = prevState.indexOf(data);
			// changing the condition to check if the length is equal to the cards per row const
			prevState[index].isEqual = payload.defaultCardLength === cardsPerRow;
			return [...prevState];
		});
	};

	const onViewAllClick = (title, categoryIndex) => {
		setCategoryEntryType(title);
		setCategoryIndex(categoryIndex);
		// toggle view all forms
		setIsViewAll(prevState => {
			const newState = _.cloneDeep(prevState);
			const isView = newState.find(item => item.id === categoryIndex);
			const index = newState.indexOf(isView);
			newState[index].show = !newState[index].show;
			return [...newState];
		});
	};

	const filteredCard = (category) => {
		let filteredCards = filterStatusId === CARDSTATUS.REQUIRED ? category.cards.filter(card => card.isRequired)
			: category.cards.filter(card => card.statusId === filterStatusId);

		if (sortReq) {
			filteredCards.sort(sortBy).filter(card => card.statusId === filterStatusId);
		}

		return (
			filteredCards.length === 0 ? <GridListCell className="noShow" colSpan={1}>
				No {filteredBy} Cards to Show
			</GridListCell> :
				filteredCards.map((card, index) => <DashboardGridCell card={card} index={index} isClient={isClient} />)
		);
	};

	const allCards = (category) => {
		let allCards = category.cards;

		if (sortReq) {
			allCards.sort(sortBy);
		}

		const cardsForClient = allCards.filter((card) => {
			return !card.shouldRenderForPractioner;
		});
		const cards = isPractitioner ? allCards : cardsForClient;

		return cards.map((card, index) => <DashboardGridCell card={card} index={index} isClient={isClient} />);
	};

	const renderViewAllBtn = (category, index) => {
		return (!filterStatusId || filterStatusId === -1) && (!isEqualLength[index].isEqual) && (
			<Button
				id="combined-button-2"
				className="viewAllCards"
				onClick={() => onViewAllClick(category.title, index)}
			>
				<TextIconSpacing
					icon={
						!isViewAll[index].show ?
							<ExpandMoreSVGIcon className="expandCardsButton" /> :
							<ExpandLessSVGIcon className="expandCardsButton" />}
					iconAfter
				>
					{!isViewAll[index].show ? 'View All' : 'View Less'}
				</TextIconSpacing>
			</Button>
		);
	};

	return (
		<Grid className="dashboardHeader">
			{filteredCategories.map((category, index) => (
				<GridCell key={`category-${index}`} colSpan={12} className="categoryCell">
					<GridCell key={`categoryTitle-${index}`} colSpan={12} className="dashboardCategoriesHeader">
						{!(isMobile && isClient) && <div className="dashboardCardHeaderTitle">
							<h1 className="dashHeaderTitle">
								{category.title} <span>{category.defaultCardLength} {category.defaultCardLength === 1 ? 'Form' : 'Forms'} {category.entryType === 'optionalForms' ? ' Optional' : ' Required'}</span>
							</h1>
						</div>}
						{!(isMobile && isClient) && category.cards.length >= cardsPerRow &&
							<div className="viewAllBtn">
								{renderViewAllBtn(category, index)}
							</div>}
					</GridCell>
					{!(isMobile && isClient) && <GridCell colSpan={12} id="dashboardCategoryDivider">
						<Divider />
					</GridCell>}
					<GridList className="dashboardGridListCategory" maxCellSize={300} cellMargin="10px">
						{!filterStatusId || filterStatusId === -1 ? allCards(category) : filteredCard(category)}
					</GridList>
				</GridCell>
			))}
		</Grid>
	);
}

export default DashboardGrid;