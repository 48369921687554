import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectReduxGlobalState } from '@utilities/store';
import usePageFramework from '@utilities/hooks/usePageFramework';
import FormRenderer from '@components/formRenderer';
import { sectionFieldLogic, triggeredEvent } from './refundPaymentsLogicTrigger';
import getYear from '@utilities/helpers/getYear';
import * as NAV from '@utilities/constants/navigation';
import getFormTemplateForYear from '@utilities/helpers/formTemplate';
import refundPayment2021 from './2021';
import refundPayment2022 from './2022';
import refundPayment2023 from './2023';
import { RefundsPaymentsGroupIds } from './refundPayments.config'

import './RefundPayments.css';

let sections = [];

export const getRefundsPaymentSections = () => {
    return sections;
};

function RefundPayment(props) {
    const { isExportForm } = props
    const { updateCard, REDUX, selectState } = usePageFramework(NAV.REFUNDTAXPAYMENT);
    const [sectionState, setSectionState] = useState('');
    const year = getYear(selectState);

    const formDataSection = useSelector(state => selectReduxGlobalState(state, REDUX.REFUND_TAX_PAYMENT));

    useEffect(() => {
        if (!isExportForm) updateCard();

        const formTemplatesByYear = {
            2021: refundPayment2021(year),
            2022: refundPayment2022(year),
            2023: refundPayment2023(year)
        };

        const formSections = getFormTemplateForYear(year.current, formTemplatesByYear);
        sections = formSections;

        setSectionState(formSections);
        clearVerificationFieldsOnload(formDataSection);
    }, []);

    const clearVerificationFieldsOnload = (formDataSection) => {
        if (formDataSection) {
            const verificationGroup = formDataSection[0].groups.find(x => x.groupId === RefundsPaymentsGroupIds.BankInformationVerification);

            if (verificationGroup && verificationGroup.fields) {
                const routingNoField = verificationGroup.fields.find(x => x.name === 'VerifyRoutingNo');
                const accountNoField = verificationGroup.fields.find(x => x.name === 'VerifyAccountNo');

                if (routingNoField && routingNoField.error === true) routingNoField.value = "";
                if (accountNoField && accountNoField.error === true) accountNoField.value = "";
            }

        }
    }

    return (
        <FormRenderer sections={sectionState} key="refundPaymentForm" formName={REDUX.REFUND_TAX_PAYMENT}
            identifiers={[{ section: 'General', id: 'Basic Data' }]}
            triggeredEvent={triggeredEvent}
            sectionFieldLogic={sectionFieldLogic}
            isExportForm={isExportForm}
        />
    );
}

export default RefundPayment;
