import { FileInput, Form } from 'react-md';
import { frameworkSetter } from '../../../../../hooks/usePageFramework';
import * as IMG from '../../../../../constants/images';
import { ACCEPTED_FILE_TYPES } from '../../../../../constants/document';

function InlineUploadButton(props) {
    const { className, height, index, group, onClick, isFormLocked, lineItemSectionName } = props;
    const { onFileUpload, selectState, REDUX } = frameworkSetter.usePageFramework();
    const uploadList = selectState(REDUX.UPLOAD_LIST);
    const lineItem = group?.lineItems[index][0];
    const onChange = (event) => onFileUpload(event, null, lineItemSectionName, uploadList, { updateState: onClick });

    return (
        <Form>
            <FileInput className ={className}
                disabled={ isFormLocked || lineItem?.notApplicable }
                data-testid='inline-upload-button'
                id={`lineUpload-${lineItemSectionName}`}
                onChange={onChange} 
                theme='clear'
                themeType='flat'
                buttonType ='text'
                multiple={true}
                accept={ACCEPTED_FILE_TYPES}
                icon= {<img height={height} alt="File upload button" src={IMG.uploadIconPrimary} />}>
            </FileInput>
            
        </Form>
    );
}

export default InlineUploadButton;