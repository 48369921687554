// common modules
import { useEffect, useState } from 'react';
import _ from 'lodash';

// custom modules
import '@views/DocumentManager.css';
import usePageFramework from '@utilities/hooks/usePageFramework';
import DocumentManagerCategory from '@components/documentManager/DocumentManagerCategory';
import * as STRING from '@utilities/constants/strings';
import api from '@utilities/claApi';

function DocumentManager(props) {
	const {
		updatePage,
		REDUX,
		selectState,
		dispatch,
		ACTION,
		updateUploadList
	} = usePageFramework();
	const organizerId = selectState(REDUX.ORGANIZER_ID);
	const isFutureYear = selectState(REDUX.IS_FUTURE_YEAR);
	const forms = selectState(REDUX.DASHBOARD);
	let categories = forms ? forms.filter((f) => f.title !== 'Opportunity Services') : [];
	const uploadList = selectState(REDUX.UPLOAD_LIST);
	const [formData, setFormData] = useState([]);
	const [isLoaded, setIsLoaded] = useState(false);
	const taxYear = selectState(REDUX.YEAR);
	const priorYearDetails = selectState(REDUX.PRIOR_YEAR_DETAILS);
	const futureYearOrganizer = priorYearDetails?.priorYearLookup ? _.find(priorYearDetails.priorYearLookup, {value: taxYear}) : {};

	if (isLoaded || isFutureYear) {
		if (isFutureYear) {
			categories = [];
		} else {
			let allCards = [];
			categories.forEach(category => category.cards.forEach(card => allCards.push(card)));
			categories.unshift({
				title: 'All Documents',
				cards: allCards,
				uploads: [],
				uploadSectionName: STRING.DASHBOARD_SECTION_NAME,
				key: STRING.DASHBOARD_KEY,
			});
		}
		categories.push({
			title: 'Unassigned Files',
			cards: [],
			uploads: [],
			uploadSectionName: STRING.DASHBOARD_SECTION_NAME,
			key: STRING.DASHBOARD_KEY,
		});

		// prepare categories
		categories.forEach((category) => {
			if (category.title === 'Unassigned Files') {
				category.uploads = uploadList?.filter((upload) => upload.form === STRING.DASHBOARD_SECTION_NAME) || [];
			}

			category.cards.forEach((card) => {
				card.isCollapsed = true;
				card.uploads = uploadList?.filter((upload) => upload.form === card.key) || [];
				card.entities = [];
				if (category.title === 'All Documents') {
					const cardUploadList = uploadList?.filter((upload) => upload.form === card.key) || [];
					cardUploadList.map(list => list.sectionName = card.title);
					if (cardUploadList?.length > 0) {
						category.uploads.push(...cardUploadList);
					}
				}


				if (card.showBulkEntityUpload) {
					const cardForm = formData.find((f) => f.key === card.formName);

					if (!cardForm || (cardForm?.data && cardForm?.data?.length === 0)) {
						card.entities.push(
							{
								title: _.startCase(`${card.formName} 1`),
								uploads: card.uploads.filter(upload => upload.section.startsWith('0')),
								isFormEntity: true,
								isCollapsed: true,
								uploadSectionName: `0-${card.bulkUpload.sectionName}`
							}
						)
					} else {
						cardForm?.data?.forEach((entity) => {
							const unNamedEntity = _.startCase(`${card.formName} ${entity.entityIndex + 1}`);
							card.entities.push({
								title: entity.name || unNamedEntity,
								uploads: card.uploads?.filter(upload => upload.section.startsWith(String(entity.entityIndex))),
								isFormEntity: true,
								isCollapsed: true,
								uploadSectionName: `${entity.entityIndex}-${card.bulkUpload.sectionName}`
							});
						})
					}
				}
			})
			if (category.title === 'All Documents') {
				const cardUploadList = uploadList?.filter((upload) => upload.form === STRING.DASHBOARD_SECTION_NAME) || [];
				cardUploadList.map(list => list.sectionName = '');
				if (cardUploadList?.length > 0) {
					category.uploads.push(...cardUploadList);
				}
			}
		});
		categories[0].uploads.sort(function (a, b) {
			return a.sectionName === b.sectionName && ((/^[0-9]/.test(a.name) - /^[0-9]/.test(b.name)) || a.name.localeCompare(b.name, undefined, { numeric: true, sensitivity: 'base' }));
		});
	}

	useEffect(() => {
		updatePage({ title: 'Document Manager', key: REDUX.DOCUMENT_MANAGER });
	}, []);

	useEffect(() => {
		setIsLoaded(false);

		if (!isFutureYear) {
			// Handle current year; load all forms and documents
			dispatch(ACTION.setProgressText('Loading Client Data...'));
			dispatch(ACTION.setProgressVisible(true));

			// get organizer details
			api.get(`organizers/${organizerId}`).then(async (response) => {
				const {
					id,
					forms,
					locked,
					client,
					status,
					year,
				} = response.data;

				const {
                    data: {
                        id: dashboardId,
                        dashboard,
                    }
                } = await api.get(`organizers/${id}/dashboard`);

                // set organizer meta data
				dispatch(ACTION.setActiveReturn({
					displayName: client.name,
					clientNumber: client?.number ?? '',
					formStatus: status,
					currentYear: year
				}));
				dispatch(ACTION.setOrganizerId(id));
				dispatch(ACTION.setYear(year));
				dispatch(ACTION.setLockForms(locked || year === priorYearDetails?.priorYear));
                dispatch(ACTION.setDashboardId(dashboardId));

				if (dashboard) {
					dispatch(ACTION.setDashboard(dashboard));
				}

				let hasVehicleData = false;

				setFormData(forms);

				const formKeys = [];
				forms?.forEach((form) => {
					if (!formKeys.includes(form.key)) {
						dispatch(ACTION.setForm(form.key, form.data));
						hasVehicleData = hasVehicleData || _.startsWith(form.key, REDUX.VEHICLE);
						formKeys.push(form.key);
					}
				});
				if (year !== priorYearDetails.priorYear) {
					updateUploadList(id).then(() => {
						dispatch(ACTION.setProgressVisible(false));
						setIsLoaded(true);
					}).catch((err) => {
						console.error('Failed to retrieve uploads', err);
						dispatch(ACTION.setProgressVisible(false));
					});
				} else {
					dispatch(ACTION.setUploadList([]));
					dispatch(ACTION.setProgressVisible(false));
					setIsLoaded(true);
				}
			}).catch((err) => {
				console.error(err);
				dispatch(ACTION.setProgressVisible(false));
			});
		} else if (isFutureYear) {
			// Handle future year; load future year documents
			dispatch(ACTION.setProgressText('Loading Client Data...'));
			dispatch(ACTION.setProgressVisible(true));
			if (futureYearOrganizer?.id) {
				updateUploadList(futureYearOrganizer?.id).then(() => {
					dispatch(ACTION.setProgressVisible(false));
					setIsLoaded(true);
				}).catch((err) => {
					console.error('Failed to retrieve uploads', err);
					dispatch(ACTION.setUploadList([]));
					setIsLoaded(true);
					dispatch(ACTION.setProgressVisible(false));
				});
			} else {
				// This should fall to the scenario of the 'year is not set up'
				console.error('Failed to retrieve uploads, no reference');
				dispatch(ACTION.setUploadList([]));
				setIsLoaded(true);
				dispatch(ACTION.setProgressVisible(false));
			}
		} else if (taxYear !== priorYearDetails?.priorYear) {
			// Handle previous year; documents are archived and should not be shown
			setIsLoaded(true);
			dispatch(ACTION.setProgressVisible(false));
			dispatch(ACTION.setUploadList([]));
		}
		// eslint-disable-next-line
	}, [taxYear]);

	return (
		<div className='formBuilderContainer'>
			<div>
				{
					!isLoaded
						? <div>Not loaded</div>
						: taxYear === priorYearDetails?.priorYear
							? <div>Your prior year documents have been archived. Please select another year.</div>
							: futureYearOrganizer?.id || !isFutureYear
								? categories.map((category, index) => (
									<DocumentManagerCategory
										key={`doc-manager-form-${index}`}
										category={category}
										categories={[...categories]}
										dataTestId={'doc-manager-form'} />
								))
								: <div>This year is not currently set up. If you would like to upload documents to this year, please chat with Support to enable this feature.</div>
				}
			</div>
		</div>
	);
};

export default DocumentManager;