import { useState, useEffect } from 'react';
import usePageFramework from '@utilities/hooks/usePageFramework';
import FormRendererHelper from '@components/formRenderer';
import * as NAV from '@utilities/constants/navigation';
import { sectionFieldLogic } from './charitableContributionsLogicTrigger';
import getYear from '@utilities/helpers/getYear';
import getFormTemplateForYear from '@utilities/helpers/formTemplate';
import charitable2021 from './2021';
import charitable2022 from './2022';
import charitable2023 from './2023';

let sections = [];

export const getCharitableContributionsSections = () => {
    return sections;
};

function CharitableContributions(props) {
    const { isExportForm } = props;
    const { updateCard, REDUX, selectState } = usePageFramework(NAV.CHARITABLE_CONTRIBUTIONS);
    const [sectionState, setSectionState] = useState('');
    const year = getYear(selectState);

    useEffect(() => {
        if (!isExportForm) updateCard();

        const formTemplatesByYear = {
            2021: charitable2021(year),
            2022: charitable2022(year),
            2023: charitable2023(year),
        };

        const formSections = getFormTemplateForYear(year.current, formTemplatesByYear);
        sections = formSections;
        setSectionState(formSections);
        // eslint-disable-next-line
    }, []);

    const axcessIdentifiers = [
        { section: 'Deductions', id: 'Sch A' },
        { section: 'Deductions', id: '8283 - Noncash Charitable Contributions (1098-C)' }
    ];

    return (
        <FormRendererHelper sections={sectionState} key="CharitableContributionsForm" formName={REDUX.CONTRIBUTIONS} identifiers={axcessIdentifiers} sectionFieldLogic={sectionFieldLogic}
            isExportForm={isExportForm}
        />
    );
}

export default CharitableContributions;
