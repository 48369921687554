import { EVENT_KEY_YES } from '@utilities/constants/triggerKeys';

const setAdvanceCreditText = (event, options) => {
    const { sections, formSections, setFormSections } = options;
    const name = formSections ? formSections : sections;
    name[0].groups[1].fields[7].isVisible = event === EVENT_KEY_YES;
    
    setFormSections(name);
};

const setStimulusPaymentText = (event, options) => {
    const { sections, formSections, setFormSections, inputState } = options;
    const name = formSections ? formSections : sections;

    name[0].groups[3].fields[0].isVisible = event === EVENT_KEY_YES ? false: true
    name[0].groups[3].fields[1].isVisible = event === EVENT_KEY_YES ? true: false
    
    name[0].groups[3].fields[2].default = event;
    const prevState = name[0].groups[3].fields[2].inputState || 0;
    name[0].groups[3].fields[2].inputState = (prevState & inputState);

    setFormSections(name);
};

const setChildTaxPaymentText = (event, options) => {
    const { sections, formSections, setFormSections } = options;
    const name = formSections ? formSections : sections;

    name[0].groups[3].fields[3].isVisible = event === EVENT_KEY_YES ? false: true
    name[0].groups[3].fields[4].isVisible = event === EVENT_KEY_YES ? true: false
    
    name[0].groups[3].fields[5].default = event;
    
    setFormSections(name);
};

const showCryptoPlatformList = (event, options) => {
    const { sections, formSections, } = options;
    const name = formSections ? formSections : sections;

    name[1].groups[1].fields[4].isVisible = event === EVENT_KEY_YES;
    name[1].groups[1].fields[5].isVisible = event === EVENT_KEY_YES;

};

const triggeredEvent = (trigger, event, options) => {
    switch (trigger) {
    case 'setAdvanceCreditText':
        setAdvanceCreditText(event, options);
        break;
    case 'setStimulusPaymentText':
        setStimulusPaymentText(event, options)
        break;
    case 'setChildTaxPaymentText':
        setChildTaxPaymentText(event, options)
        break;
    case 'showCryptoPlatformList':
        showCryptoPlatformList(event, options)
        break;
    default:
        break;
    }
};

export {
    triggeredEvent,
};