import { useState, useEffect } from 'react';
import { NestedDialogContextProvider, Card, Dialog } from 'react-md';

import usePageFramework from '@utilities/hooks/usePageFramework';
import * as IMG from '@utilities/constants/images';
import NotesDialogContent from '../NotesDialogContent/NotesDialogContent';
import '../notes.scss';


function FormNotes({setNotesVisible, notesVisible}) {
    const { REDUX, selectState, card } = usePageFramework();
    const notesList = selectState(REDUX.FORM_NOTES) || [];
    const isFutureYear = selectState(REDUX.IS_FUTURE_YEAR);
    
    let formName = card?.formName || 'dashboard';
    // eslint-disable-next-line
    let activeNote = notesList.length && notesList.find(x => x.key=== formName) || { value: '', key: formName };
    const [currentformNoteText, setCurrentformNoteText] = useState(activeNote.value);
    const [styles, setStyles] = useState("");

    useEffect(() => {
       if(isFutureYear) return;

        setStyles(currentformNoteText.length > 0 ? "notesCardHasNotes" : "");
        // eslint-disable-next-line
    }, [currentformNoteText]);

    useEffect(() => {
      if(isFutureYear){
        setStyles("cardDisabled")
      }
      // eslint-disable-next-line
    }, []);

    useEffect(() => {
        // when card change, different cards have different notes, so need to reset
        // eslint-disable-next-line
        formName = card?.formName || 'dashboard';
        // eslint-disable-next-line
        activeNote = notesList.length && notesList.find(x => x.key=== formName) || { value: '', key: formName };
        setCurrentformNoteText(activeNote.value)
        // eslint-disable-next-line
    }, [card])

    const showFormNotes = () => {
        if(isFutureYear){
            return;
        }
        setNotesVisible(true)
    };

    const NotesCard = () => {
        return (
            <div onClick={showFormNotes} first="true" last="true" data-testid='card-notes' className='notes'> 
                <Card 
                    className={`dashboardHeaderIconCard entryNoShadow dashboardHeaderCard iconCard ${styles}`}
                >
                    <div className="dashboardHeaderIconName headerCardsPadding iconImg">
                        <img alt="Notes icon" src={IMG.NOTES_HEADER} />
                        <div className="iconText">Notes</div>
                    </div>
                </Card>
            </div>
        );
    };

    return (
        <NestedDialogContextProvider>
             <NotesCard />
             <Dialog
                id={'cardNotesDialog'}
                visible={notesVisible}
                onRequestClose={() => {}}
                modal={true}
                aria-labelledby="card-notes-dialog"
                className="notesDialogContainer"
                overlayHidden={false}
            >
                <NotesDialogContent {...{setNotesVisible, formName, currentformNoteText, setCurrentformNoteText, notesList, activeNote}} />
            </Dialog>
        </NestedDialogContextProvider>
    );
}

export default FormNotes;