import usePageFramework from '@utilities/hooks/usePageFramework';
import './showLastFormSave.css';
import moment from 'moment';
import NavBarFooter from '@components/NavFooter';

const ShowLastFormSave = ({ className, isNavBar }) => {
    const {
        lastSaveFormTime,
        location,
    } = usePageFramework();
    let lastFormChangeTime = lastSaveFormTime;

    if (lastFormChangeTime) {
        lastFormChangeTime = moment(lastFormChangeTime).format("MM/DD/YYYY - hh:mm a");
    }
    return (<>
        {(location.pathname !== '/dashboard' && location.pathname !== '/documentManager' && location.pathname !== '/practitionersDashboard' && lastFormChangeTime) &&
            <div className={className} >
                { isNavBar && <NavBarFooter/> }
                <span className="navbarLastSaveLabel"> Data last saved on: </span>
                <span className="navbarLastSaveValue">{lastFormChangeTime || null}</span>
            </div>
        }
    </>)
}
export default ShowLastFormSave;