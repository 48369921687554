// common modules
import React from 'react';
import ProgressBar from '@components/ProgressBar.js';

// custom modules
import './ProgressTracker.css';

function ProgressTracker({ title, completed }) {
  return (
    <div className="progress-tracker">
      <div className="progress-tracker-content">
        <div className="progress-tracker-title">
          {title}
        </div>
        <ProgressBar completed={completed} />
        <div className="progress-tracker-subText">
          <span className="progress-bar-label left-align">Complete</span>
          <span className="progress-bar-label right-align">Incomplete</span>
        </div>
      </div>
    </div>
  )
}

export default ProgressTracker;