import { useState, useEffect } from 'react';
import usePageFramework from '@utilities/hooks/usePageFramework';
import FormRenderer from '@components/formRenderer';

import * as NAV from '@utilities/constants/navigation';
import getYear from '@utilities/helpers/getYear';
import getFormTemplateForYear from '@utilities/helpers/formTemplate';
import investment2021 from './2021';
import investment2022 from './2022';
import investment2023 from './2023';

function Income(props) {
    const { isExportForm } = props;
    const { updateCard, REDUX, selectState } = usePageFramework(NAV.INCOME);
    const [sectionState, setSectionState] = useState('');
    const year = getYear(selectState);

    useEffect(() => {
        if (!isExportForm) updateCard();

        const formTemplatesByYear = {
            2021: investment2021(year),
            2022: investment2022(year),
            2023: investment2023(year),
        };

        const formSections = getFormTemplateForYear(year.current, formTemplatesByYear);
        setSectionState(formSections);
        // eslint-disable-next-line   
    }, []);

    const axcessIdentifiers = [
        { section: 'Income', id: 'Interest' },
        { section: 'Income', id: 'Dividends' },
        { section: 'Income', id: 'Consolidated 1099' },
    ];

    return (
        <FormRenderer
            sections={sectionState}
            key="incomeForm"
            formName={REDUX.INVESTMENT_INCOME}
            identifiers={axcessIdentifiers}
            isExportForm={isExportForm}
        />
    );
}

export default Income;
