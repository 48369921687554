/* eslint-disable no-unused-vars */
import {
	GridCell, Grid, DropdownMenu, Divider, useToggle, Button, FileInput,
	Form, FontIcon, TextIconSpacing
} from 'react-md';
import React from 'react';
// import * as IMG from '../../../../../constants/images';
import * as IMG from '@utilities/constants/images';
import usePageFramework from '@utilities/hooks/usePageFramework';
import InlineUploadButton from '../../buttons/uploadButtons/inlineUploadButton/index';
import nextId from 'react-id-generator';
import BlobFileViewer from '../../../dialogBox/blobFileViewer';
import moment from 'moment';
import { MoreVertSVGIcon } from '@react-md/material-icons';
import useWindowDimensions from '@utilities/hooks/useWindowDimensions';
function UploadField(props) {
	// eslint-disable-next-line no-unused-vars
	const { field, index, group, isFormLocked, colspan, fns, drawerStyle } = props;
	const { selectState, REDUX, onFileRemoval, onFileUpload } = usePageFramework();
	const [visibleViewBtn, showViewBtn, hideViewBtn] = useToggle(false);
	const uploadList = selectState(REDUX.UPLOAD_LIST);
	const sectionName = 'fileUpload';
	const setLineUploaded = () => {
		fns.saveForm(field);
	};
	const onChange = (event) => onFileUpload(event, null, sectionName, uploadList, { updateState: setLineUploaded });

	const buildUploadListButton = () => {
		return (
			<Button className='lineUploadButton2'>
				<img alt={visibleViewBtn ? 'Hide uploaded files button' : 'View uploaded files button'}
					src={visibleViewBtn ? IMG.hideUploadsIcon : IMG.viewUploadsIcon} />
			</Button>
		);
	};
	const { width } = useWindowDimensions();
	const getScreenCol = (col) => {
		const colTab = col === 10 ? 6 : col === 6 ? 3 : col === 3 ? 2 : col === 2 ? 1 : col;
		return width < 1025 ? colTab : col;
	};
	const lineUploadItems = (file) => {
		return [
			{
				children: 'Remove File',
				onClick: (event) => onFileRemoval(event, file),
				className: 'removeUpload'
			}
		];
	};
	// const isLineUploadDrawer = true;
	const style = drawerStyle ? drawerStyle : 'uploadDrawerContainer';
	const isLineUploadDrawer = style === 'lineUploadDrawerContainer';
	const uploadedFileList = uploadList.map((file, idx) =>
		<React.Fragment key={idx}>
			<Grid key={`${sectionName}-${idx}`} id={`${sectionName}-${nextId()}`} className='uploadDrawerRow'>
				{isLineUploadDrawer && <GridCell colSpan={1}></GridCell>}
				<GridCell className='fileName' colSpan={getScreenCol(6)}>
					<BlobFileViewer
						key={`blobFileViewer-${idx}`}
						id={nextId()}
						className="fileViewDetails"
						blobFile={file}
						fileName={file.name}
					/>
				</GridCell>
				<GridCell className='fileName' colSpan={getScreenCol(isLineUploadDrawer ? 2 : 3)}>
					{file.uploaded_by || 'None'}
				</GridCell>
				<GridCell className='fileName' colSpan={getScreenCol(isLineUploadDrawer ? 1 : 2)}>
					{moment(new Date(file.createdOn)).format('MMM DD, YYYY') || 'None'}
				</GridCell>
				<GridCell colSpan={1} className="fileList">
					<DropdownMenu
						disabled={isFormLocked}
						key={`dpmenu-${idx}`}
						id={nextId()}
						items={lineUploadItems(file)}
						buttonType="icon"
					>
						<MoreVertSVGIcon />
					</DropdownMenu>
				</GridCell>
				{isLineUploadDrawer && <GridCell colSpan={1}></GridCell>}
			</Grid>
			<Grid>
				{isLineUploadDrawer && <GridCell colSpan={1}></GridCell>} 
				{/* {closeButton} */}
				<GridCell colSpan={12}>
					<Divider />
				</GridCell>
			</Grid>
		</React.Fragment>
	);
	return (
		<Grid>
			<GridCell colSpan={9} className='labelText'> {field.label} </GridCell>
			<GridCell colSpan={3} key={`cell-${field.name}`}>
				<div key={`ivbgc-${index}`} className="formLineButton" style = {{float: "right"}} data-testid='upload-kebab-line-buttons'>
					<div className="formLineActionsBtn">
						{uploadList?.length ?
							buildUploadListButton()
							: <InlineUploadButton key={`iub-${index}`} className='lineUploadButton1' index={index}
								onClick={setLineUploaded}
								isFormLocked={isFormLocked} lineItemSectionName='Section12345' />}
					</div>
				</div>
			</GridCell>
			{uploadedFileList.length > 0 && visibleViewBtn && <GridCell colSpan={12} key={`cell-${field.name}`}>
				<Grid className='uploadDrawerHeaderContainer'>
					<GridCell colSpan={12}><Divider /></GridCell>
					<GridCell colSpan={6} className="headerLabelsLeft">
						File Name
					</GridCell>
					<GridCell colSpan={3} className="uploadHeaderheaderLabelsLeft">
						Uploaded By
					</GridCell>
					<GridCell colSpan={3} className="uploadHeaderheaderLabelsLeft">
						Date Uploaded
					</GridCell>
					<GridCell colSpan={1}></GridCell>
					<GridCell colSpan={1}></GridCell>
					<GridCell colSpan={12}><Divider /></GridCell>
				</Grid>
				{uploadedFileList}
				{/* <GridCell colSpan={12}><Divider /></GridCell> */}
				<GridCell colSpan={6}>
					<Form>
						<FileInput
							disabled
							style={isFormLocked ? { cursor: 'default' } : {}}
							theme='clear'
							themeType='flat'
							id={sectionName}
							onChange={onChange}
							buttonType={'text'}
							disableIconSpacing={true}
							multiple={true}
							icon={<TextIconSpacing
								icon={
									<FontIcon className="addIcon">
										add
									</FontIcon>
								}>
							</TextIconSpacing>}
							className={'addMoreFile'}
						>
							Upload More Files
						</FileInput>
					</Form>
				</GridCell>
			</GridCell >}
		</Grid>
	);
}
export default UploadField;