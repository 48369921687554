import _ from "lodash";
import * as AXCESS_KEYS from "@utilities/constants/axcessKeys";
import * as TRIGGER_KEYS from "@utilities/constants/triggerKeys";
import * as EVENT from '@utilities/constants/triggerKeys';
import * as LOOKUPS from "@utilities/constants/lookupInfo";
import * as FIELD_TYPE from "@utilities/constants/fieldType";
import { parseToUSDate } from "@components/formRenderer/fields/utils/formatDate";
import moneyFormatter from '../utilities/moneyFormatter'
import { stateTaxMaps } from "./helpers/getStateTaxSections";
import { extensionPaymentDuedate } from "./helpers/extensionPaymentDuedate";

const zipPostalPull = (axcessValues) => {
  if (!axcessValues) return null;

  const [foreignCountry, zipCode, postalCode] = axcessValues;

  if (foreignCountry) return postalCode || null;
  return zipCode || null;
};

const foreignCountryPull = (axcessValues) => {
  if (!axcessValues) return null;

  const [foreignCountry] = axcessValues;

  if (!foreignCountry) return TRIGGER_KEYS.KEY_UNITED_STATES;
  if (foreignCountry === TRIGGER_KEYS.KEY_EMPTY)
    return TRIGGER_KEYS.KEY_UNITED_STATES;
  if (foreignCountry.toLowerCase() === TRIGGER_KEYS.KEY_NA)
    return TRIGGER_KEYS.KEY_UNITED_STATES;

  return foreignCountry;
};

const americanStatesPull = (axcessValues) => {
  if (!axcessValues) return null;

  const [foreignCountry, state] = axcessValues;

  const country = foreignCountryPull([foreignCountry]);

  if (!country) return null;
  if (country === TRIGGER_KEYS.KEY_UNITED_STATES) return state;
  return null;
};

const canadianProvincePull = (axcessValues) => {
  if (!axcessValues) return null;

  const [foreignCountry, province] = axcessValues;

  const country = foreignCountryPull([foreignCountry]);

  if (!country) return null;
  if (country === TRIGGER_KEYS.KEY_CANADA) return province;
  return null;
};

const mexicanStatesPull = (axcessValues) => {
  if (!axcessValues) return null;

  const [foreignCountry, state] = axcessValues;

  const country = foreignCountryPull([foreignCountry]);

  if (!country) return null;
  if (country === TRIGGER_KEYS.KEY_MEXICO) return state;
  return null;
};

const foreignProvincePull = (axcessValues) => {
  if (!axcessValues) return null;

  const [foreignCountry, province] = axcessValues;

  const country = foreignCountryPull([foreignCountry]);

  if (!country) return null;
  if (
    country !== TRIGGER_KEYS.KEY_CANADA &&
    country !== TRIGGER_KEYS.KEY_UNITED_STATES
  )
    return province;
  return null;
};

const childCareExpensesPull = (values) => {
  const filterValid = (x) => x && _.toSafeInteger(x) !== 0;
  if (!values || !_.isArray(values)) return null;

  const hasCareExpense = values.filter(filterValid).length ? true : false;

  return hasCareExpense ? AXCESS_KEYS.AXCESS_KEY_YES : null;
};

const datePull = (value) => parseToUSDate(value);

const employerIdPull = (value) => {
  if (!value) return null;
  return value.replace("-", "");
};

const jointToTaxpayerPull = (value) => {
  if (value === AXCESS_KEYS.JOINT_KEY) return AXCESS_KEYS.TAXPAYER_KEY;
  return value;
};

const checkboxPull = (value) =>
  value === AXCESS_KEYS.AXCESS_KEY_X ? true : false;
const checkboxYNPull = (value) =>
  value === AXCESS_KEYS.AXCESS_KEY_YES ? true : false;
const checkboxToYNPull = (value) =>
  value === AXCESS_KEYS.AXCESS_KEY_X ? "Yes" : "No";
const selectYNPull = (value) => {
  switch (value) {
    case AXCESS_KEYS.AXCESS_KEY_Y:
      return AXCESS_KEYS.AXCESS_KEY_YES;
    case AXCESS_KEYS.AXCESS_KEY_N:
      return AXCESS_KEYS.AXCESS_KEY_NO;
    default:
      return "";
  }
};
const withdrawalPull = (value) =>
  value === AXCESS_KEYS.AXCESS_KEY_BOTH ? AXCESS_KEYS.EXCHANGE_KEY_BOTH : value;
const maritalStatusPull = (value) => {
  const SINGLE = [TRIGGER_KEYS.EVENT_KEY_1, TRIGGER_KEYS.EVENT_KEY_3];
  const MARRIED = [
    TRIGGER_KEYS.EVENT_KEY_2,
    TRIGGER_KEYS.EVENT_KEY_5,
    TRIGGER_KEYS.EVENT_KEY_6,
    TRIGGER_KEYS.EVENT_KEY_7
  ];
  const WIDOWED = [TRIGGER_KEYS.EVENT_KEY_4];

  if (SINGLE.includes(value)) {
    return TRIGGER_KEYS.KEY_SINGLE;
  } else if (MARRIED.includes(value)) {
    return TRIGGER_KEYS.KEY_MARRIED;
  } else if (WIDOWED.includes(value)) {
    return TRIGGER_KEYS.KEY_WIDOWED;
  }

  return value;
};

const foreignAccountTypePull = (values) => {
  if (!values) return null;
  const [foreignAccount] = values;

  if (foreignAccount) return foreignAccount;
  return AXCESS_KEYS.FOREIGN_ACC_TYPE_OTHER;
};

const foreignAssetsIdTypePull = (values) => {
  if (!values) return null;
  const [idType] = values;

  switch (idType) {
    case AXCESS_KEYS.FOREIGN_ASSETS_ID_TYPE_FOREIGN:
      return TRIGGER_KEYS.KEY_FOREIGN;
    case AXCESS_KEYS.FOREIGN_ASSETS_ID_TYPE_EIN:
      return TRIGGER_KEYS.KEY_EIN;
    case AXCESS_KEYS.FOREIGN_ASSETS_ID_TYPE_SSN:
      return TRIGGER_KEYS.KEY_SSN;
    default:
      return null;
  }
};

const translateFunctions = {
  date: datePull,
  checkbox: checkboxPull,
  YNcheckbox: checkboxYNPull,
  checkboxYN: checkboxToYNPull,
  selectYN: selectYNPull,
  electronicWithdrawal: withdrawalPull,
  maritalStatus: maritalStatusPull,
  employerId: employerIdPull,
  jointToTaxpayer: jointToTaxpayerPull
  // Define other functions for translation when pulling prior year data
};

const translatePullData = (axcessTranslate, value) => {
  if (!axcessTranslate || !translateFunctions[axcessTranslate]) return value;

  return translateFunctions[axcessTranslate](value);
};

const stateLocalIncomeRefundPull = (values) => {
  if (!_.isArray(values)) {
    return null;
  }

  const tsj = stateLocalTsjPull(values);
  const taxpayerRefund = _.toSafeInteger(values[9]);
  const spouseRefund = _.toSafeInteger(values[23]);

  if (tsj === AXCESS_KEYS.JOINT_KEY) return `${taxpayerRefund + spouseRefund}`;
  return `${taxpayerRefund}`;
};

const stateLocalTsjPull = (values) => {
  const filterValid = (x) => x && _.toSafeInteger(x) !== 0;

  if (!_.isArray(values)) {
    return AXCESS_KEYS.TAXPAYER_KEY;
  }

  const maritalStatus = values[0];
  const hasJointValues = values.slice(1, 15).filter(filterValid).length
    ? true
    : false;
  const hasSpouseValues = values.slice(15).filter(filterValid).length
    ? true
    : false;

  if (
    maritalStatus === TRIGGER_KEYS.EVENT_KEY_2 &&
    hasJointValues &&
    !hasSpouseValues
  )
    return AXCESS_KEYS.JOINT_KEY;
  if (
    maritalStatus === TRIGGER_KEYS.EVENT_KEY_2 &&
    hasJointValues &&
    hasSpouseValues
  )
    return AXCESS_KEYS.JOINT_KEY;
  return AXCESS_KEYS.TAXPAYER_KEY;

  // Taxpayer or Joint is 1 - 14
  // Spouse 15 - 28
  // If filing status on return is married filing joint and just a value under taxpayer or joint, mark it joint and using the value from just taxpayer column
  // If filing status on return is married filing joint and value under taxpayer or joint AND spouse, mark it joint and use the value from taxpayer and spouse combined
  // If filing status on return is anything else, mark it taxpayer and use just taxpayer value
};

const stateLocalIncomeCityPull = (values) => {
  if (!values && !_.isArray(values)) return null;
  if (values[1] === "city" && values[0]) return values[0];
  return null;
};

const stateLocalIncomeStatePull = (values) => {
  if (!values && !_.isArray(values)) return null;
  if (values[1] === "state" && values[0]) return values[0];
  return null;
};

const iraTypePull = (axcessValues) => {
  if (!axcessValues) return null;

  const amounts = axcessValues.map((x) => _.toSafeInteger(x));

  const [pyContributions, cyContributions, rothContributions] = amounts;

  if (pyContributions || cyContributions)
    return AXCESS_KEYS.AXCESS_KEY_TRADITIONAL;
  if (rothContributions) return AXCESS_KEYS.AXCESS_KEY_ROTH;

  return null;
};

const iraAmountPull = (axcessValues) => {
  const iraType = iraTypePull(axcessValues);

  if (!iraType) return null;

  const amounts = axcessValues.map((x) => _.toSafeInteger(x));
  const [pyContributions, cyContributions, rothContributions] = amounts;

  switch (iraType) {
    case AXCESS_KEYS.AXCESS_KEY_TRADITIONAL:
      return `${pyContributions + cyContributions}`;
    case AXCESS_KEYS.AXCESS_KEY_ROTH:
      return `${rothContributions}`;
    default:
      return null;
  }
};

const sepTypePull = (axcessValues) => {
  if (!axcessValues) return null;

  const [benCB, sepCB, simCB, ...amounts] = axcessValues;
  const numAmounts = amounts.map((x) => _.toSafeInteger(x));
  const [ben1, ben2, ben3, sep1, sep2, sim1, sim2] = numAmounts;

  if (benCB) return AXCESS_KEYS.AXCESS_KEY_BENEFIT;
  if (sepCB) return AXCESS_KEYS.AXCESS_KEY_SEP;
  if (simCB) return AXCESS_KEYS.AXCESS_KEY_SIMPLE;

  if (ben1 || ben2 || ben3) return AXCESS_KEYS.AXCESS_KEY_BENEFIT;
  if (sep1 || sep2) return AXCESS_KEYS.AXCESS_KEY_SEP;
  if (sim1 || sim2) return AXCESS_KEYS.AXCESS_KEY_SIMPLE;

  return null;
};

const sepAmountPull = (axcessValues) => {
  const sepType = sepTypePull(axcessValues);

  if (!sepType)
    return `${_.toSafeInteger(axcessValues[axcessValues.length - 1])}` || null;

  /* eslint-disable no-unused-vars */
  const [benCB, sepCB, simCB, ...amounts] = axcessValues;
  const numAmounts = amounts.map((x) => _.toSafeInteger(x));
  const [ben1, ben2, ben3, sep1, sep2, sim1, sim2] = numAmounts;

  switch (sepType) {
    case AXCESS_KEYS.AXCESS_KEY_BENEFIT:
      if (!ben1 && !ben2 && !ben3)
        return `${numAmounts[numAmounts.length - 1]}` || null;
      return `${ben1 + ben2 + ben3}`;
    case AXCESS_KEYS.AXCESS_KEY_SEP:
      if (!sep1 && !sep2) return `${numAmounts[numAmounts.length - 1]}` || null;
      return `${sep1 + sep2}`;
    case AXCESS_KEYS.AXCESS_KEY_SIMPLE:
      if (!sim1 && !sim2) return `${numAmounts[numAmounts.length - 1]}` || null;
      return `${sim1 + sim2}`;
    default:
      return `${numAmounts[numAmounts.length - 1]}` || null;
  }
};

const farmCropInsurancePull = (axcessValues) => {
  if (!axcessValues) return "0";

  const amounts = axcessValues.map((x) => _.toSafeInteger(x));
  const [val1, val2] = amounts;

  const cropInsuranceAmt = (val1 || 0) - (val2 || 0);
  return cropInsuranceAmt < 0 ? "0" : `${cropInsuranceAmt}`;
};

const farmGasFuelPull = (axcessValues) => {
  if (!axcessValues) return "0";

  const amounts = axcessValues.map((x) => _.toSafeInteger(x));
  const [fuelAmt] = [amounts.reduce((a, b) => a + b, 0)];

  return `${fuelAmt || "0"}`;
};

const anyValidPull = (axcessValues) => {
  if (!axcessValues || !_.isArray(axcessValues)) return null;

  return axcessValues.find((x) => x) || null;
};

const anyValidDatePull = (axcessValues) => {
  if (!axcessValues || !_.isArray(axcessValues)) return null;

  return axcessValues.find((x) => x) || null;
};

const accountTypePull = (axcessValues) => {
  if (!axcessValues) return LOOKUPS.ACCOUNT_TYPE[1].value;

  if (
    _.toUpper(axcessValues).includes(
      _.toUpper(AXCESS_KEYS.EXCHANGE_KEY_CHECKING)
    )
  ) {
    return LOOKUPS.ACCOUNT_TYPE[1].value;
  }

  if (
    _.toUpper(axcessValues).includes(
      _.toUpper(AXCESS_KEYS.EXCHANGE_KEY_SAVINGS)
    )
  ) {
    return LOOKUPS.ACCOUNT_TYPE[2].value;
  }

  return LOOKUPS.ACCOUNT_TYPE[1].value;
};

const providerTypePull = (axcessValues) => {
  if (!axcessValues) return null;

  return axcessValues[0] ? EVENT.EVENT_KEY_SSN : axcessValues[1] ? EVENT.EVENT_KEY_EIN : null;
};

const sumAllPull = (axcessValues) => {
  if (!axcessValues || !_.isArray) return null;

  const amounts = axcessValues.map((x) => _.toSafeInteger(x));
  const amount = amounts.reduce((prev, curr) => prev + curr, 0);

  return `${amount}`;
};

const conditionalValuePull = (axcessValues) => {
  if (!axcessValues || !_.isArray || !axcessValues.length) return null;

  const amount = axcessValues.find((x) => x);

  return `${_.toSafeInteger(amount)}`;
};

const nonZeroStatePaymentPull = (axcessValues) => {
  if (!axcessValues || !_.isArray || !axcessValues.length) return null;

  const selectedState = anyValidPull([axcessValues[0]]);

  const stateIds = stateTaxMaps[selectedState];

  if (!stateIds || !stateIds.length) return null;

  let stateInstalmentTotal = 0;
  stateIds.forEach(stateId => {
    if (axcessValues[stateId]) stateInstalmentTotal += _.toSafeInteger(axcessValues[stateId]);
  })

  return stateInstalmentTotal > 0 ? `${stateInstalmentTotal}` : axcessValues[1] ? `${(axcessValues[1])}` : '0';
};

const vehicleOtherMilesPull = (axcessValues) => {
  if (!axcessValues || !_.isArray(axcessValues)) return null;

  const miles = axcessValues.map((x) => _.toSafeInteger(x));
  const otherMilesAmount = miles[0] - miles[1] - miles[2];
  return otherMilesAmount > 0 ? `${otherMilesAmount}` : null;
};

const foreignAssetsBankInfoLabelPull = (axcessValues) => {
  if (!axcessValues || !_.isArray(axcessValues)) return null;

  let bankName = axcessValues[0] || "";
  const maskedAccountNumber =
    ("" + axcessValues[1]).slice(0, -4).replace(/./g, "X") +
    ("" + axcessValues[1]).slice(-4);
  let accountNumber = axcessValues[1] ? `Acct #: ${maskedAccountNumber}` : "";

  if (bankName.length > 20) {
    bankName = `${bankName.substring(0, 17)}...`;
  }

  if (accountNumber.length > 20) {
    accountNumber = `${accountNumber.substring(0, 17)}...`;
  }

  if (!bankName && !accountNumber) return null;
  return `${bankName}\n${accountNumber}`;
};

const foreignAssetsAccountHeldJointlyOther = (axcessValues) => {
  if (!axcessValues || !_.isArray(axcessValues)) return null;

  const ownershipType = axcessValues[0];
  if (ownershipType === AXCESS_KEYS.FOREIGN_ASSETS_JOINT_OWNERSHIP_OTHER) {
    return 'Yes';
  }

  return 'No';
};

const foreignIncomeHomeAddressPull = (axcessValues) => {
  if (!axcessValues || !_.isArray(axcessValues)) return null;

  const addresses = axcessValues.filter((x) => x?.trim());

  return addresses.length > 0 ? addresses.join(", ") : "";
};

const attachOrTablePull = (axcessValues) => {
  if (!axcessValues || !_.isArray(axcessValues)) return null;

  const addresses = axcessValues.filter((x) => x?.trim());

  return addresses.length > 0 ? "TABLE" : null;
};

const foreignIncomeHomeOccupantPull = (axcessValues) => {
  if (!axcessValues || !_.isArray(axcessValues)) return null;

  const addresses = axcessValues
    .map((row) => {
      if (!row || !_.isArray(row)) return null;

      return row
        .map((x) => x?.trim())
        .filter((x) => x)
        .join(" ");
    })
    .filter((x) => x && x.length);

  return addresses.length > 0 ? addresses : [];
};

const dependentsSummaryPull = (axcessValues) => {
  if (!axcessValues || !_.isArray(axcessValues)) return null;

  const [relation, ...names] = axcessValues;

  // Base relationship status on lookup
  const relationship =
    LOOKUPS?.RELATIONSHIP?.find(
      (x) => x.value === relation?.trim() && relation
    )?.name?.trim() ?? "";
  let name = names
    ?.filter((x) => x && _.isString(x) && x?.trim())
    ?.map((x) => x?.trim())
    ?.join(" ");

  return `${name}\n${relationship}`;
};

const odtkeCompensationPull = (axcessValues) => {
  if (!axcessValues || !_.isArray(axcessValues)) return null;

  const [isIndividual, isInstitutional, isKeyEmployee, isHighestCompensated] =
    axcessValues;

  if (isIndividual) return "Director";
  if (isInstitutional) return "Institutional Trustee";
  if (isKeyEmployee) return "Key Employee";
  if (isHighestCompensated) return "Highest Comp EE";
  return null;
};

const odtkeLabelPull = (axcessValues) => {
  if (!axcessValues || !_.isArray(axcessValues)) return null;

  let indvName = axcessValues[0] || "";
  let indvTitle = axcessValues[1] || "";

  if (indvName.length > 20) {
    indvName = `${indvName.substring(0, 17)}...`;
  }

  if (indvTitle.length > 20) {
    indvTitle = `${indvTitle.substring(0, 17)}...`;
  }

  let format = `Name: ${indvName || ""}\nTitle: ${indvTitle || ""}`;
  return format;
};

const taxExemptStatusPull = (axcessValues) => {
  if (!axcessValues || !_.isArray(axcessValues)) return null;

  const [section501, section4947, section527] = axcessValues;

  if (
    section501 &&
    _.toSafeInteger(section501) &&
    _.toSafeInteger(section501) > 0 &&
    _.toSafeInteger(section501) <= 29
  ) {
    // check if section501 is a number between 1 and 29
    // compile into 501(c)(#)
    return `501(c)(${section501})`;
  }
  if (section4947) return "4947(a)(1)";
  if (section527) return "527";

  return null;
};

const publicCharityStatusPull = (axcessValues) => {
  if (!axcessValues || !_.isArray(axcessValues)) return null;

  const [
    church,
    school,
    hospital,
    medResearch,
    university,
    govUnit,
    publicFunded,
    communityTrust,
    agResearch,
    grossReceipts,
    publicSafety,
    typeI,
    typeII,
    typeIIIFunctionally,
    typeIIINonFunctionally
  ] = axcessValues;

  if (church) return "170(b)(1)(A)(i)–Church"; // .0
  if (school) return "170(b)(1)(A)(ii)-School"; // .1
  if (hospital) return "170(b)(1)(A)(iii)-Hospital"; // .2
  if (medResearch) return "170(b)(1)(A)(iii)-Med Research Org"; // .4
  if (university) return "170(b)(1)(A)(iv)-College/University"; // .6
  if (govUnit) return "170(b)(1)(A)(v)–Gov Unit"; // .3
  if (publicFunded) return "170(b)(1)(A)(vi)-Gov/Public Funded"; // .7
  if (communityTrust) return "170(b)(1)(A)(vi)-Community Trust"; // .8
  if (agResearch) return "170(b)(1)(A)(ix)–Ag Research Org"; // .15
  if (grossReceipts) return "509(a)(2)–Gross Receipts Test"; // .9
  if (publicSafety) return "509(a)(4)–Public Safety"; // .13
  if (typeI) return "509(a)(3)–Type I"; // .10
  if (typeII) return "509(a)(3)–Type II"; // .11
  if (typeIIIFunctionally) return "509(a)(3)–Type III Functionally Int."; // .12
  if (typeIIINonFunctionally) return "509(a)(3)–Type III Non-functionally Int."; // .14

  return null;
};

const removeHyphensPull = (axcessValues) => {
  if (!axcessValues || !_.isArray(axcessValues)) return null;
  if (
    !axcessValues[0] ||
    !_.isString(axcessValues[0]) ||
    !axcessValues[0].trim()
  )
    return null;

  return axcessValues[0].trim().replaceAll("-", "");
};

const joinTextPull = (axcessValues) => {
  if (!axcessValues || !_.isArray(axcessValues)) return null;

  return axcessValues
    .filter((x) => x && _.isString(x))
    .map((x) => x.trim())
    .join(" ");
};

const missionStatementPull = (code) => (axcessValues) => {
  if (!axcessValues || !_.isArray(axcessValues) || axcessValues.length !== 3)
    return null;

  if (axcessValues[0]) return `${axcessValues[0]}`;

  return fieldMatchTextPull(code)([axcessValues[1], axcessValues[2]]);
};

const fieldMatchTextPull = (matchValue) => (axcessValues) => {
  if (!axcessValues || !_.isArray(axcessValues)) return null;

  // Index 0 is the field value to be pulled
  // Index 1 is the field value to be matched
  return axcessValues[1] === matchValue && axcessValues[0]
    ? `${axcessValues[0]}`
    : null;
};

const pullIfFieldMatches = (axcessValues, data) => {
  if (!data || !data.matchValue) return null;

  return fieldMatchTextPull(data.matchValue)(axcessValues);
};

const programsMSPull = (axcessValues) =>
  missionStatementPull(AXCESS_KEYS.MS_CODE_2)(axcessValues);
const basicDataMSPull = (axcessValues) =>
  missionStatementPull(AXCESS_KEYS.MS_CODE_1)(axcessValues);

const fsRptAcctFedReqAudMissExpPull = (axcessValues) =>
  fieldMatchTextPull(AXCESS_KEYS.EXPLANATION_CODE_24)(axcessValues);

const fsRptAcctMthdPYPull = (axcessValues) => {
  if (!axcessValues || !_.isArray(axcessValues)) return AXCESS_KEYS.CASH;

  if (axcessValues[0] === AXCESS_KEYS.AXCESS_KEY_X) {
    return AXCESS_KEYS.ACCRUAL;
  } else if (axcessValues[1]) {
    return AXCESS_KEYS.OTHER;
  }
};

const gridToMultiselectPull = (axcessValues) => {
  if (!axcessValues || !_.isArray(axcessValues) || !axcessValues[0])
    return null;

  return axcessValues[0];
};

const checkboxValuePull = (axcessValues) => {
  if (!axcessValues || !_.isArray(axcessValues)) return null;

  return axcessValues[0] ? true : null;
};

const fiscalYearPull = (axcessValues) => {
  if (!_.isArray(axcessValues)) return null;

  if (!axcessValues || axcessValues[0] === null) return "Yes";

  const match =
    axcessValues[0] &&
    axcessValues[0].match(/^(12)\/(31)\/(([0-9]{2})?[0-9]{2})$/g);

  return match === null ? "No" : "Yes";
};

const returnFn = ([x]) => x;

const supFinArtExhbRptElectRptPull = (axcessValues) => {
  if (!axcessValues || !_.isArray(axcessValues)) return null;

  /**
   * If first field is 'X', return 'No'.
   * If second field is 'X', return 'Yes'.
   * If both fields null, return null
   * If both fields 'X', return null
   */
  return axcessValues[0] === AXCESS_KEYS.AXCESS_KEY_X && !axcessValues[1]
    ? "No"
    : axcessValues[1] === AXCESS_KEYS.AXCESS_KEY_X && !axcessValues[0]
      ? "Yes"
      : null;
};

const YNToRadioPull = (axcessValues) => {
  if (!axcessValues || !_.isArray(axcessValues)) return null;

  return axcessValues[0] === AXCESS_KEYS.AXCESS_KEY_Y
    ? "Yes"
    : axcessValues[0] === AXCESS_KEYS.AXCESS_KEY_N
      ? "No"
      : null;
};

const checkboxToRadioPull = (axcessValues) => {
  if (!axcessValues || !_.isArray(axcessValues)) return null;
  if (!axcessValues[0]) return null;

  return axcessValues[0] === AXCESS_KEYS.AXCESS_KEY_X ? "Yes" : "No";
};

const anyValidCheckboxOrFieldToRadioPull = (axcessValues) => {
  if (!axcessValues || !_.isArray(axcessValues)) return null;

  const pulledValue = axcessValues.find((x) => x);
  if (!pulledValue) return null;

  return pulledValue ? "Yes" : "No";
};

const mortgageIdTypePull = (axcessValues) => {
  if (!axcessValues) return null;
  const [lastName, ssnValue] = axcessValues;

  if (!ssnValue) return null;

  if (lastName) {
    return TRIGGER_KEYS.EVENT_KEY_SSN;
  }

  return TRIGGER_KEYS.EVENT_KEY_EIN;
};

const mortgageSSNIdPull = (axcessValues) => {
  if (!axcessValues) return null;

  const [lastName, ssnValue] = axcessValues;
  const idType = mortgageIdTypePull(axcessValues);

  if (idType === TRIGGER_KEYS.EVENT_KEY_SSN) {
    return ssnValue;
  }

  return null;
};

const mortgageEINIdPull = (axcessValues) => {
  if (!axcessValues) return null;

  const [lastName, einValue] = axcessValues;
  const idType = mortgageIdTypePull(axcessValues);

  if (idType === TRIGGER_KEYS.EVENT_KEY_EIN) {
    return einValue;
  }

  return null;
};

const mortgageLenderSummaryPull = (axcessValues) => {
  if (!axcessValues || !_.isArray(axcessValues)) return null;

  const idMaskValue = "On File";
  const [lenderFirstNameValue, lenderLastNameValue, lenderIdValue] =
    axcessValues;
  let lenderName = _.isString(lenderFirstNameValue)
    ? lenderFirstNameValue?.trim()
    : "";
  let lenderIdType =
    _.isString(lenderLastNameValue) && lenderLastNameValue?.trim()?.length
      ? TRIGGER_KEYS.EVENT_KEY_SSN
      : TRIGGER_KEYS.EVENT_KEY_EIN;
  let lenderId =
    _.isString(lenderIdValue) && lenderIdValue?.trim()?.length
      ? `${lenderIdType}: ${idMaskValue}`
      : "";

  if (lenderName.length > 23) {
    lenderName = `${lenderName.substring(0, 20)}...`;
  }

  return `${lenderName}\n${lenderId}`;
};

const noncashCharitableSummaryPull = (axcessValues) => {
  if (!axcessValues || !_.isArray(axcessValues)) return null;
  const [contributionName1, contributionName2] = axcessValues;

  const contributionName = contributionName1 ?? contributionName2 ?? "";

  return `${contributionName}`;
};

const medicalLabelPull = (axcessValues, axcessMetadata, formContext) => {
  if (!axcessValues || !_.isArray(axcessValues)) return null;

  const [amount] = axcessValues;

  const medicalAmount = _.toSafeInteger(amount);

  if (!medicalAmount) return null;

  const priorYear = _.toSafeInteger(formContext?.currentYear) - 1;

  // GROWTH: Add new years and percentages
  const YEAR_PERCENTAGE_MAP = {
    2019: 7.5,
    2020: 7.5,
    2021: 7.5,
    2022: 7.5,
    2023: 7.5,
  };

  const percentage = YEAR_PERCENTAGE_MAP[priorYear];

  const grossIncomeAmount = moneyFormatter(medicalAmount);
  const totalLabel = `Last year your adjusted gross income was ${grossIncomeAmount}`;

  let limitLabel = "";

  if (percentage) {
    const deductibleAmount =
      medicalAmount > 0
        ? moneyFormatter((medicalAmount * percentage) / 100)
        : moneyFormatter(0);
    limitLabel = ` and the ${priorYear} limit was ${percentage}%. You would have needed ${deductibleAmount} of medical expenses to be deductible`;
  }

  return `${totalLabel}${limitLabel}.`;
};

const tsNamePull = (axcessValues) => {
  if (!axcessValues || !_.isArray(axcessValues)) return null;

  const [tsjValue] = axcessValues;

  if (!_.isString(tsjValue) || !tsjValue?.trim()) return null;

  const tsjName = LOOKUPS.PAYER_TS.find(
    (x) => x.value === tsjValue?.trim()
  )?.name?.trim();

  return tsjName ? tsjName : null;
};

const propertyTaxPull = (axcessValues) => {
  if (!axcessValues || !_.isArray(axcessValues)) return null;

  const [taxCode] = axcessValues;
  const taxType = LOOKUPS?.TAX_TYPE?.find((x) => x.value === taxCode)?.value;

  return taxType && taxType !== " " ? taxType : null;
};

const calculateCYFromTaxData = (axcessValues, formContext) => {
  if (!axcessValues || !_.isArray(axcessValues) || !axcessValues.length) return "N/A";

  const pulledValue = axcessValues.find((x) => x);

  if (!pulledValue || pulledValue === "0") return "N/A";

  const currentYear = _.toSafeInteger(formContext?.currentYear);
  return currentYear;
};

const setQ1DueDatePull = (axcessValues, axcessMetadata, formContext) => {
  const defaultYear = calculateCYFromTaxData(axcessValues, formContext);

  if (defaultYear === "N/A") return "N/A";

  return `04/15/${defaultYear.toString().slice(-2)}`;
};

const setQ1ExtensionDueDatePull = (axcessValues, axcessMetadata, formContext) => {
  const defaultYear = calculateCYFromTaxData(axcessValues, formContext);

  return extensionPaymentDuedate(defaultYear);
};

const setQ2DueDatePull = (axcessValues, axcessMetadata, formContext) => {
  const defaultYear = calculateCYFromTaxData(axcessValues, formContext);

  if (defaultYear === "N/A") return "N/A";

  return `06/15/${defaultYear.toString().slice(-2)}`;
};

const setQ3DueDatePull = (axcessValues, axcessMetadata, formContext) => {
  const defaultYear = calculateCYFromTaxData(axcessValues, formContext);

  if (defaultYear === "N/A") return "N/A";

  return `09/15/${defaultYear.toString().slice(-2)}`;
};

const setQ4DueDatePull = (axcessValues, axcessMetadata, formContext) => {
  const defaultYear = calculateCYFromTaxData(axcessValues, formContext);

  if (defaultYear === "N/A") return "N/A";

  return `01/15/${((defaultYear + 1).toString().slice(-2))}`;
};

const anyValidTSPull = (axcessValues) => {
  if (!axcessValues || !_.isArray(axcessValues)) return null;
  return axcessValues.find(x => x === AXCESS_KEYS.TAXPAYER_KEY || x === AXCESS_KEYS.SPOUSE_KEY) || null;
};

const accNameWithNumberPull = (axcessValues) => {
  if (!axcessValues || !_.isArray(axcessValues)) return null;

  const [payerName, accountNumber] = axcessValues;

  const names = [];
  if (payerName?.trim()) {
    names.push(payerName.trim());
  }

  // Grab last 4 digits of account number
  if (accountNumber?.trim()) {
    let accountTrimmed = accountNumber.trim();

    if (accountNumber.length > 4) {
      accountTrimmed = accountNumber.slice(accountNumber.length - 4, accountNumber.length);
    }

    names.push(accountTrimmed);
  }

  const accDisplayName = names.join(' - ');

  return accDisplayName.length
    ? accDisplayName
    : null
};

const anyValidFilteredPull = (axcessValues, data) => {
  if (!axcessValues || !_.isArray(axcessValues)) return null;

  const axcessValue = axcessValues.find(x => x);

  const { charToFilter } = data

  return axcessValue ? axcessValue.split(new RegExp(charToFilter)).join('') : null;

};

const checkboxPullToYesNoDontKnow = (axcessValues) => {
  if (!axcessValues || !_.isArray(axcessValues)) return null;

  const fieldValue = axcessValues[0];
  if (fieldValue === AXCESS_KEYS.AXCESS_KEY_X) {
    return 'Yes';
  }

  return null;
};

const radioToYNDKPull = (axcessValues) => {
  if (!axcessValues || !_.isArray(axcessValues)) return null;

  const fieldValue = axcessValues[0];
  if (fieldValue === AXCESS_KEYS.AXCESS_KEY_YES) {
    return 'Yes';
  } else if (fieldValue === AXCESS_KEYS.AXCESS_KEY_NO) {
    return 'No';
  }

  return null;
}

const pyFunctions = {
  iraTypePull,
  iraAmountPull,
  sepTypePull,
  sepAmountPull,
  anyValidPull,
  farmCropInsurancePull,
  farmGasFuelPull,
  stateLocalIncomeCityPull,
  stateLocalIncomeStatePull,
  stateLocalTsjPull,
  stateLocalIncomeRefundPull,
  sumAllPull,
  vehicleOtherMilesPull,
  foreignAssetsBankInfoLabelPull,
  foreignAssetsAccountHeldJointlyOther,
  accountTypePull,
  foreignIncomeHomeAddressPull,
  attachOrTablePull,
  foreignIncomeHomeOccupantPull,
  dependentsSummaryPull,
  odtkeCompensationPull,
  odtkeLabelPull,
  taxExemptStatusPull,
  publicCharityStatusPull,
  removeHyphensPull,
  joinTextPull,
  programsMSPull,
  basicDataMSPull,
  fsRptAcctFedReqAudMissExpPull,
  fsRptAcctMthdPYPull,
  gridToMultiselectPull,
  checkboxValuePull,
  fiscalYearPull,
  supFinArtExhbRptElectRptPull,
  YNToRadioPull,
  checkboxToRadioPull,
  anyValidCheckboxOrFieldToRadioPull,
  // basicDataForeignProvincePull,
  pullIfFieldMatches,
  childCareExpensesPull,
  foreignProvincePull,
  mexicanStatesPull,
  canadianProvincePull,
  americanStatesPull,
  foreignCountryPull,
  mortgageIdTypePull,
  mortgageSSNIdPull,
  mortgageEINIdPull,
  mortgageLenderSummaryPull,
  noncashCharitableSummaryPull,
  medicalLabelPull,
  zipPostalPull,
  tsNamePull,
  foreignAssetsIdTypePull,
  propertyTaxPull,
  conditionalValuePull,
  setQ1DueDatePull,
  setQ1ExtensionDueDatePull,
  setQ2DueDatePull,
  setQ3DueDatePull,
  setQ4DueDatePull,
  nonZeroStatePaymentPull,
  anyValidTSPull,
  anyValidDatePull,
  accNameWithNumberPull,
  providerTypePull,
  anyValidFilteredPull,
  checkboxToYNPull,
  checkboxPullToYesNoDontKnow,
  radioToYNDKPull
};

const getPYCalculationFunction = (functionName) => {
  return functionName && pyFunctions[functionName]
    ? pyFunctions[functionName]
    : returnFn;
};

const filterPYByIdentifier = (identifier) => (priorYearData) => {
  if (
    !identifier?.section ||
    !identifier?.id ||
    !priorYearData?.section ||
    !priorYearData?.id
  )
    return false;

  return (
    priorYearData.section === identifier.section &&
    priorYearData.id.startsWith(identifier.id)
  );
};

const filerPYByControl = (controls) => (priorYearData) => {
  if (!controls?.entityId) return true;

  // TODO: set other control properties such as sheetId
  return controls.entityId === priorYearData?.entityId;
};

const findEntityNameFields = (sections) => {
  const entityPriorities = [];

  sections?.forEach((section) => {
    section?.groups?.forEach((group) => {
      group?.fields
        ?.filter((x) => x.isEntityName === true)
        ?.forEach((entityNameField) => {
          entityPriorities.push({
            name: entityNameField?.name || "",
            priority: entityNameField?.entityNamePriority || 0,
            groupId: group.groupId,
            sectionId: section.sectionId
          });
        });
    });
  });

  return entityPriorities.sort((a, b) => b.priority - a.priority);
};

const matchToLookupOptions = (
  foundValue,
  lookup,
  priorCompareOptions,
  type
) => {
  if (type === FIELD_TYPE.RADIO && !priorCompareOptions?.radioShouldLookup)
    return foundValue === null
      ? AXCESS_KEYS.AXCESS_KEY_NO
      : AXCESS_KEYS.AXCESS_KEY_YES;

  const compareAllLookup = priorCompareOptions?.compareAllLookup ? true : false;
  const compareFn = compareAllLookup ? allCompare : simpleValueCompare;
  const lookups = LOOKUPS[lookup] || LOOKUPS.YESNO;
  const lookupItem = lookups.find((x) => compareFn(foundValue)(x));

  if (type === FIELD_TYPE.MULTISELECT) return lookupItem;

  return lookupItem ? lookupItem.value : "";
};

const findFromPYData = (priorYearEntity, field, fieldName) => {
  const axcessPull = field && field.axcess?.pull;

  const key = axcessPull?.fields?.[0] || field?.name || fieldName;
  let foundPYData = null;

  priorYearEntity?.forEach((entitySection) => {
    entitySection?.data?.forEach((worksheetSection) => {
      if (foundPYData) return;
      const foundField = worksheetSection?.fields?.find(hasMatchingPYKey(key));
      const foundLine = worksheetSection?.lineItems?.find((line) =>
        line?.find(hasMatchingPYKey(key))
      );

      if (foundField) {
        foundPYData = foundField.value || null;
      } else if (foundLine && !foundPYData) {
        const foundLineField = foundLine?.find(hasMatchingPYKey(key));
        if (foundLineField) {
          foundPYData = foundLineField.value || null;
        }
      }
    });
  });

  const foundValue =
    getPYCalculationFunction(axcessPull?.fn)([foundPYData]) || foundPYData;

  if (field?.type === "select") {
    return matchToLookupOptions(
      foundValue,
      field.lookup,
      field?.priorCompareOptions
    );
  }

  return foundValue;
};

const findFromData = (worksheetSection, key, searchType) => {
  if (searchType === "fields") {
    return [findFieldInLine(worksheetSection?.fields, key)].filter((x) => x);
  } else if (searchType === "lineItems") {
    const a = worksheetSection?.lineItems
      ?.map((line) => {
        return line.find((x) => x.key === key);
      })
      .filter((x) => x);
    return a;
  }

  return [];
};

const findSection = (sections, sectionToFind) => {
  return sections?.find((x) => x.name === sectionToFind);
};

const findFieldInLine = (field, key) => {
  return field?.find(hasMatchingPYKey(key));
};

const hasMatchingPYKey = (key) => (field) => {
  return field?.key === key;
};

const hasMatchingKeyInField = (y, fieldKey) => y?.fields?.find(z => z?.key === fieldKey);
const hasMatchingKeyInLineItems = (y, fieldKey) => y?.lineItems?.find(z => z?.find(a => a?.key === fieldKey));

const simpleValueCompare = (value) => (compareObj) =>
  simpleCompare(value)(compareObj?.value);
const allCompare = (value) => (compareObj) => {
  return Object.keys(compareObj).some((compareKey) => {
    if (_.isArray(compareObj[compareKey])) {
      return compareObj[compareKey].some(simpleCompare(value));
    }
    return simpleCompare(value)(compareObj[compareKey]);
  });
};

const simpleCompare = (value) => (compareValue) => compareValue === value;

const findMergeData = (priorYearData, mergeDataIdentifiers) => {
  const foundMergeData = mergeDataIdentifiers
    .map((mergeDataIdentifier) => {
      const filteredMergeData = priorYearData.filter(
        filterPYByIdentifier(mergeDataIdentifier)
      );
      const foundPYData = findFromPYData(filteredMergeData, {
        name: mergeDataIdentifier.key
      });

      return !_.isNil(foundPYData)
        ? { key: mergeDataIdentifier.key, value: foundPYData }
        : null;
    })
    .filter((x) => x);

  return foundMergeData;
};

const getPullFieldValues = (priorYearData) => (key) => {
  const foundPriorYearItem = priorYearData?.find(hasMatchingPYKey(key));
  return foundPriorYearItem?.value || null;
};

const getFieldObject = (currentFormSections, fieldLocation) => {
  if (!currentFormSections || !fieldLocation) return null;

  const section = currentFormSections?.find(
    (section) => section.sectionId === fieldLocation.sectionId
  );
  const group = section?.groups?.find(
    (group) => group.groupId === fieldLocation.groupId
  );
  const field = group?.fields.find(
    (field) => field.name === fieldLocation.name
  );

  return field;
};

const matchToRadioLookupOptions = (foundValue) => {
  const lookupItem =
    foundValue === null
      ? AXCESS_KEYS.AXCESS_KEY_NO
      : AXCESS_KEYS.AXCESS_KEY_YES;

  return lookupItem;
};
const getFieldData = (datas) => {
  for (const data of datas) return data.data;
};

const getRequiredPSData = (sectionId, priorYearData, lookUp) => {
  const filteredData = priorYearData?.filter(
    (data) => data && data.id === sectionId
  );

  const statements = [];
  let statement = {};
  let key;
  const fieldArray = getFieldData(filteredData)?.map((x) => x.fields);

  // The is assuming that the order is important and has to be in that particular shape
  fieldArray?.forEach((field) => {
    if (lookUp && field[0]?.value === lookUp[0]) {
      key = field[1]?.key;
      statements.push(field[1]?.value);
    }
  });

  if (key !== undefined) {
    statement.key = key;
    statement.value = statements.join("\n");
  }

  return statement;
};

export {
  foreignCountryPull,
  foreignAccountTypePull,
  americanStatesPull,
  canadianProvincePull,
  mexicanStatesPull,
  foreignProvincePull,
  datePull,
  jointToTaxpayerPull,
  checkboxPull,
  checkboxToYNPull,
  checkboxYNPull,
  withdrawalPull,
  maritalStatusPull,
  foreignAssetsIdTypePull,
  translateFunctions,
  translatePullData,
  iraTypePull,
  accountTypePull,
  stateLocalIncomeCityPull,
  stateLocalIncomeStatePull,
  stateLocalTsjPull,
  stateLocalIncomeRefundPull,
  iraAmountPull,
  sepTypePull,
  sepAmountPull,
  farmCropInsurancePull,
  farmGasFuelPull,
  anyValidPull,
  sumAllPull,
  vehicleOtherMilesPull,
  foreignAssetsBankInfoLabelPull,
  foreignAssetsAccountHeldJointlyOther,
  foreignIncomeHomeAddressPull,
  foreignIncomeHomeOccupantPull,
  dependentsSummaryPull,
  odtkeCompensationPull,
  odtkeLabelPull,
  taxExemptStatusPull,
  publicCharityStatusPull,
  removeHyphensPull,
  joinTextPull,
  programsMSPull,
  basicDataMSPull,
  fsRptAcctFedReqAudMissExpPull,
  fsRptAcctMthdPYPull,
  pyFunctions,
  getPYCalculationFunction,
  filterPYByIdentifier,
  filerPYByControl,
  findEntityNameFields,
  findFromPYData,
  findSection,
  findFromData,
  childCareExpensesPull,
  simpleValueCompare,
  allCompare,
  matchToLookupOptions,
  zipPostalPull,
  findMergeData,
  hasMatchingPYKey,
  getPullFieldValues,
  getFieldObject,
  matchToRadioLookupOptions,
  getRequiredPSData,
  supFinArtExhbRptElectRptPull,
  YNToRadioPull,
  // basicDataForeignProvincePull,
  conditionalValuePull,
  setQ1DueDatePull,
  setQ2DueDatePull,
  setQ3DueDatePull,
  setQ4DueDatePull,
  nonZeroStatePaymentPull,
  providerTypePull,
  anyValidFilteredPull,
  hasMatchingKeyInField,
  hasMatchingKeyInLineItems,
  checkboxPullToYesNoDontKnow,
  radioToYNDKPull
};
