import { Validators } from 'cla-formrenderer';

import { RefundsPaymentsGroupIds } from '../refundPayments.config'

const refundPaymentFn = (year) => {
    const sections = [
        {
            title: 'Refund / Payment Preferences',
            isVisible: true,
            sectionId: 1,
            groups: [
                {
                    groupId: 1,
                    groupType: 'blueBox',
                    isSingleColumn: true,
                    fields: [
                    ]
                },
                {
                    groupId: RefundsPaymentsGroupIds.SelectAllApply,
                    fields: [
                        { label: 'REFUND:', name: 'refundLabel', type: 'label', col: 12 },
                        { label: 'I want to use direct deposit for refunds, if applicable.', name: 'IFDSDIRD.2Label', type: 'label', col: 9 },
                        {
                            label: 'Yes/No', name: 'IFDSDIRD.2', type: 'select', lookup: 'YESNO_DONTKNOW', col: 3, axcessTranslate: 'YNDKtoXCheckbox', required: true,
                            validations: {
                                ...Validators.required()
                            },
                            logicFunction: [
                                { trigger: 'isUseDirectDepositOptionChanged', isDifferentGroup: true }
                            ]
                        },
                        { label: `I want to apply my ${year.current} overpayment to my ${year.next} estimated tax liability, if applicable.`, name: 'taxLiabilityLabel', type: 'label', col: 9 },
                        {
                            label: 'Yes/No', name: 'taxLiability', type: 'select', lookup: 'YESNO_DONTKNOW', col: 3, required: true,
                            validations: {
                                ...Validators.required()
                            }
                        },
                        { label: 'TAX PAYMENT:', name: 'taxPaymentLabel', type: 'label', col: 12 },
                        { label: 'I want to discuss with my tax preparer the option to use electronic withdrawal (direct debit) for any balance(s) due.', name: 'taxPreparerOptionLabel', type: 'label', col: 9 },
                        {
                            label: 'Yes/No', name: 'taxPreparerOption', type: 'select', lookup: 'YESNO_DONTKNOW', col: 3, required: true,
                            validations: {
                                ...Validators.required()
                            },
                            logicFunction: [
                                { trigger: 'isTaxPreparerOptionChanged', isDifferentGroup: true }
                            ]
                        },
                    ],
                },
                {
                    groupType: 'lineItem',
                    groupId: RefundsPaymentsGroupIds.BankInformation,
                    logicFunction: [{ trigger: 'showBankingInfoQuestion' }],
                    axcessGroup: {
                        pull: {
                            postPopulateFn: 'onlyFirstLineItem',
                            fromSections: [
                                'Direct Deposit/Electronic Funds Withdrawal',
                            ],
                            fieldPrefixes: ['IFDSDIRD'],
                            lineFilterType: 'any',
                            lineFilters: [
                                { key: 'IFDSDIRD.3', filterType: 'containsKey' },
                                { key: 'IFDSDIRD.4', filterType: 'containsKey' },
                                { key: 'IFDSDIRD.5', filterType: 'containsKey' },
                            ],
                        },
                    },
                    fields: [
                        {
                            label: 'Bank Name',
                            wrapLabel: 'Bank / Financial Institution Name',
                            name: 'IFDSDIRD.3',
                            type: 'freeText',
                            placeholder: 'Enter Bank Name',
                            prior: true,
                            maxLength: 35,
                            isPriorEditable: false,
                            required: true,
                            validations: {
                                ...Validators.required()
                            }
                        },
                        {
                            label: 'Routing No',
                            name: 'IFDSDIRD.4',
                            type: 'number',
                            placeholder: 'Enter Number',
                            prior: true,
                            maxLength: 9,
                            canPaste: false,
                            validations: {
                                ...Validators.isRoutingNumber('Invalid check digit, or only 9 digits starting with 01-12 or 21-32'),
                                ...Validators.required()
                            },
                            isPriorEditable: false,
                            required: true,
                            logicFunction: [
                                { trigger: 'confirmRoutingNumberValidation' }
                            ]
                        },
                        {
                            label: 'Account No',
                            name: 'IFDSDIRD.5',
                            type: 'accountNumber',
                            placeholder: 'Enter Number',
                            prior: true,
                            maxLength: 17,
                            canPaste: false,
                            isMaskOnBlur: true,
                            logicFunction: [
                                { trigger: 'confirmAccountNumberValidation' }
                            ],
                            required: true,
                            validations: {
                                ...Validators.required()
                            }
                        },
                        {
                            label: 'Account Type',
                            name: 'IFDSDIRD.13',
                            type: 'select',
                            lookup: 'ACCOUNT_TYPE',
                            placeholder: 'Select',
                            prior: true,
                            //axcessTranslate: 'accountType',
                            isPriorEditable: false,
                            pullCalculation: {
                                keyIdentifiers: ['IFDSDIRD.13'],
                                fn: 'accountTypePull',
                                defaultIfEmpty: 'Checking (Default)',
                            },
                            required: true,
                            validations: {
                                ...Validators.required()
                            }
                        },
                    ],
                    lineItems: [],
                    entities: [],
                    currentEntity: -1,
                    lineItemDetails: {
                        headerLabels: [
                            { label: 'Bank / Financial Institution Name', smallScreenLabel: 'Bank Details', col: 4, colTablet: 2, },
                            { label: 'Routing No', col: 2, colTablet: 1, },
                            { label: 'Account No', col: 3, colTablet: 2, },
                            { label: 'Account Type', col: 2, colTablet: 2, },
                        ],
                        footerLabels: [],
                        lineButtons: []
                    },
                    hasDivider: true
                },
                {
                    groupId: RefundsPaymentsGroupIds.BankInformationVerification,
                    isVisible: true,
                    fields: [
                        {
                            label: 'For verification purposes, please reenter your routing and account numbers.',
                            name: 'verificationLabel',
                            type: 'label',
                            col: 7
                        },
                        {
                            label: 'Routing No',
                            id: 'routing-no-confirmation',
                            name: 'VerifyRoutingNo',
                            type: 'number',
                            placeholder: 'Enter Number',
                            maxLength: 9,
                            col: 2,
                            canPaste: false,
                            logicFunction: [{ trigger: 'confirmRoutingNumberValidation' }]
                        },
                        {
                            label: 'Account No',
                            id: 'account-no-confirmation',
                            name: 'VerifyAccountNo',
                            type: 'accountNumber',
                            placeholder: 'Enter Number',
                            col: 2,
                            maxLength: 17,
                            canPaste: false,
                            isMaskOnBlur: true,
                            logicFunction: [{ trigger: 'confirmAccountNumberValidation' }]
                        }
                    ]
                },
                {
                    groupId: RefundsPaymentsGroupIds.BankInformationConfirmation,
                    isVisible: false,
                    fields: [
                        { label: `Please confirm the accuracy of the banking information above for your ${year.current} tax return.`, name: 'bankingInfoLabel', type: 'label', prior: true, col: 9 },
                        {
                            label: 'Confirmed/Requires Change', name: 'bankingInfo', type: 'select', lookup: 'CONFIRMED_REQUIRESCHANGE', col: 3, triggered: 'isConfirmRequiresChange',
                            required: true,
                            validations: {
                                ...Validators.required()
                            }
                        }
                    ]
                }
            ]
        },
    ];

    return sections;
};

export default refundPaymentFn;