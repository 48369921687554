import { Button, Dialog } from 'react-md';

// custom modules
import '@components/dialog/successDialog/SuccessDialog.css';

function SuccessDialog(props){
    const { buttonElement, className, buttonType,
        onClick, subtitleText, onCloseClick,
        visible
    } = props;
    const isSaveSuccess = subtitleText.includes('successfully saved');

    const handleOnClick = () => {
        onClick && onClick();
    };

    const handleOnCloseClick = () => {
            onCloseClick && onCloseClick();
    };

    return(
        <>
            { buttonElement && 
                <Button buttonType={buttonType} className={className} data-testid="test-button" onClick={handleOnClick} >
                {buttonElement}
            </Button>
            }
            <Dialog
                id="success-dialog"
                role="alertdialog"
                visible={visible}
                onRequestClose={()=> {}}
                aria-labelledby="dialog-title"
                className="WarningDialogContainer"
                data-testid="success-dialog"
            >
                <div className='WarningDialogInnerContainer' >
                    {isSaveSuccess ? <div className="SuccessDialogTitle">Congratulations!</div> : null}
                    <div className="WarningDialogTextContainer">
                        <div className="WarningDialogText1"> {subtitleText}</div>
                    </div>
                    <div className='successDialogButton'>
                        <Button className="formNotesSaveActive" id="dialog-close"  data-testid="dialog-close" onClick={handleOnCloseClick} > CLOSE </Button>
                    </div>
                </div> 
            </Dialog> 
        </>
    );
}

export default SuccessDialog;