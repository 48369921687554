import { Card } from 'react-md';
import { useToggle } from '@react-md/utils';
import usePageFramework from '@utilities/hooks/usePageFramework';

function EntryIcon(props) {
	const { CARDSTATUS } = usePageFramework();
	const { card } = props;
	const [isSelected, select, unSelect] = useToggle(card.isRequired || card.statusId === CARDSTATUS.REQUIRED);

	const updateStatus = () => {
		if (card) {
			if (isSelected) {
				// card.statusId = CARDSTATUS.NOT_STARTED;
				card.isRequired = false;
				unSelect();
			} else {
				// card.statusId = CARDSTATUS.REQUIRED;
				card.isRequired = true;
				select();
			}
		}
	};

	return (
		<div className="entryIconCard">
			<Card data-testid="entryNoShadowId" onClick={() => updateStatus()} className="entryNoShadow">
				<div className={`entryIconBg ${isSelected ? 'entryIconBgActive' : ''}`}>
					<img className={card.formName === 'foreignIncome' ? 'entrIconForeign' : 'entryIcon'} alt={`Icon for ${card.title} in entry experience`} src={card.entryIconInactive} ></img>
				</div>
				<div className={`entryIconName ${isSelected ? 'entryIconNameSelected' : ''}`}>
					<div>{card.entryTitle}</div>
				</div>
			</Card>
		</div>
	);
}

export default EntryIcon;