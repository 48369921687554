
import { useState, useEffect } from 'react';
import usePageFramework from '@utilities/hooks/usePageFramework';
import FormRenderer from '@components/formRenderer';
import * as NAV from '@utilities/constants/navigation';
import getYear from '@utilities/helpers/getYear';
import getFormTemplateForYear from '@utilities/helpers/formTemplate';
import retirement2021 from './2021';
import retirement2022 from './2022';
import retirement2023 from './2023';

function SocialSercurityRetirement(props) {
    const { isExportForm } = props;
    const { updateCard, REDUX, selectState } = usePageFramework(NAV.RETIREMENT_INCOME);
    const [sectionState, setSectionState] = useState('');
    const year = getYear(selectState);

    useEffect(() => {
        if (!isExportForm) updateCard();

        const formTemplatesByYear = {
            2021: retirement2021(year),
            2022: retirement2022(year),
            2023: retirement2023(year),
        };

        const formSections = getFormTemplateForYear(year.current, formTemplatesByYear);

        setSectionState(formSections);
        // eslint-disable-next-line    
    }, []);

    const axcessIdentifiers = [
        { section: 'Income', id: 'IRAs' },
        { section: 'Income', id: 'Social Security Benefit' },
    ];

    return (
        <FormRenderer
            sections={sectionState}
            key="socialSercurityRetirementForm"
            formName={REDUX.RETIREMENT_INCOME}
            identifiers={axcessIdentifiers}
            isExportForm={isExportForm}
        />
    );
}

export default SocialSercurityRetirement;
